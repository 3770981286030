import { useEffect, useState } from 'react';
import AnimatedSection from '@/components/AnimatedSection';
import Testimonial from './components/Testimonial';
import TestimonialAddModal from './components/TestimonialAddModal';
import GridLayout from './components/GridLayout';

const SignupV2Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [toggleModal, setToggleModal] = useState(false);

  const getTestimonials = () => {
    let data = [
      {
        name: 'Flora W.',
        city: 'Durham',
        country: 'US',
        rating: 5,
        testimonial:
          'Communa is a cool website I use almost everyday for 2 months or so. Last month I was able to redeem my first gift card, was so happy.',
        date: '2019/06/22 09:59:59',
      },
      {
        name: 'Lindsay P.',
        city: 'Portland',
        country: 'US',
        rating: 5,
        testimonial: 'Great app. 3 gift cards redeemed already.',
        date: '2019/09/12 07:33:49',
      },
      {
        name: 'Ana S.',
        city: 'Virginia Beach',
        country: 'US',
        rating: 4,
        testimonial:
          "I use several sites like this one and I really like how it's easy to pick up a survey and get points. I'd give 5 stars if they had more gift card brands!",
        date: '2018/08/06 14:20:33',
      },
      {
        name: 'Jay S.',
        city: 'Calgary',
        country: 'Canada',
        rating: 5,
        testimonial:
          'Earned over $15 dollars in gift cards just by signing up and taking surveys regularly for 2 weeks.',
        date: '2018/01/31 22:01:48',
      },
      {
        name: 'Bernadette U.',
        city: 'Minneapolis',
        country: 'US',
        rating: 5,
        testimonial:
          'Really nice support! I got an issue last month with a survey and I contacted them about it. Went smooth. I recommend.',
        date: '2019/08/11 12:23:13',
      },
      {
        name: 'Higgins T.',
        city: 'Santa Ana',
        country: 'US',
        rating: 4,
        testimonial: 'Addictive. Cool.',
        date: '2020/08/31 10:45:40',
      },
      {
        name: 'Reynolds L.',
        city: 'Columbus',
        country: 'US',
        rating: 4,
        testimonial:
          'Rather happy. I was afraid to waste my time with those surveys, but I like seeing the points adding up. A bit like a game where you earn real stuff in the end!',
        date: '2019/01/02 12:17:49',
      },
      {
        name: 'Pauline O.',
        city: 'Mackay',
        country: 'Australia',
        rating: 4,
        testimonial:
          "I saw an ad about it, I wasn't really convinced at first but it's free and most of the surveys I do are not really long (for now at least). Only thing is that sometimes I have to wait a few days before getting my points.",
        date: '2019/05/19 10:37:53',
      },
      {
        name: 'Olia K.',
        city: 'Gladstone',
        country: 'Australia',
        rating: 5,
        testimonial: '',
        date: '2020/08/01 17:13:36',
      },
      {
        name: 'Tina R.',
        city: 'Fort Worth',
        country: 'US',
        rating: 3,
        testimonial:
          "Did a few surveys, got points, but wasn't able to cash out early as I wanted.",
        date: '2019/04/26 18:06:01',
      },
      {
        name: 'Whitney B.',
        city: 'New Orleans',
        country: 'US',
        rating: 5,
        testimonial:
          'I use my Communa gift cards for extra cash off Amazon purchases!',
        date: '2018/06/06 11:54:13',
      },
    ];
    data = data.sort((a, b) => (a.date > b.date ? -1 : 1));
    setTestimonials(data);
  };

  const setAnimClass = (i) => {
    const anims = [2, 3, 4];
    const randNb = Math.floor(Math.random() * anims.length);
    return `anim${anims[randNb]}`;
  };

  useEffect(() => {
    getTestimonials();
  }, []);

  return (
    <>
      <div id="testimonials">
        <AnimatedSection>
          {(animate) => (
            <div className="max-w-6xl mx-auto py-14 md:py-20 px-5">
              <h3
                className={`text-2xl md:text-3xl text-center mb-20 anim ${
                  animate ? 'anim1' : ''
                } `}
              >
                <div className="mb-2">Don&apos;t Take Our Word For It.</div>
                <div>See What Our Users Say.</div>
              </h3>
              {testimonials.length ? (
                <GridLayout>
                  {testimonials.map((data, i) => (
                    <div
                      key={`ts-${i}`}
                      className={`anim ${animate ? setAnimClass(i) : ''}`}
                    >
                      <Testimonial data={data} />
                    </div>
                  ))}
                </GridLayout>
              ) : (
                <></>
              )}
              {/* <div className="mt-8 text-center">
                <button
                  onClick={() => setToggleModal(true)}
                  className=" hover:bg-gray-200 rounded-md py-2 px-3 text-sm transition-colors duration-200"
                >
                  Let us know what you think!
                </button>
              </div> */}
            </div>
          )}
        </AnimatedSection>
      </div>
      {toggleModal && (
        <TestimonialAddModal onClose={() => setToggleModal(false)} />
      )}
    </>
  );
};

export default SignupV2Testimonials;
