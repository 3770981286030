import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get } from '@/config/axios';
import ProgressBar from './components/ProgressBar';
import ReadyBtn from './components/ReadyBtn';
import BgImg from './components/BgImg';
import VerifyEmailModal from '@/components/VerifyEmailModal';
import VerifyPhoneModal from '@/components/VerifyPhoneModal';
import Verify30DayHoldModal from '@/components/Verify30DayHoldModal';
import NotEnoughPointsModal from '@/components/NotEnoughPointsModal';
import RewardRedeemModal from '@/components/RewardRedeemModal';
import { numberFormat } from '@/utils/helpers';
import NotEnoughSurveysModal from '@/components/NotEnoughSurveysModal';
import TaskSomethingWrongModal from '@/components/TaskSomethingWrongModal';

const RewardItem = ({ reward }) => {
  const { userId } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [rewardItem, setRewardItem] = useState(null);
  const [errorOptions, setErrorOptions] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showRedeemModal, setShowRedeemModal] = useState(false);
  const [showVerifyEmail, setShowVerifyEmail] = useState(false);
  const [showVerifyPhone, setShowVerifyPhone] = useState(false);
  const [notEnoughPoints, setNotEnoughPoints] = useState(false);
  const [show30DayHold, setShow30DayHold] = useState(false);
  const [notEnoughSurveys, setNotEnoughSurveys] = useState(false);
  const [somethingWrong, setSomethingWrong] = useState(false);

  const {
    available,
    image_urls,
    _id,
    points_required_for_minimum,
    min_value_points,
  } = reward;

  const canBeRedeemed = available;

  const redeemReward = async () => {
    setLoading(true);
    try {
      const { data } = await get('v1/reward/load-by-id', {
        user_id: userId,
        reward_id: _id,
      });
      setRewardItem(data.data);
      setShowRedeemModal(true);
      setLoading(false);
    } catch (err) {
      const error = err.response?.data?.errors?.[0];
      setLoading(false);
      if (error) {
        handleErrorCode(error);
      }
    }
  };

  const handleErrorCode = (error) => {
    switch (error.code_hex) {
      case '0201':
        setShowVerifyEmail(true);
        break;
      case '0202':
        setShowVerifyPhone(true);
        break;
      case '0203':
        setShow30DayHold(true);
        break;
      case '0606':
        setNotEnoughPoints(true);
        break;
      case '0608':
        setNotEnoughSurveys(true);
        break;
      default:
        setSomethingWrong(true);
        if (error.message) {
          setErrorMessage(error.message);
        }
        if (error.options) {
          setErrorOptions(error.options);
        }
        break;
    }
  };

  return (
    <>
      <button className="text-center" onClick={redeemReward}>
        <div className="font-bold">{numberFormat(min_value_points)} Points</div>

        <div className="relative mt-2 hover:scale-105 transform transition-all duration-200 cursor-pointer">
          <BgImg redeemable={canBeRedeemed} images={image_urls} />
          {canBeRedeemed ? (
            <ReadyBtn loading={loading} />
          ) : (
            <ProgressBar reward={reward} />
          )}
        </div>
      </button>
      {showRedeemModal && (
        <RewardRedeemModal
          item={rewardItem}
          onClose={() => setShowRedeemModal(false)}
        />
      )}
      {showVerifyEmail && (
        <VerifyEmailModal onClose={() => setShowVerifyEmail(false)} />
      )}
      {showVerifyPhone && (
        <VerifyPhoneModal onClose={() => setShowVerifyPhone(false)} />
      )}
      {show30DayHold && (
        <Verify30DayHoldModal onClose={() => setShow30DayHold(false)} />
      )}
      {notEnoughPoints && (
        <NotEnoughPointsModal
          minimumPoints={min_value_points}
          pointsRequired={points_required_for_minimum}
          onClose={() => setNotEnoughPoints(false)}
        />
      )}
      {notEnoughSurveys && errorOptions && (
        <NotEnoughSurveysModal
          minimumSurveys={errorOptions.minimum_tasks}
          surveysRequired={errorOptions.tasks_required_for_minimum}
          onClose={() => setNotEnoughSurveys(false)}
        />
      )}
      {somethingWrong && errorMessage && (
        <TaskSomethingWrongModal
          content={errorMessage}
          onClose={() => setSomethingWrong(false)}
        />
      )}
    </>
  );
};

RewardItem.propTypes = {
  reward: PropTypes.object.isRequired,
};

export default RewardItem;
