import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';
import Alert from '@/components/Alert';
import { post } from '@/config/axios';
import { logAnalyticEvent } from '@/config/analytics';
import usePortal from '@/hooks/usePortal';
import Header from './components/Header';
import ProgressBar from './components/ProgressBar';
import Title from './components/Title';
import Navigation from './components/Navigation';
import QuestionsDispatcher from './components/QuestionsDispatcher';
import ProfilerSkeleton from './components/ProfilerSkeleton';
import ProfilerErrors from './components/ProfilerErrors';
import { setTutorialToShow } from '@/store/slices/actionsSlice';
import { setLockAppToDashboard } from '@/store/slices/configSlice';

const Profiler = ({ surveyId, title, onClose }) => {
  const dispatch = useDispatch();
  const { userId } = useSelector((state) => state.user);
  const [active, setActive] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [previousLoading, setPreviousLoading] = useState(false);
  const [nextLoading, setNextLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [questionData, setQuestionData] = useState(null);
  const [surveyState, setSurveyState] = useState(null);
  const [answer, setAnswer] = useState(null);
  const [errors, setErrors] = useState(null);
  const [hasGlobalError, setHasGlobalError] = useState(false);

  const handleOnClose = () => {
    setActive(false);
    setTimeout(() => {
      onClose();
    }, 400);
  };

  const setData = (data) => {
    data?.question_data && setQuestionData(data.question_data);
    data?.question_data?.progress && setProgress(data.question_data.progress);
    data?.survey_state && setSurveyState(data.survey_state);

    logEvent(data.question_data, 'question-impression');
  };

  const logEvent = (questionData, type) => {
    logAnalyticEvent('profiler', type, {
      profiler_id: surveyId,
      profiler_title: title,
      question_id: questionData?.id,
      question_title: questionData?.heading,
    });
  };

  const getSurvey = async () => {
    setHasGlobalError(false);
    try {
      const { data } = await post(
        `v1/survey/start`,
        {
          survey_id: surveyId,
        },
        {
          user_id: userId,
        },
        true
      );
      setData(data.data);
      setLoading(false);
      setPageLoading(false);
    } catch (err) {
      setHasGlobalError(true);
      setPageLoading(false);
    }
  };

  const onPrevious = async () => {
    setHasGlobalError(false);
    setPreviousLoading(true);
    setErrors(null);
    try {
      const { data } = await post(
        `v1/survey/back`,
        {
          survey_state: surveyState,
        },
        {
          user_id: userId,
        },
        true
      );
      setLoading(true);
      setData(data.data);
      setAnswer(null);
      setPreviousLoading(false);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setHasGlobalError(true);
      setPreviousLoading(false);
    }
  };

  const onNext = async () => {
    if (!answer) return;

    logEvent(questionData, 'question-answer');
    setHasGlobalError(false);
    setErrors(null);
    setNextLoading(true);
    const delta = {
      question_id: questionData.id,
      answer,
    };

    try {
      const { data } = await post(
        `v1/survey/answer`,
        {
          survey_state: surveyState,
          delta,
        },
        {
          user_id: userId,
        },
        true
      );

      if (data.data.survey_complete) {
        handleCompletedProfiler();
        return;
      }
      setLoading(true);
      setAnswer(null);
      setData(data.data);
      setNextLoading(false);
      setLoading(false);
    } catch (err) {
      setNextLoading(false);
      setLoading(false);
      err.response?.data?.errors && setErrors(err.response.data.errors);
    }
  };

  const handleCompletedProfiler = () => {
    dispatch(setTutorialToShow(''));
    dispatch(setLockAppToDashboard(false));

    logAnalyticEvent('profiler', 'complete', {
      profiler_id: surveyId,
      profiler_title: title,
    });

    handleOnClose();
  };

  const handleOnChange = (answer) => {
    setErrors(null);
    setAnswer(answer);
  };

  useEffect(() => {
    setTimeout(() => {
      setActive(true);
    }, 10);

    getSurvey();
  }, []);

  return createPortal(
    <section
      className={`${
        active
          ? 'translate-y-0  opacity-100 visible'
          : 'translate-y-28 invisible opacity-0'
      } transform transition-all duration-200 fixed overflow-y-auto inset-0 z-400 bg-white`}
    >
      <Header title={title} onClose={handleOnClose} />

      {pageLoading ? (
        <ProfilerSkeleton />
      ) : (
        <section className="max-w-3xl mx-auto p-8">
          {hasGlobalError && (
            <Alert type="error">
              Something went wrong while trying to load the content. Please
              refresh the page and try again. <br />
              If the issue persists, please{' '}
              <Link to="/contact">
                <u>contact us</u>
              </Link>
              .
            </Alert>
          )}

          <ProgressBar progress={progress} />
          <Title data={questionData} />
          {!loading && (
            <QuestionsDispatcher
              data={questionData}
              onChange={handleOnChange}
            />
          )}
          {errors && errors.length && <ProfilerErrors errors={errors} />}
          <Navigation
            showPrevious={
              questionData?.back_button && questionData?.progress > 0
            }
            showNext={!!answer}
            onPrevious={onPrevious}
            onNext={onNext}
            previousLoading={previousLoading}
            nextLoading={nextLoading}
          />
        </section>
      )}
    </section>,
    usePortal('profiler-root')
  );
};

Profiler.propTypes = {
  title: PropTypes.string.isRequired,
  surveyId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Profiler;
