import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { logAnalyticEvent } from '@/config/analytics';

const TaskTutorialInfo = ({
  title = "Let's get to know each other!",
  text = '',
  source = '',
  isDown = false,
}) => {
  const ref = useRef();

  const isReferrerTask = source === 'GoalReferral';

  // const title = isReferrerTask ? '' : '';

  const className = `${
    isDown ? 'top-full animate-fadeInBottom' : 'bottom-full animate-fadeInTop'
  } absolute z-200 `;

  const content = text
    ? text
    : isReferrerTask
    ? 'Click here to invite a friend today!'
    : 'In order to provide you the best offer we need to get to know a bit about you.';

  const scrollToTutorial = () => {
    if (!ref.current) return;
    const el = ref.current?.getBoundingClientRect();
    if (!el?.top || !el?.height) return;

    const { top: elTop, height: elHeight } = el;
    const scrollTopDistance = window.scrollY + elTop - 130;

    if (el && window.scrollY > elHeight) {
      window.scrollTo({
        top: scrollTopDistance,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  const logTutorialEvent = () => {
    const tutorial_id = isReferrerTask ? 'referrer' : 'ftue';
    const tutorial_title = isReferrerTask
      ? 'Invite a friend'
      : 'First Time User';

    logAnalyticEvent('tutorial ', 'start', {
      tutorial_id: 'ftue',
      tutorial_title,
    });
    logAnalyticEvent('tutorial ', 'step', {
      tutorial_id,
      tutorial_title,
      step_id: 0,
    });
  };

  useEffect(() => {
    scrollToTutorial();
    logTutorialEvent();
    window.addEventListener('resize', () => scrollToTutorial());
    return window.removeEventListener('resize', () => scrollToTutorial());
  }, []);

  return (
    <div ref={ref} className={className}>
      <div className="bg-green rounded p-3 relative top-2">
        {!isReferrerTask && <h4 className="text-white">{title}</h4>}
        <p className="text-white">{content}</p>

        <div
          className={`w-4 h-4 absolute rounded bg-green transform ${
            isDown ? '-top-1.5 rotate-45' : 'rotate-45 -bottom-1.5'
          }  `}
        />
      </div>
    </div>
  );
};

TaskTutorialInfo.propTypes = {
  source: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  isDown: PropTypes.bool,
};

export default TaskTutorialInfo;
