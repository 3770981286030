import PropTypes from 'prop-types';
import Button from '@/components/Button';
import TaskDetailHeader from './TaskDetailHeader';
import TaskContainer from './TaskContainer';

const TaskBallotLoginReward = ({ onClose }) => {
  const task = {
    name: 'Congratulations',
    design_class: 'Trophy',
  };

  return (
    <TaskContainer>
      <TaskDetailHeader task={task} onClose={onClose} />
      <section className="mt-4">
        <p className="px-5 md:px-8 md:text-lg text-center">
          You have earned <strong>1 ballot</strong> by collecting 5 login
          stamps, collect your reward. Keep up the good work!
        </p>
      </section>

      <footer className="p-5 pt-8 md:p-8">
        <Button onClick={onClose} variant="success" className="w-full">
          Collect
        </Button>
      </footer>
    </TaskContainer>
  );
};

TaskBallotLoginReward.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default TaskBallotLoginReward;
