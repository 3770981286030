import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import usePortal from '@/hooks/usePortal';
import PointsDisplay from './PointsDisplay';

const CoinsAnimationV2 = ({ isAnimating = false, onDone }) => {
  const [screenWidth, setScreenWidth] = useState(400);
  const [screenHeight, setScreenHeight] = useState(400);
  const [counterPos, setCounterPos] = useState(0);
  const [g, setG] = useState(null);

  /////////////////////////////////////////////////////////////////
  // Configuration
  /////////////////////////////////////////////////////////////////

  // coin
  const imgEl = useRef();

  // number of coins in animation
  const num_coins = 96;

  // time scalar
  const time_scale = 1;

  /////////////////////////////////////////////////////////////////
  // State
  /////////////////////////////////////////////////////////////////

  // array of coin objects
  let coins;

  // number of coins which are collected
  let num_coins_collected;

  // time at start of simulation
  let time_t0;

  let winAnimReq = null;

  const handleResize = () => {
    getCounterPosition();
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
  };

  // hermite interpolation
  const hermite = (y0, y1, y2, y3, mu, tension = 0, bias = 0) => {
    let mu2 = mu * mu;
    let mu3 = mu2 * mu;

    let m0 = ((y1 - y0) * (1.0 + bias) * (1.0 - tension)) / 2.0;
    m0 += ((y2 - y1) * (1.0 - bias) * (1.0 - tension)) / 2.0;
    let m1 = ((y2 - y1) * (1.0 + bias) * (1.0 - tension)) / 2.0;
    m1 += ((y3 - y2) * (1.0 - bias) * (1.0 - tension)) / 2.0;

    let a0 = 2.0 * mu3 - 3.0 * mu2 + 1.0;
    let a1 = mu3 - 2.0 * mu2 + mu;
    let a2 = mu3 - mu2;
    let a3 = -2.0 * mu3 + 3.0 * mu2;

    return a0 * y1 + a1 * m0 + a2 * m1 + a3 * y2;
  };

  // random int between inclusive min and exclusive max
  const randi = (min, max) => Math.floor(Math.random() * (max - min)) + min;

  const init = () => {
    // reset numebr of coins collected
    num_coins_collected = 0;

    // reset simulation start time
    time_t0 = undefined;

    // init coins
    coins = [];

    const coinsStart = document.getElementById('coinsStart');
    let coinsStartX = screenWidth / 2;
    let coinsStartY = screenHeight / 2;

    if (coinsStart) {
      const { x, y } = coinsStart.getBoundingClientRect();
      coinsStartX = x;
      coinsStartY = y;
    }

    for (let i = 0; i < num_coins; i++) {
      let x = coinsStartX;
      let y = coinsStartY;

      let x1 = x + randi(-16, 16);
      let y1 = y + 256 + randi(-8, 8);
      let x2 = x + screenWidth / 4 + randi(-2, 2);
      let y2 = y - 256 + randi(-2, 2);

      let theta = Math.random() * Math.PI;
      let magnitude = 512 + randi(0, 1024);

      let x0 = x1 + Math.cos(theta) * magnitude;
      let y0 = y1 + Math.sin(theta) * magnitude;
      let x3 = x2 - 1000;
      let y3 = y2;

      let dt = (i * 0.75) / num_coins;

      coins.push({
        x0: x0,
        y0: y0,
        x1: x1,
        y1: y1,
        x2: x2,
        y2: y2,
        x3: x3,
        y3: y3,
        dt: dt,
        state: 0,
        k: 0,
      });
    }
  };

  const tick = (timestamp) => {
    // time
    if (time_t0 === undefined) {
      time_t0 = timestamp;
    }
    const t = ((timestamp - time_t0) / 1000.0) * time_scale;

    // update coins
    for (let i = 0; i < coins.length; i++) {
      let coin = coins[i];

      coin.k = Math.min(Math.max(t - coin.dt, 0), 1);

      // update
      if (coin.state === 0 && coin.k >= 1) {
        num_coins_collected++;

        coin.state = 1;
      }
    }

    // render the background
    g.clearRect(0, 0, screenWidth, screenHeight);
    g.strokeStyle = '#808080';
    g.beginPath();
    g.rect(1, 1, screenWidth - 2, screenHeight - 2);
    g.stroke();

    const pointsTarget = document.querySelector('#pointsDot2');
    const {
      x: pointsTargetX,
      y: pointsTargetY,
    } = pointsTarget.getBoundingClientRect();

    // render the coins
    for (let i = 0; i < coins.length; i++) {
      let coin = coins[i];

      let k = coin.k;

      // render
      if (k > 0 && k < 1) {
        let x = hermite(
          coin.x0,
          coin.x1,
          pointsTargetX - 100,
          coin.x3,
          k,
          2,
          0
        );
        let y = hermite(
          coin.y0,
          coin.y1,
          pointsTargetY + 100,
          coin.y3,
          k,
          2,
          0
        );

        let w = 12;
        let s = 1.0 - k / 2.0;

        g.save();
        g.translate(x, y);
        g.scale(s, s);
        g.drawImage(imgEl.current, 200, -200, w, 12);
        g.restore();
      }
    }

    winAnimReq = window.requestAnimationFrame(tick);

    if (num_coins_collected === num_coins) {
      window.cancelAnimationFrame(winAnimReq);
      onDone();
      return;
    }
  };

  const getCounterPosition = () => {
    const ref = document.querySelector('#points');
    if (!ref) return;
    const { left } = ref.getBoundingClientRect();

    setCounterPos(left);
  };

  useEffect(() => {
    if (!isAnimating) return;

    handleResize();
    setG(document.getElementById('canvas').getContext('2d'));
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isAnimating]);

  useEffect(() => {
    if (g) {
      init();
      winAnimReq = window.requestAnimationFrame(tick);
    }
  }, [g]);

  useEffect(() => {
    getCounterPosition();
  }, []);

  return createPortal(
    <div>
      <div
        style={{ left: `${counterPos}px` }}
        className={`fixed z-god top-0 bg-white p-3 font-bold rounded-full  items-center px-3 h-12 ${
          isAnimating ? 'flex' : 'hidden'
        }`}
      >
        <div id="pointsDot2" className="mr-1 h-2 rounded-full bg-green w-2 " />
        <PointsDisplay />
      </div>
      {isAnimating && (
        <>
          <canvas
            id="canvas"
            width={screenWidth}
            height={screenHeight}
            className="inset-0 fixed z-god"
          />
          <img
            ref={imgEl}
            src="/img/coin.svg"
            alt="coin"
            style={{ display: 'none', zIndex: 401, position: 'relative' }}
          />
        </>
      )}
    </div>,
    usePortal('coins-root')
  );
};

CoinsAnimationV2.propTypes = {
  isAnimating: PropTypes.bool,
  onDone: PropTypes.func.isRequired,
};

export default CoinsAnimationV2;
