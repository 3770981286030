import PropTypes from 'prop-types';
import { useState } from 'react';

const FaqItem = ({ data }) => {
  const { question, answer } = data;
  const [open, setOpen] = useState(false);
  return (
    <button
      className="group text-left mb-5 sm:mb-8"
      onClick={() => setOpen(!open)}
    >
      <div
        className={`sm:text-xl font-bold transition-colors duration-200 flex justify-between items-center ${
          open ? 'text-blue-500' : 'group-hover:text-gray-500 '
        }`}
      >
        <div>{question}</div>
        <div>
          {open ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="-5 -11 24 24"
              width="18"
              fill="currentColor"
            >
              <path d="M1 0h12a1 1 0 0 1 0 2H1a1 1 0 1 1 0-2z" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="-4.5 -4.5 24 24"
              width="20"
              fill="currentColor"
            >
              <path d="M8.9 6.9v-5a1 1 0 1 0-2 0v5h-5a1 1 0 1 0 0 2h5v5a1 1 0 1 0 2 0v-5h5a1 1 0 1 0 0-2h-5z" />
            </svg>
          )}
        </div>
      </div>
      <p
        className={`sm:text-base text-sm mt-2 overflow-hidden transition-all duration-300 ${
          open
            ? ' transform scale-100 max-h-999 opacity-100'
            : ' scale-95 max-h-0 opacity-0'
        }`}
      >
        {answer}
      </p>
    </button>
  );
};

FaqItem.propTypes = {
  data: PropTypes.object.isRequired,
};

export default FaqItem;
