import PropTypes from 'prop-types';
import Button from '@/components/Button';
import TaskDetailHeader from './TaskDetailHeader';
import TaskContainer from './TaskContainer';

const TaskWelcome = ({ onClose }) => {
  const task = {
    name: 'Welcome to Communa!',
    design_class: 'Welcome',
  };

  return (
    <TaskContainer>
      <TaskDetailHeader task={task} onClose={onClose} />
      <section className="mt-4">
        <p className="px-5 md:px-8 md:text-lg text-center">
          We&apos;re excited to have you as a part of our community. First
          let&apos;s take a quick tour.
        </p>
      </section>

      <footer className="p-5 pt-8 md:p-8">
        <Button onClick={onClose} variant="success" className="w-full">
          Ok
        </Button>
      </footer>
    </TaskContainer>
  );
};

TaskWelcome.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default TaskWelcome;
