import AnimatedSection from '@/components/AnimatedSection';
import GetStartedBtn from '@/components/GetStartedBtn';
import Illustration from './components/Illustration';

const SignupV2Ready = () => {
  return (
    <AnimatedSection className="relative overflow-hidden py-16 md:py-32 px-5">
      {(animate) => (
        <div className="max-w-4xl mx-auto relative text-center z-10">
          <h3 className={`text-2xl md:text-3xl anim ${animate ? 'anim0' : ''}`}>
            Ready to join Communa? <br /> Click below and start earning!
          </h3>
          {/* <p
            className={`text-base lg:text-lg mt-2 anim ${
              animate ? 'anim1' : ''
            }`}
          >
            Click below and start earning!
          </p> */}
          <div
            className={`flex justify-center mt-3 anim ${
              animate ? 'anim1-5' : ''
            }`}
          >
            <GetStartedBtn />
          </div>
          <div
            className={`flex justify-center md:mt-6 anim ${
              animate ? 'anim1-5' : ''
            }`}
          >
            <Illustration />
          </div>
        </div>
      )}
    </AnimatedSection>
  );
};

export default SignupV2Ready;
