import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const BlogSeeAlso = ({ article }) => {
  const { id, title, featured_image, slug } = article;

  return (
    <section className="flex flex-col md:flex-row border-t border-grey-250 mt-8 pt-8">
      <Link
        to="/blog"
        className="p-3 bg-blue text-white hover:shadow-lg hover:bg-blue-400 transition-all duration-200 text-2xl font-bold rounded flex-1 mr-5 h-40 flex items-center justify-center"
      >
        Back To Blogs
      </Link>
      <Link
        to={`/blog/${id}/${slug}`}
        className="p-5 blog-link flex-1 rounded h-40 hover:shadow transition-all duration-200 bg-grey font-bold text-shadow flex items-center text-2xl text-white overflow-hidden relative"
        style={{
          background: `url(${featured_image}) center center / cover`,
        }}
      >
        <span className="relative z-10">{title}</span>
      </Link>
    </section>
  );
};

BlogSeeAlso.propTypes = {
  article: PropTypes.object.isRequired,
};

export default BlogSeeAlso;
