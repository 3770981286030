import { createSlice } from '@reduxjs/toolkit';

export const configSlice = createSlice({
  name: 'config',
  initialState: {
    showGlobalCurtain: false,
    isOnline: true,
    samplechain: null,
    lockAppToDashboard: false,
    cmpn: null,
    campaignInfo: {
      campaign_id: 'organic',
      campaign_name: 'organic',
      source_class: 'organic',
      source_name: 'organic',
    },
    isFailed: false,
    isTaskOpen: false,
    signupVariant: '',
    leadId: null,
    cbdOffer: null,
  },
  reducers: {
    setIsTaskOpen: (state, { payload }) => {
      state.isTaskOpen = payload;
    },
    setShowGlobalCurtain: (state, { payload }) => {
      state.showGlobalCurtain = payload;
    },
    setIsOnline: (state, { payload }) => {
      state.isOnline = payload;
    },

    setSamplechain: (state, { payload }) => {
      state.samplechain = payload;
    },

    setLockAppToDashboard: (state, { payload }) => {
      state.lockAppToDashboard = payload;
    },
    setCmpn: (state, { payload }) => {
      state.cmpn = payload;
    },
    setCampaignInfo: (state, { payload }) => {
      state.campaignInfo = payload;
    },
    setIsFailed: (state, { payload }) => {
      state.isFailed = payload;
    },
    setSignupVariant: (state, { payload }) => {
      state.signupVariant = payload;
    },
    setLeadId: (state, { payload }) => {
      state.leadId = payload;
    },
    setCBDOffer: (state, { payload }) => {
      state.cbdOffer = payload;
    },
  },
});

export const {
  setShowGlobalCurtain,
  setIsOnline,
  setSamplechain,
  setIsTaskOpen,
  setLockAppToDashboard,
  setCmpn,
  setCampaignInfo,
  setIsFailed,
  setSignupVariant,
  setLeadId,
  setCBDOffer,
} = configSlice.actions;

export default configSlice.reducer;
