import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getToken } from '@/utils/token';
import withSession from '@/hoc/withSession';
import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';
import AppNav from '@/components/AppNav';
import EventsManager from '@/components/EventsManager';
import ActionsManager from '@/components/ActionsManager';
import OfflineModal from '@/components/OfflineModal';
import LoadingEllipsis from '@/components/LoadingEllipsis';
import ErrorBoundary from '@/components/ErrorBoundary';
import useCbdOffer from '@/hooks/useCbdOffer';

const AppLayout = ({ payloadLoaded }) => {
  const location = useLocation();
  const navigateTo = useNavigate();
  const wrapperRef = useCbdOffer();

  const { lockAppToDashboard } = useSelector((state) => state.config);

  useEffect(() => {
    if (lockAppToDashboard && !location.pathname.includes('dashboard')) {
      navigateTo('/dashboard');
    }
  }, [location, lockAppToDashboard]);

  if (!getToken()) {
    window.location.href = '/signup';
    return;
  }

  return (
    <>
      <div
        className="bg-grey-200 flex flex-col min-h-screen pt-12"
        ref={wrapperRef}
      >
        <AppHeader />
        <main className="flex w-full max-w-8xl mx-auto">
          <AppNav />
          <section className="flex-1 md:px-10 md:py-8 p-6">
            <ErrorBoundary>
              {payloadLoaded ? (
                <Outlet />
              ) : (
                <div className="flex items-center h-full justify-center text-blue">
                  <LoadingEllipsis width={40} />
                </div>
              )}
            </ErrorBoundary>
          </section>
        </main>

        <div className="mt-auto">
          <AppFooter />
        </div>
      </div>
      <EventsManager />
      <ActionsManager />
      <OfflineModal />
    </>
  );
};

AppLayout.propTypes = {
  payloadLoaded: PropTypes.bool.isRequired,
};

export default withSession(AppLayout);
