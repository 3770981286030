import PropTypes from 'prop-types';
import Modal from '@/components/Modal';
import LeftSection from './components/LeftSection';
import SignupFormV2 from './components/SignupFormV2';

const SignupV2SignupModal = ({ onClose }) => {
  return (
    <Modal onClose={onClose} maxWidth="max-w-4xl">
      {(onClose) => (
        <div className="md:flex bg-white">
          <LeftSection />
          <SignupFormV2 />
        </div>
      )}
    </Modal>
  );
};

SignupV2SignupModal.propTypes = {
  onClose: PropTypes.func,
};

export default SignupV2SignupModal;
