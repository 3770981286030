import { Link } from 'react-router-dom';
import Logo from '@/components/Logo';
import SocialLink from '@/components/SocialLink';

import navData from './navData';

const AppFooter = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="text-center border-t border-grey-250 py-8 px-6">
      <div className="flex justify-center px-3 pt-3 pb-2">
        <Logo doNotBreak />
      </div>
      <nav className="mx-auto py-3 max-w-2xl flex flex-wrap justify-center">
        {navData.map(({ to, label }, i) => (
          <div key={`ft-${i}`} className="px-3 py-0.5">
            <Link
              to={to}
              className="text-sm font-montserrat font-bold text-grey-600 hover:text-blue transition-colors duration-200"
            >
              {label}
            </Link>
          </div>
        ))}
      </nav>
      <nav className="flex justify-center py-1">
        <SocialLink />
        <SocialLink type="twitter" className="text-grey-600 mx-3" />
        <SocialLink type="instagram" />
      </nav>
      <div className="pt-6 pb-16">
        <small className="text-grey text-xs">
          Copyright {year} Communa. All Rights Reserved.
        </small>
      </div>
    </footer>
  );
};

export default AppFooter;
