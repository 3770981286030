const ReviewsSumup = () => {
  const scrollToTestimonials = () => {
    const testimonials = document.querySelector('#testimonials');
    testimonials.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <button
      onClick={scrollToTestimonials}
      className="mt-3 hover:bg-gray-200 p-1 -mx-1 rounded-md transition-all duration-200 group"
    >
      <div className="flex items-center text-gray-800">
        {Array.from(Array(4).keys()).map((s, i) => (
          <svg
            key={`st-${i}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-2 -2 24 24"
            width="16"
            fill="currentColor"
            className="group-hover:text-yellow-500 transition-colors duration-200"
          >
            <path d="M10 16.379l-6.173 3.245 1.179-6.874L.01 7.882l6.902-1.003L10 .624l3.087 6.255 6.902 1.003-4.995 4.868 1.18 6.874z" />
          </svg>
        ))}
        <svg
          width="14"
          height="13"
          viewBox="0 0 14 13"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none">
            <polygon
              fill="currentColor"
              className="group-hover:text-yellow-500 transition-colors duration-200"
              points="6.66 10.5033333 2.54466667 12.6666667 3.33066667 8.084 0 4.83866667 4.60133333 4.17 6.66 0"
            />
            <polygon
              fill="#ddd"
              transform="matrix(-1 0 0 1 19.944 0)"
              points="13.301992 10.5033333 9.18665868 12.6666667 9.97265868 8.084 6.64199201 4.83866667 11.2433253 4.17 13.301992 0"
            />
          </g>
        </svg>
      </div>
      <p className="mt-0.5 text-xs text-gray-800">
        4.7 out of 5 by 1,374 People
      </p>
    </button>
  );
};

export default ReviewsSumup;
