import { useEffect } from 'react';

import NavItem from './components/NavItem';
import SkeletonLoading from './components/SkeletonLoading';
import SponsoredNav from './components/SponsoredNav';
import TutorialBackground from '@/components/TutorialBackground';
import { useSelector, useDispatch } from 'react-redux';
import { setTutorialToShow } from '@/store/slices/actionsSlice';

const AppNav = () => {
  const dispatch = useDispatch();
  const { addPoints, isAdmin, data } = useSelector((state) => state.user);
  const { list: flags } = useSelector((state) => state.flags);
  const { tutorialToShow } = useSelector((state) => state.actions);

  const hasUserFlagsData =
    Object.keys(data).length > 0 && Object.keys(flags).length > 0;

  const showTutorial = tutorialToShow === 'refer_a_friend';

  const tutorialBgClickHandler = () => {
    // When the background is clicked, clear the tutorial
    dispatch(setTutorialToShow(''));
  };

  return (
    <aside
      className={`${
        addPoints ? 'z-1' : 'z-10'
      } bg-grey-100 md:bg-transparent fixed md:relative w-auto md:w-44 xl:w-56 md:py-3 bottom-0 shadow md:shadow-none md:bottom-auto md:left-auto left-0 md:right-auto right-0`}
    >
      {hasUserFlagsData ? (
        <nav className="flex md:flex-col flex-row">
          <NavItem to="/dashboard" label="Dashboard" />
          <NavItem to="/redeem" label="Redeem" />
          {flags && flags.referral && (
            <NavItem
              to="/refer-and-earn"
              label="Refer and Earn"
              showTutorial={showTutorial}
              onClick={() => dispatch(setTutorialToShow(''))}
            />
          )}
          <NavItem to="/timeline" label="Timeline" />
          {isAdmin && <NavItem to="/admin" label="Admin" external />}
        </nav>
      ) : (
        <SkeletonLoading />
      )}

      <SponsoredNav />
      {showTutorial && <TutorialBackground onClick={tutorialBgClickHandler} />}
    </aside>
  );
};

export default AppNav;
