import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormCheckbox from '@/components/FormCheckbox';
import FormItem from '@/components/FormItem';
import Row from './Row';

const OptionsMulti = ({ data, onChange }) => {
  const { answer_set, id } = data;
  const [preAnswers, setPreAnswers] = useState();

  const handleOnChange = (e) => {
    const name = e.target.name;
    const value = answer_set.find(({ id }) => id === name);
    const exclusiveAnswers = answer_set.filter((answer) => answer.exclusive);
    const isText = value.text;
    const checkState = preAnswers ? !preAnswers[name] : true;

    // Exclusive value means that all other values will be
    // ignored. For instance, a "None" option that uncheck all
    // other previously checked options.
    if (value.exclusive) {
      // if true, add the key/pair, if false, reset answer set to null
      const exclValue = checkState ? { [name]: checkState } : null;
      setPreAnswers(exclValue);
      return;
    }

    const answersClone = {
      ...preAnswers,
      [name]: isText ? e.target.value : checkState,
    };

    // If the exclusive answers contain an answer that is in the currently selected answers object
    const exclusiveMatch = exclusiveAnswers.find(
      (excl) => answersClone[excl.id]
    );

    if (exclusiveMatch) {
      delete answersClone[exclusiveMatch.id];
    }

    // cleanup object if falsy pairs remain
    for (const name in answersClone) {
      if (!isText && answersClone[name] === false) {
        delete answersClone[name];
      }
    }

    const preAnswersClone = !Object.entries(answersClone).length
      ? null
      : answersClone;

    setPreAnswers(preAnswersClone);
  };

  useEffect(() => {
    onChange(preAnswers);
  }, [preAnswers]);

  return (
    <section>
      {answer_set.map(({ id, label, text }, i) => (
        <Row key={`ro-${i}`}>
          {text ? (
            <div className="pl-2 py-4">
              <label className="text-sm font-bold mb-1 block">{label}</label>
              <FormItem
                value={preAnswers?.[id] ? preAnswers[id] : ''}
                name={id}
                onChange={handleOnChange}
              />
            </div>
          ) : (
            <FormCheckbox
              checked={preAnswers?.[id] ? preAnswers[id] : false}
              name={id}
              label={label}
              onChange={handleOnChange}
              className="py-4"
            />
          )}
        </Row>
      ))}
    </section>
  );
};

OptionsMulti.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default OptionsMulti;
