import { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeFieldErrors } from '@/utils/helpers';
import { post } from '@/config/axios';
import FormItem from '@/components/FormItem';
import Fieldset from '@/components/Fieldset';
import Button from '@/components/Button';
import FormWrapper from '@/components/FormWrapper';
import Alert from '@/components/Alert';

const ProfilePasswordUpdate = () => {
  const { userId } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [formData, setFormData] = useState({
    password_old: null,
    password: null,
    password_confirm: null,
  });

  const [fieldErrors, setFieldErrors] = useState();
  const [formError, setFormError] = useState();

  const handleOnChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async () => {
    setFieldErrors();
    setFormError();
    setLoading(true);
    setShowSuccess(false);

    const postData = {
      ...formData,
      user_id: userId,
      speculative: false,
    };

    try {
      await post('v1/user/update-password', postData);
      setShowSuccess(true);
      setLoading(false);
    } catch (err) {
      const { fieldErrors, formError } = makeFieldErrors(
        err?.response?.data?.errors
      );
      if (fieldErrors) {
        setFieldErrors(fieldErrors);
      }
      if (formError) {
        setFormError(formError);
      }
      setLoading(false);
    }
  };

  const isDisabled =
    !formData.password_old ||
    !formData.password ||
    !formData.password_confirm ||
    loading;

  return (
    <Fieldset title="Update Password">
      <FormWrapper onSubmit={onSubmit} error={formError}>
        <FormItem
          label="Current Password"
          name="password_old"
          type="password"
          autoComplete="current-password"
          className="mt-4"
          error={fieldErrors?.password_old}
          onChange={handleOnChange}
        />
        <FormItem
          label="New Password"
          name="password"
          type="password"
          autoComplete="new-password"
          className="mt-4"
          error={fieldErrors?.password}
          onChange={handleOnChange}
        />
        <FormItem
          label="Confirm Password"
          name="password_confirm"
          type="password"
          autoComplete="new-password"
          className="my-4"
          error={fieldErrors?.password_confirm}
          onChange={handleOnChange}
        />
        {showSuccess && (
          <div className="mt-4">
            <Alert type="success">
              Your information has been successfully saved!
            </Alert>
          </div>
        )}
        <Button loading={loading} disabled={isDisabled}>
          Update
        </Button>
      </FormWrapper>
    </Fieldset>
  );
};

export default ProfilePasswordUpdate;
