import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { removeAction } from '@/store/slices/actionsSlice';
import Modal from '@/components/Modal';
import PageHeader from '@/components/PageHeader';
import Button from '@/components//Button';
import Alert from '@/components/Alert';
import { post } from '@/config/axios';
import { logAnalyticEvent } from '@/config/analytics';

const TermsModal = ({ terms }) => {
  const dispatch = useDispatch();
  const { userId } = useSelector((state) => state.user);
  const [error, showError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOnClose = () => {
    dispatch(removeAction(terms.id));
  };

  const handleSubmit = async (onClose) => {
    logAnalyticEvent('terms-popup', 'accept');
    setLoading(true);
    showError(false);
    try {
      await post('v1/action/terms-agree/complete', null, {
        user_id: userId,
      });
      onClose();
      setLoading(false);
    } catch (err) {
      showError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    logAnalyticEvent('terms-popup', 'impression');
  }, []);

  return (
    <Modal maxWidth="max-w-lg" persistOverlay onClose={handleOnClose}>
      {(onClose) => (
        <div className="px-8 bg-grey-200">
          <header>
            <PageHeader
              title="Terms and Privacy Policy"
              small
              className="pt-6 pb-4"
            />
            <p className="text-lg text-center">
              Before we continue please accept communa Terms and Privacy Policy.
            </p>
          </header>

          {error && (
            <div className="my-2">
              <Alert type="error">
                Something went wrong. Please try again. If the issue persists,
                please contact us.
              </Alert>
            </div>
          )}

          <section className="border border-grey-300 my-4 rounded h-60 overflow-y-auto px-3 pb-3">
            <div
              className="page"
              dangerouslySetInnerHTML={{ __html: terms.terms }}
            />
            <div
              className="page"
              dangerouslySetInnerHTML={{ __html: terms.privacy }}
            />
          </section>
          <div className="pb-6">
            <Button
              className="w-full"
              variant="success"
              onClick={() => handleSubmit(onClose)}
              loading={loading}
            >
              I Accept the Terms and Privacy Policy
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

TermsModal.propTypes = {
  terms: PropTypes.object.isRequired,
};

export default TermsModal;
