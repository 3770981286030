import PropTypes from 'prop-types';
import Button from '@/components/Button';
import TaskDetailHeader from './TaskDetailHeader';
import { numberFormat } from '@/utils/helpers';
import TaskContainer from './TaskContainer';

const TaskNotEnoughSurveys = ({ minimumSurveys, surveysRequired, onClose }) => {
  const task = {
    name: 'You have not completed enough surveys!',
    design_class: 'Locked',
  };

  const widthPercent = `${Math.floor(
    (1 - surveysRequired / minimumSurveys) * 100
  )}%`;

  return (
    <TaskContainer>
      <TaskDetailHeader task={task} onClose={onClose} />
      <section className="mt-4 px-5 md:px-8 ">
        <p className="md:text-lg text-center">
          Complete some more surveys to unlock rewards.
          <br />
          <br />
          You need <strong>{numberFormat(surveysRequired)}</strong> more surveys
          to get rewards.
        </p>
        <div className="mt-6 max-w-xs mx-auto rounded-full bg-grey-300 h-3 ">
          <div
            className="bg-green rounded-full w-2/3 h-3"
            style={{
              width: widthPercent,
            }}
          />
        </div>
      </section>

      <footer className="p-5 pt-8 md:p-8">
        <Button to="/dashboard" variant="success" className="w-full">
          Return To Dashboard
        </Button>
      </footer>
    </TaskContainer>
  );
};

TaskNotEnoughSurveys.propTypes = {
  minimumSurveys: PropTypes.number.isRequired,
  surveysRequired: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TaskNotEnoughSurveys;
