import PropTypes from 'prop-types';

const Brand = ({ children, className = '' }) => {
  return (
    <div
      className={`flex items-center px-12 py-6 justify-center border-dashed ${className}`}
    >
      <div className="w-24 h-11 flex items-center justify-center">
        {children}
      </div>
    </div>
  );
};

Brand.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Brand;
