import PropTypes from 'prop-types';

const Rating = ({ rating }) => {
  const baseRate = Math.floor(rating);
  const hasHalf = rating % 1 !== 0;

  return (
    <div className="flex items-center">
      {[...Array(rating)].map((s, i) => (
        <svg
          key={`st-${i}`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="-2 -2 24 24"
          width="16"
          fill="currentColor"
          className="text-yellow-500"
        >
          <path d="M10 16.379l-6.173 3.245 1.179-6.874L.01 7.882l6.902-1.003L10 .624l3.087 6.255 6.902 1.003-4.995 4.868 1.18 6.874z" />
        </svg>
      ))}
      {[...Array(5 - rating)].map((s, i) => (
        <svg
          key={`nst-${i}`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="-2 -2 24 24"
          width="16"
          fill="currentColor"
          className="text-gray-300"
        >
          <path d="M10 16.379l-6.173 3.245 1.179-6.874L.01 7.882l6.902-1.003L10 .624l3.087 6.255 6.902 1.003-4.995 4.868 1.18 6.874z" />
        </svg>
      ))}
    </div>
  );
};

Rating.propTypes = {
  rating: PropTypes.number.isRequired,
};

export default Rating;
