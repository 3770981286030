import Logo from '@/components/Logo';
import GetStartedBtn from '@/components/GetStartedBtn';
import Illustration from './components/Illustration';
import Rewards from './components/Rewards';
import ReviewsSumup from './components/ReviewsSumup';
import { Link } from 'react-router-dom';

const SignupV2Hero = () => {
  return (
    <div className="w-full overflow-hidden relative">
      <div className="absolute right-6 z-10 top-6">
        <Link
          className="hover:scale-102 group flex items-center relative  transition-all duration-200 bg-gray-100 rounded-md pl-4 pr-9 py-2  font-bold active:scale-95 transform"
          to="/login"
        >
          <span>Login</span>
          <span className="absolute transform group-hover:translate-x-0.5 transition-all duration-200 right-3.5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="-5 -5 24 24"
              width="20"
              fill="currentColor"
            >
              <path d="M10.586 5.657l-3.95-3.95A1 1 0 0 1 8.05.293l5.657 5.657a.997.997 0 0 1 0 1.414L8.05 13.021a1 1 0 1 1-1.414-1.414l3.95-3.95H1a1 1 0 1 1 0-2h9.586z" />
            </svg>
          </span>
        </Link>
      </div>

      <header className="pt-12 sm:pt-16 md:pt-24 pb-16 sm:pb-24 flex flex-col md:flex-row items-center max-w-7xl mx-auto relative z-1 px-6">
        <section className="md:w-1/2">
          <div className="mb-8 anim anim0-5">
            <Logo />
          </div>
          <h1
            style={{ lineHeight: 1.25 }}
            className="text-3xl sm:text-4xl md:text-5xl anim anim1"
          >
            Give Your Opinion. <br /> Earn
            <Rewards />
            <br /> Gift Cards.
          </h1>
          <p className="sm:text-lg leading-relaxed max-w-md mt-6 mb-8 anim anim1-25">
            The easiest way to earn gift cards online! Spend as little as ten
            minutes a day to give your opinion on surveys that matter to you.
            Express yourself and redeem gift cards from Amazon, Walmart, Visa
            and more!
          </p>
          <div className="anim anim1">
            <GetStartedBtn />
            <ReviewsSumup />
          </div>
        </section>
        <section className="md:w-1/2 md:mt-0 mt-12 anim anim1-5">
          <Illustration />
        </section>
      </header>
      <div className=" -translate-y-1/3 w-150 h-150 md:w-200 md:h-200 rounded-5xl bg-blue-200 absolute top-0 right-0  transform rotate-45" />
    </div>
  );
};

export default SignupV2Hero;
