import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const OldResetPage = () => {
  const navigateTo = useNavigate();

  useEffect(() => {
    navigateTo({
      pathname: '/password/reset',
      search: location.search,
    });
  }, []);

  return <></>;
};

export default OldResetPage;
