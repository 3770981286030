// Mostly code from https://reactjs.org/docs/error-boundaries.html
import React, { Component } from 'react';
import ErrorInner from '@/components/ErrorInner';
import PropTypes from 'prop-types';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  // Has to be called getDerivedStateFromError
  // There is no equivelant of this with hooks, you have to use a class
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // eslint-disable-next-line no-console
    console.error('ErrorBoundary caught an error', error, info);
  }

  render() {
    const title = 'An error has occured';
    const content = 'Oops, something went wrong!';

    if (this.state.hasError) {
      return (
        <div style={{ margin: '1rem' }}>
          <ErrorInner title={title} content={content} />
        </div>
      );
    }

    // Otherwise, just pass through the children
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
