import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation, useNavigate, Outlet } from 'react-router-dom';

import {
  setCmpn,
  setCampaignInfo,
  setSignupVariant,
  setLeadId,
} from '@/store/slices/configSlice';
import { get, post } from '@/config/axios';
import withSession from '@/hoc/withSession';

const SignupV2Layout = () => {
  const dispatch = useDispatch();

  const getLeadId = async () => {
    const leadId = localStorage.getItem('leadId');

    if (leadId) {
      dispatch(setLeadId(leadId));
    } else {
      try {
        const { data } = await get('v1/lead/get-id');
        const leadId = data?.data?.lead_id;

        localStorage.setItem('leadId', leadId);

        dispatch(setLeadId(leadId));

        let campaignId = null;

        if (location.search) {
          campaignId = await logCampaign();
        }

        await postLead('new', campaignId, leadId);
      } catch (err) {
        // console.error(err);
      }
    }
  };

  const logCampaign = async () => {
    let params = location.search;

    if (!params || params.includes('referrer_id')) {
      return;
    }

    params = params.replace('?', '');

    try {
      const { data } = await post(
        'v1/user-action',
        {
          action: 'campaign_click_log',
          query_params: params,
        },
        null,
        true
      );

      dispatch(setCmpn(data?.data?.cmpn));

      if (data?.data) {
        dispatch(setCampaignInfo(data?.data));
        return data?.data?.campaign_id;
      }
    } catch (err) {
      // 🤫
      // Nothing happens on the user side,
      // error is silenced but will be logged in Amplitude
    }
  };

  const postLead = async (variantId, campaignId, leadId) => {
    const requestParams = {
      variant_id: variantId,
      campaign_id: campaignId || 'organic',
      lead_id: leadId,
    };

    await post(
      'v1/user-action',
      {
        action: 'landing_test_variant_assign',
        query_params: requestParams,
      },
      null,
      true
    );
  };

  useEffect(() => {
    getLeadId();
  }, [location]);

  return <Outlet />;
};

export default withSession(SignupV2Layout);
