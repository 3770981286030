import PropTypes from 'prop-types';

const TaskSkeleton = ({ onClose }) => {
  return (
    <div className="bg-white ">
      <div className="task-single animate-pulse">
        <button
          className="text-grey-400 hover:opacity-100 opacity-75 duration-200 transition-opacity absolute top-4 right-4 z-20"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            viewBox="0 0 32 32"
          >
            <polygon
              fill="currentColor"
              points="32 3.8 28.2 0 16 12.2 3.8 0 0 3.8 12.2 16 0 28.2 3.8 32 16 19.8 28.2 32 32 28.2 19.8 16"
            />
          </svg>
        </button>
        <header
          className="h-40 bg-grey-250"
          style={{ clipPath: 'polygon(0 0, 101% 0, 101% 80%, 0% 100%)' }}
        />
        <div className="p-6">
          <div className="h-8 max-w-xs mx-auto mb-6 mt-4 bg-grey-250 rounded" />
          <div className="h-5  mx-auto my-3 bg-grey-250 rounded" />
          <div className="h-5  mx-auto my-3 bg-grey-250 rounded" />
          <div className="h-5 max-w-xs mx-auto my-3 bg-grey-250 rounded" />
          <div className="h-10 mx-auto mt-12 bg-grey-250 rounded" />
        </div>
      </div>
    </div>
  );
};

TaskSkeleton.propTypes = {
  onClose: PropTypes.func,
};

export default TaskSkeleton;
