import AnimatedSection from '@/components/AnimatedSection';
import faqData from './data.json';
import FaqItem from './components/FaqItem';

const SignupV2Faq = () => {
  return (
    <AnimatedSection className="bg-white px-5">
      {(animate) => (
        <div className="max-w-4xl mx-auto py-16 md:py-24 px-5">
          <h3
            className={`text-2xl md:text-3xl text-center max-w-sm mb-10 md:mb-14 mx-auto anim ${
              animate ? 'anim0' : ''
            }`}
          >
            You have a question? We&apos;re here to help!
          </h3>
          <div>
            {faqData.map((faq, i) => (
              <div
                className={`anim ${
                  animate ? `anim${i + 1 < 7 ? i + 1 : 6}` : ''
                }`}
                key={`fi-${i}`}
              >
                <FaqItem data={faq} />
              </div>
            ))}
          </div>
        </div>
      )}
    </AnimatedSection>
  );
};

export default SignupV2Faq;
