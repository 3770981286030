import { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import { formatTextDate } from '@/utils/helpers';
import { get } from '@/config/axios';

const InviteLine = ({ friend }) => {
  const { email, invited_on, earned, has_sign_up, name, rewarded } = friend;
  const [isSentAgain, setIsSentAgain] = useState(false);

  const sendInviteAgain = async () => {
    setIsSentAgain(true);
    try {
      await get('v1/friends/invite-resend', {
        email,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getInfo = () => {
    if (!has_sign_up) {
      const formattedDate = formatTextDate(invited_on);
      return `Invited on ${formattedDate}`;
    }

    if (!rewarded) {
      const pointsLeft = 250 - earned;
      const pts = pointsLeft > 1 ? 'Pts' : 'Pt';
      return `${pointsLeft} ${pts} to bonus!`;
    }

    return 'You earned your bonus!';
  };

  return (
    <div className="border-b last:border-transparent border-grey-27 py-3 flex items-center justify-between">
      <div className="w-auto sm:w-2/3 flex flex-col lg:flex-row lg:items-center">
        <div className="lg:w-1/2">
          {name ? (
            <div className="flex items-center flex-wrap">
              <span className="font-bold mr-1">{name}</span>
              <span className="text-grey-800 text-sm">({email})</span>
            </div>
          ) : (
            <span className="font-bold">{email}</span>
          )}
        </div>
        <div className="text-sm text-grey-600 lg:w-1/2">
          {rewarded && (
            <span className="text-green-600 text-sm font-semibold inline-flex items-center mr-2">
              Congratulations!{' '}
              <span
                role="img"
                className="text-lg leading-[0px]"
                aria-label="clapping hands"
              >
                👏
              </span>
            </span>
          )}
          {getInfo()}
        </div>
      </div>

      <div className="flex justify-end items-center w-20 sm:w-1/3 h-10">
        {!has_sign_up && (
          <Button
            size="small"
            variant="outline"
            onClick={sendInviteAgain}
            disabled={isSentAgain}
          >
            {isSentAgain ? 'Sent!' : 'Resend'}
          </Button>
        )}
      </div>
    </div>
  );
};

InviteLine.propTypes = {
  friend: PropTypes.object.isRequired,
};

export default InviteLine;
