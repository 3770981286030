/**
 * randId
 * Generate a random ID based on Math.random().
 * @param min Number
 * @param max Number
 * @returns string
 */
export const randId = (min = 2, max = 6) =>
  Math.random().toString(36).substr(min, max);

/**
 * setValidationRules
 * set validation rules for form elements.
 * @param props Props object
 * @returns Object
 */
export const setValidationRules = ({
  required,
  maxLength,
  minLength,
  max,
  min,
  isEmail,
  pattern,
}) => {
  const rules = {};

  if (required) {
    rules.required = {
      value: true,
      message: 'This field is required.',
    };
  }

  if (minLength) {
    const plural = minLength > 1 ? 's' : '';
    rules.minLength = {
      value: minLength,
      message: `Field value cannot have less than ${minLength} character${plural}.`,
    };
  }

  if (maxLength) {
    const plural = minLength > 1 ? 's' : '';
    rules.maxLength = {
      value: maxLength,
      message: `Field value cannot exceed ${maxLength} character${plural}.`,
    };
  }

  if (min) {
    rules.min = {
      value: min,
      message: `Field value cannot be lower than ${min}.`,
    };
  }

  if (max) {
    rules.max = {
      value: max,
      message: `Field value cannot be higher than ${max}.`,
    };
  }

  if (isEmail) {
    rules.pattern = {
      value: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}/,
      message: `You've entered an incorrect email address.`,
    };
  }

  if (pattern) {
    rules.pattern = {
      value: pattern,
      message: `You've entered an incorrect value.`,
    };
  }

  return rules;
};

/**
 * setFieldErrors
 * format errors.
 * @param array errors
 * @returns Object
 */
export const makeFieldErrors = (errors) => {
  if (!errors || !errors.length) return false;

  const fieldErrors = {};
  let formError = '';

  for (const { field, message } of errors) {
    if (field) {
      fieldErrors[field] = message;
    }
  }

  if (errors.length && !errors[0].field) {
    formError = errors[0].message;
  }

  return {
    formError,
    fieldErrors,
  };
};

/**
 * sleep
 * Pause script execution for x ms.
 * @param ms Number
 * @returns Promise
 */
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * formatDate
 * format Date in a specific format
 * @param date Date Object
 * @param format String
 * @returns string
 */
export const formatDate = (date, format = 'YYYY-MM-DD', fixTz = true) => {
  const baseDate = fixTz ? fixTimezone(new Date(date)) : new Date(date);
  // const baseDate = (new Date(date))
  const months = baseDate.getMonth();
  const month = months + 1 > 9 ? months + 1 : `0${months + 1}`;
  const days = baseDate.getDate();
  const day = days > 9 ? days : `0${days}`;
  let formatted = format.includes('YYYY')
    ? format.replace('YYYY', baseDate.getFullYear())
    : format.replace('YY', baseDate.getFullYear().toString().substr(-2));

  formatted = formatted.replace('MM', month);
  formatted = formatted.replace('DD', day);

  if (format.includes('hh')) {
    let hours = baseDate.getHours();
    if (format.includes('aa')) {
      hours = (hours + 24) % 12 || 12;
    }
    formatted = formatted.replace('hh', hours > 9 ? hours : `0${hours}`);
  }

  if (format.includes('mm')) {
    const min = baseDate.getMinutes();
    formatted = formatted.replace('mm', min > 9 ? min : `0${min}`);
  }

  if (format.includes('ss')) {
    const sec = baseDate.getSeconds();
    formatted = formatted.replace('ss', sec > 9 ? sec : `0${sec}`);
  }

  if (format.includes('aa')) {
    const hours = baseDate.getHours();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    formatted = formatted.replace('aa', ampm);
  }

  return formatted;
};

export const fixTimezone = (date) => {
  const dateObj = new Date(date);
  return new Date(dateObj - dateObj.getTimezoneOffset() * -60000);
};

/**
 * currencyFormat
 * format currency in a localized format
 * @param value Number
 * @param currency String
 * @param locale String
 * @returns String
 */
export const currencyFormat = (value, currency = 'USD', locale = 'en-US') => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(Number(value));
};

/**
 * numberFormat
 * format number in a localized format
 * @param value Number
 * @param locale String
 * @returns String
 */
export const numberFormat = (value, locale = 'en-US') => {
  return new Intl.NumberFormat(locale).format(Number(value));
};

/**
 * formatTextDate
 * format Date in a readable format
 * @param number String
 * @param style Object
 * @returns string
 */
export const formatTextDate = (date) => {
  const dateEl = new Date(date);
  // const dateYear = new Date(date).getFullYear();
  // const currentYear = new Date().getFullYear();
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  // if (dateYear !== currentYear) {
  //   options.year = 'numeric';
  // }
  return dateEl.toLocaleDateString('en-US', options);
};

/**
 * taskType
 * get corresponding task types amongst third_party_survey_or_offer, profiler, email_verification, phone_verification
 * @param taskId String
 * @returns String
 */
export const taskType = (id, type) => {
  if (id === 'verify_email') {
    return 'email_verification';
  }

  if (id === 'verify_phone') {
    return 'phone_verification';
  }

  if (
    [
      'survey',
      'limited_time_offers',
      'enter_to_win',
      'up_and_coming_surveys',
      'hot_surveys',
      'popular_surveys',
      'trending_surveys',
    ].includes(type)
  ) {
    return 'third_party_survey_or_offer';
  }

  if (type === 'general_profiler') {
    return 'profiler';
  }

  return type;
};
