import { useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Dropdown from '@/components/Dropdown';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import { getToken } from '@/utils/token';

const UserNav = () => {
  const [active, setActive] = useState(false);
  const ref = useRef();
  const navigateTo = useNavigate();

  useOnClickOutside(ref, () => setActive(false));

  const linkStyle =
    'block px-4 py-2.5 w-full text-left font-bold text-sm hover:bg-grey-250 transition-colors duration-200';

  const isLoggedIn = getToken();

  const handleOnClick = () => {
    isLoggedIn ? setActive(!active) : navigateTo('/login');
  };

  return (
    <div ref={ref} className="flex flex-col items-center pl-2 relative">
      <button
        onClick={handleOnClick}
        className={`flex items-center hover:text-blue ${
          active ? 'text-blue' : 'text-grey-800'
        } transition-colors duration-200`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
          className="w-7"
        >
          <path
            fill="currentColor"
            d="M16,0 C24.8,0 32,7.2 32,16 C32,24.8 24.8,32 16,32 C7.2,32 0,24.8 0,16 C0,7.2 7.2,0 16,0 Z M16,2.5 C8.5,2.5 2.5,8.5 2.5,16 C2.5,23.5 8.6,29.5 16,29.5 C23.5,29.5 29.5,23.4 29.5,16 C29.5,8.5 23.5,2.5 16,2.5 Z M24.6294801,18.2776547 L24.9,18.5 L25.1,18.7 C25.1,18.775 25.15625,18.79375 25.184375,18.840625 L25.2,18.9 L25.3,19.1 L25.3,19.3 L25.4,19.5 L25.4,23.1 C25.4,24.15 24.5799587,25.0177686 23.5486946,25.0944872 L23.4,25.1 L8.8,25.1 C7.75,25.1 6.8822314,24.2799587 6.80551277,23.2486946 L6.8,23.1 L6.8,19.1 C6.8,19.02 7.12,18.684 7.248,18.5528 L7.3,18.5 L7.3,18.5 C12.3105263,14.2754386 19.5410588,14.2013235 24.6294801,18.2776547 Z M9.46344259,19.8063377 L9.2,20 L9.2,22.5 L22.8,22.5 L22.8,20 C18.787234,16.9659574 13.5291987,16.9014033 9.46344259,19.8063377 Z M16,5 C18.7,5 20.9,7.2 20.9,9.9 C20.9,12.6 18.7,14.8 16,14.8 C13.3,14.8 11.1,12.6 11.1,9.9 C11.1,7.2 13.3,5 16,5 Z M16,7.5 C14.7,7.5 13.6,8.6 13.6,9.9 C13.6,11.2 14.7,12.3 16,12.3 C17.3,12.3 18.4,11.2 18.4,9.9 C18.4,8.6 17.3,7.5 16,7.5 Z"
          />
        </svg>
        {!isLoggedIn && <span className="font-bold ml-2">Log In</span>}
      </button>
      {isLoggedIn && (
        <div className="absolute top-10 right-0">
          <Dropdown width="w-44" active={active} clipRight>
            <Link
              className={linkStyle}
              onClick={() => setActive(false)}
              to="/profile"
            >
              Profile
            </Link>
            <div className="h-px w-full bg-grey-50" />
            <Link
              className={linkStyle}
              onClick={() => setActive(false)}
              to="/logout"
            >
              Logout
            </Link>
          </Dropdown>
        </div>
      )}
    </div>
  );
};

export default UserNav;
