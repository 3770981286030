import PropTypes from 'prop-types';

const TaskFooter = ({ length, bonusPoints = null, featured = false }) => {
  return (
    <footer className="flex items-center justify-between font-bold text-white py-2 pl-2.5">
      <div className="flex items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="-2 -2 24 24"
          width="14"
        >
          <path
            fill="currentColor"
            d="M11 9h4a1 1 0 0 1 0 2h-5a1 1 0 0 1-1-1V4a1 1 0 1 1 2 0v5zm-1 11C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
          />
        </svg>
        <span className="ml-1">{length} min</span>
      </div>
      {bonusPoints && (
        <div
          className={`${
            featured ? 'bg-orange-400' : 'bg-red-600'
          } text-sm  text-white px-2 py-0.5 rounded-l-full`}
        >
          + {bonusPoints} Bonus Pts
        </div>
      )}
    </footer>
  );
};

TaskFooter.propTypes = {
  length: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  bonusPoints: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  featured: PropTypes.bool,
};

export default TaskFooter;
