import { useState } from 'react';
import PropTypes from 'prop-types';
import { randId, setValidationRules } from '@/utils/helpers';

const FormItemV2 = ({
  value,
  label = null,
  type = 'text',
  floatingLabel = false,
  onChange,
  placeholder = '',
  revealable = false,
  tooltip = null,
  required = false,
  disabled = false,
  id = '',
  className = '',
  name,
  error = '',
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const isTextarea = type === 'textarea';
  const Element = isTextarea ? 'textarea' : 'input';
  const elId = id || randId();
  const isPassword = type === 'password';

  const elLabel = floatingLabel ? label || placeholder : label;
  const elPlaceholder = floatingLabel ? '' : placeholder;
  const labelClass = `block transition-all duration-200 ${
    floatingLabel
      ? `absolute top-3.5 left-3 text-sm pointer-events-none text-gray-400 origin-left transform ${
          !!value ? 'scale-75 -translate-y-2.5 ' : ''
        }`
      : 'mb-1 font-medium text-xs'
  }`;

  const elClass = `${
    isTextarea ? 'h-48 py-2' : 'h-12'
  } border rounded border-grey-275 px-3 w-full  ${
    !!value && floatingLabel ? (isTextarea ? 'pt-4' : 'pt-2.5') : ''
  } ${isPassword && revealable ? 'pr-10' : ''}`;

  const elType = isPassword ? (showPassword ? 'text' : 'password') : type;

  const togglePassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  return (
    <div className={`${className} relative`}>
      {elLabel && (
        <label className={labelClass} htmlFor={elId}>
          {elLabel}
        </label>
      )}
      <Element
        id={elId}
        type={elType}
        name={name}
        value={value}
        required={required}
        placeholder={elPlaceholder}
        className={elClass}
        onChange={onChange}
        disabled={disabled}
        {...props}
      />
      {isPassword && revealable && !!value && (
        <div
          role="presentation"
          onClick={togglePassword}
          className="absolute top-3 right-3 text-lg z-1 select-none cursor-pointer"
        >
          <span
            role="img"
            aria-label={`${showPassword ? 'see-no-evil' : ''} monkey`}
          >
            {showPassword ? <>🐵</> : <>🙈</>}
          </span>
        </div>
      )}

      {error && (
        <span
          className="text-xs mt-0.5 text-red-500"
          dangerouslySetInnerHTML={{ __html: error }}
        />
      )}
    </div>
  );
};

FormItemV2.propTypes = {
  value: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  floatingLabel: PropTypes.bool,
  revealable: PropTypes.bool,
  tooltip: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

export default FormItemV2;
