import { useState } from 'react';
import SignupV2SignupModal from '@/components/SignupV2SignupModal';

const GetStartedBtn = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className="mt-8">
        <button
          onClick={() => setShowModal(true)}
          className="group primary transform transition-all duration-200  hover:scale-102  p-3 px-5 rounded-lg text-white flex items-center relative active:scale-95"
        >
          <span className="mr-1 pr-4">Get Started</span>
          <span className="absolute transform group-hover:translate-x-0.5 transition-all duration-200 right-3.5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="-5 -5 24 24"
              width="20"
              fill="currentColor"
            >
              <path d="M10.586 5.657l-3.95-3.95A1 1 0 0 1 8.05.293l5.657 5.657a.997.997 0 0 1 0 1.414L8.05 13.021a1 1 0 1 1-1.414-1.414l3.95-3.95H1a1 1 0 1 1 0-2h9.586z" />
            </svg>
          </span>
        </button>
      </div>
      {showModal && <SignupV2SignupModal onClose={() => setShowModal(false)} />}
    </>
  );
};

export default GetStartedBtn;
