import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { post } from '@/config/axios';

const useGetPageContent = (path) => {
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(true);
  const navigateTo = useNavigate();
  const [contentData, setContentData] = useState();

  const getPageData = async () => {
    setHasError(false);
    setLoading(true);
    try {
      const { data } = await post(
        'v1/page-data',
        {
          path,
        },
        null,
        true
      );
      setContentData(data.data);
      setLoading(false);
    } catch (err) {
      const errorMsg = err?.response?.data?.errors?.[0]?.message;
      if (errorMsg === 'Blog does not exist') {
        navigateTo('/not-found');
      }
      setHasError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (path) {
      getPageData();
    }
  }, [path]);

  return { contentData, loading, hasError };
};

export default useGetPageContent;
