import PropTypes from 'prop-types';
import Button from '@/components/Button';
import InviteLine from './components/InviteLine';

const FriendsInviteSection = ({ friends = [], onShowModal }) => {
  return (
    <section>
      <header className="flex justify-between items-center mb-6">
        <h2 className="mb-0 text-xl">Invite a Friend</h2>
        <Button onClick={onShowModal}>Invite</Button>
      </header>
      <div>
        {!friends.length ? (
          <p>
            There are no friends to show. Invite some of your friends to
            Communa!
          </p>
        ) : (
          friends.map((friend, i) => (
            <InviteLine friend={friend} key={`fd-${i}`} />
          ))
        )}
      </div>
    </section>
  );
};

FriendsInviteSection.propTypes = {
  friends: PropTypes.array,
  onShowModal: PropTypes.func.isRequired,
};

export default FriendsInviteSection;
