import PropTypes from 'prop-types';
import Button from '@/components/Button';
import TaskDetailHeader from './TaskDetailHeader';
import TaskContainer from './TaskContainer';

const TaskVerifyEmail = ({ onClose, onCheck }) => {
  const task = {
    name: 'Verify your Email to redeem rewards!',
    design_class: 'GoalVerifyEmail',
  };

  return (
    <TaskContainer>
      <TaskDetailHeader task={task} onClose={onClose} />
      <section className="mt-4">
        <p className="px-5 md:px-8 md:text-lg text-center">
          Verify your email now to earn great rewards
        </p>
      </section>

      <footer className="p-5 pt-8 md:p-8">
        <Button onClick={onCheck} variant="success" className="w-full">
          Verify Email
        </Button>
      </footer>
    </TaskContainer>
  );
};

TaskVerifyEmail.propTypes = {
  onCheck: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TaskVerifyEmail;
