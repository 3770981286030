import { createSlice } from '@reduxjs/toolkit';

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState: {
    list: [],
  },
  reducers: {
    addTasks: (state, { payload }) => {
      state.list = [...state.list, ...payload];
    },
  },
});

export const { addTasks } = tasksSlice.actions;

export default tasksSlice.reducer;
