import PropTypes from 'prop-types';
import Button from '@/components/Button';
import TaskDetailHeader from './TaskDetailHeader';
import TaskContainer from './TaskContainer';

const TaskCap = ({ task, onClose }) => {
  const capTask = {
    ...task,
    design_class: 'SurveyQuotaMet',
    name: `We are out of ${task.clients} surveys for today!`,
  };

  return (
    <TaskContainer>
      <TaskDetailHeader task={capTask} onClose={onClose} />
      <p className="px-8 mt-6 text-lg text-center">
        Sorry, we&apos;re out of {task.clients} surveys for today. If you check
        back tomorrow we&apos;ll make sure to have more ready for you.
      </p>
      <footer className="p-8">
        <Button onClick={onClose} variant="success" className="w-full">
          Back to Surveys
        </Button>
      </footer>
    </TaskContainer>
  );
};

TaskCap.propTypes = {
  onClose: PropTypes.func.isRequired,
  task: PropTypes.object.isRequired,
};

export default TaskCap;
