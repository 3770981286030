import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get } from '@/config/axios';
import FormItem from '@/components/FormItem';
import Button from '@/components/Button';
import TaskDetailHeader from './TaskDetailHeader';
import Alert from '@/components/Alert';
import TaskInfo from './TaskInfo';
import { logAnalyticEvent } from '@/config/analytics';
import { getErrorMsg } from '@/utils/errorsManager';
import TaskContainer from './TaskContainer';

const TaskReferAFriend = ({
  task,
  onClose,
  onComplete = null,
  setParentLoading,
}) => {
  const { userId } = useSelector((state) => state.user);
  const [taskClone, setTaskClone] = useState(task);
  const [emailValue, setEmailValue] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showStep1, setShowStep1] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [isSubmitting, setIsSumbitting] = useState(false);
  const [points, setPoints] = useState('');

  const errMsgLookup = {
    default: 'Something went wrong, please try again',
    '0204': 'Please enter a valid email address.',
    '0213': 'Please enter a valid email address.',
    429: "You've reached your invite limit. Try again tomorrow!",
  };

  const handleSubmit = () => {
    if (isSent) {
      onClose();
      return;
    }

    if (showStep1) {
      sendEmail();
      return;
    }

    setShowStep1(true);
  };

  const btnLabel = () => {
    if (isSent) {
      return 'OK';
    }
    if (showStep1) {
      return 'Send';
    }
    return 'I Understand, Please Proceed';
  };

  const sendEmail = async () => {
    setError('');
    setLoading(true);
    setIsSumbitting(true);

    try {
      await get('v1/friends/invite', {
        email: emailValue,
      });
      setTaskClone({
        ...taskClone,
        name: 'Email Sent!',
      });
      setLoading(false);
      setIsSumbitting(false);
      setShowStep1(false);
      setIsSent(true);
      /*
      logAnalyticEvent('refer_a_friend', 'close', {
        task_type,
        featured,
        points,
      });
      */
      onComplete && onComplete();
    } catch (err) {
      const errorMsg = getErrorMsg(err);

      setError(errorMsg);
      setLoading(false);
      setIsSumbitting(false);
    }
  };

  const modalText = (ptsToReceive = '') => {
    if (isSent) {
      return `Your invite to ${emailValue} was sent&nbsp;successfully!`;
    }
    if (showStep1) {
      return 'Enter the email of the person you would like to&nbsp;invite.';
    }
    return "<p class='mb-3'>Friends that stick together, save together. Know someone who could use some extra earnings?</p> <p class='mb-3'>Start sending those referrals today and you'll be saving money in no time.</p>";
  };

  useEffect(async () => {
    try {
      const {
        data: { data },
      } = await get('v1/friends/data');

      data?.points_referrer_to_receive &&
        setPoints(data.points_referrer_to_receive);

      logAnalyticEvent('task-card', 'impression', {
        task_id: 'refer_a_friend',
        task_title: 'Refer a Friend',
        task_type: 'referral',
      });

      setParentLoading(false);
    } catch (err) {
      // setHasError(true);
      setParentLoading(false);
    }
    /*
    logAnalyticEvent('refer_a_friend', 'start', {
      task_type,
      featured,
      points,
    });
    */
  }, []);

  return (
    <TaskContainer>
      <TaskDetailHeader task={taskClone} onClose={onClose} />
      <>
        <section className={showStep1 || isSent ? 'mt-4' : ''}>
          {!showStep1 && !isSent && <TaskInfo points={points} />}
          {/* TODO: set this using value from frontend */}

          {isSent && (
            <div className="flex flex-col items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 48 48"
                className="mb-1"
              >
                <path
                  fill="#22b473"
                  d="M35.18,14.06a2,2,0,0,0-1.39.63L20.5,29.47l-6.2-6.19A1.81,1.81,0,0,0,13,22.75a1.86,1.86,0,0,0-1.3.53,1.82,1.82,0,0,0,0,2.59l7.59,7.54a1.86,1.86,0,0,0,1.29.53h.05A1.83,1.83,0,0,0,22,33.31l14.6-16.22a1.82,1.82,0,0,0-.15-2.59A2,2,0,0,0,35.18,14.06Z"
                />
                <path
                  fill="#22b473"
                  d="M24,0A24,24,0,1,0,48,24,24,24,0,0,0,24,0Zm0,44.3A20.3,20.3,0,1,1,44.3,24,20.34,20.34,0,0,1,24,44.3Z"
                />
              </svg>
            </div>
          )}
          <p
            className="px-5 pt-1 md:px-8 text-lg text-center"
            dangerouslySetInnerHTML={{ __html: modalText(points) }}
          />
          <div className="px-5 md:px-8 mx-auto mt-2 flex flex-col items-center">
            {error && (
              <div className="flex-1 w-full">
                <Alert type="error">{error}</Alert>
              </div>
            )}
            {showStep1 && (
              <div className="flex flex-col items-center">
                <FormItem
                  name="friends-email"
                  value={emailValue}
                  placeholder="Email address"
                  onChange={(e) => setEmailValue(e.target.value)}
                  className="w-60"
                />
              </div>
            )}
          </div>
        </section>

        <footer className="p-5 pt-8 md:p-8">
          <Button
            onClick={handleSubmit}
            variant="success"
            className="w-full"
            disabled={isSubmitting || (!emailValue && showStep1)}
            loading={loading}
          >
            {btnLabel()}
          </Button>
        </footer>
      </>
    </TaskContainer>
  );
};

TaskReferAFriend.propTypes = {
  task: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
  setParentLoading: PropTypes.func.isRequired,
};

export default TaskReferAFriend;
