import { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Logo from '@/components/Logo';
import { getToken } from '@/utils/token';
import BallotDraw from './components/BallotDraw';
import PointsCount from './components/PointsCount';
import UserNav from './components/UserNav';
import SkeletonLoading from './components/SkeletonLoading';

const AppHeader = () => {
  const { points, ballots } = useSelector((state) => state.user);
  const isLoggedIn = getToken();
  const refEl = useRef();
  const refHeader = useRef();
  const scrollRef = useRef(0);
  const [slideUpOf, setSlideUpOf] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);

  const handleOnScroll = () => {
    const { height } = refHeader.current.getBoundingClientRect();
    setIsScrolling(window.scrollY > height);
    if (window.scrollY >= scrollRef.current) {
      setSlideUpOf(window.scrollY);
    }
    if (window.scrollY <= scrollRef.current) {
      setSlideUpOf(0);
    }

    scrollRef.current = window.scrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleOnScroll);
    return () => window.removeEventListener('scroll', handleOnScroll);
  }, []);

  return (
    <header
      ref={refHeader}
      className={`fixed right-0 top-0 left-0 z-20 bg-white px-4 flex justify-between shadow h-12 ${
        isScrolling ? 'transition-all duration-300' : ''
      } `}
      style={{ transform: `translateY(-${slideUpOf}px)` }}
    >
      <Link
        to="/"
        className="flex items-center hover:opacity-80 transition-opacity duration-200"
      >
        <Logo />
      </Link>
      <section ref={refEl} className="flex items-center">
        {isLoggedIn && (
          <>
            {points !== null && ballots !== null ? (
              <>
                <PointsCount refEl={refEl} />
                <BallotDraw />
              </>
            ) : (
              <SkeletonLoading />
            )}
          </>
        )}

        <UserNav />
      </section>
    </header>
  );
};

export default AppHeader;
