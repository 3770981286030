import ErrorBoundary from '@/components/ErrorBoundary';

import { Outlet } from 'react-router-dom';

const DefaultLayout = () => {
  return (
    <ErrorBoundary>
      <Outlet />
    </ErrorBoundary>
  );
};

export default DefaultLayout;
