import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RenderRoutes from '@/config/routes';
import Error500 from '@/pages/error';
import ScrollToTop from '@/components/ScrollToTop';
import GlobalCurtain from '@/components/GlobalCurtain';
import MaxMind from '@/components/MaxMind';
import AnalyticsPageViews from '@/components/AnalyticsPageViews';

const App = () => {
  const { isFailed } = useSelector((state) => state.config);

  if (isFailed) {
    return <Error500 />;
  }

  return (
    <Router>
      <ScrollToTop />
      <RenderRoutes />
      <GlobalCurtain />
      <MaxMind />
      <AnalyticsPageViews />
    </Router>
  );
};

export default App;
