import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeEvent } from '@/store/slices/eventsSlice';
import Modal from '@/components/Modal';
import EventDispatcher from './components/EventDispatcher';

const EventsManager = () => {
  const [currentEvent, setCurrentEvent] = useState();
  const { list } = useSelector((state) => state.events);
  const { list: actionsList } = useSelector((state) => state.actions);
  const { isTaskOpen } = useSelector((state) => state.config);
  const [isInitial, setIsInitial] = useState(true);
  const dispatch = useDispatch();

  const onClose = async () => {
    // close current event
    setIsInitial(false);
    setCurrentEvent();
    dispatch(removeEvent(currentEvent?._id));
  };

  useEffect(() => {
    const currentEvent = list && list.length ? list[0] : null;
    if (
      currentEvent &&
      (['DAY_FIRST_LANDING_POPUP'].includes(currentEvent.type) ||
        currentEvent.points !== 0)
    ) {
      setCurrentEvent(list[0]);
    }
    if (!list.length) {
      setIsInitial(true);
    }
  }, [list]);

  const showOverflow =
    currentEvent?.type && ['CBD_OFFER'].includes(currentEvent.type);

  const persistOverlay =
    currentEvent?.type && ['CBD_OFFER'].includes(currentEvent.type);

  // if there's still actions in the list (as they take precedence over events)
  if (
    !currentEvent ||
    ([
      'DAY_FIRST_LANDING_POPUP',
      'WON_BALLOT_DRAW',
      'BALLOT_EARNED_LOGIN_REWARD',
    ].includes(currentEvent.type) &&
      (actionsList.length || isTaskOpen))
  ) {
    return <></>;
  }
  return (
    <Modal
      onClose={onClose}
      overflowVisible={showOverflow}
      persistOverlay={persistOverlay}
    >
      {(onClose) => <EventDispatcher event={currentEvent} onClose={onClose} />}
    </Modal>
  );
};

export default EventsManager;
