import { useState, useRef, useEffect } from 'react';

const useBreakpointDetect = () => {
  const [breakpoint, setBreakpoint] = useState('');
  const breakPointElRef = useRef();
  const timeout = useRef();

  // Note: This depends on css styles in main.css
  const breakpointLookup = {
    0: 'xs',
    0.2: 'sm',
    0.4: 'md',
    0.6: 'lg',
    0.8: 'xl',
    1: 'xxl',
  };

  function getBreakpoint() {
    const opacity = window.getComputedStyle(breakPointElRef.current).opacity;
    return breakpointLookup?.[opacity] || '';
  }

  const resizeHandler = (e) => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setBreakpoint(getBreakpoint());
    }, 100);
  };

  useEffect(() => {
    let breakPointEl = document.getElementById('breakpoint-check');

    if (!breakPointEl) {
      breakPointElRef.current = document.createElement('div');
      // Make a breakpoint check el
      breakPointElRef.current.id = 'breakpoint-check';
      document.body.appendChild(breakPointElRef.current);
    } else {
      breakPointElRef.current = breakPointEl;
    }

    window.addEventListener('resize', resizeHandler);
    setBreakpoint(getBreakpoint());

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  return breakpoint;
};

export default useBreakpointDetect;
