import PropTypes from 'prop-types';
import LoadingEllipsis from '@/components/LoadingEllipsis';

const ButtonV2 = ({
  children,
  className = '',
  variant = 'primary',
  loading = false,
  disabled = false,
  onClick = null,
  ...props
}) => {
  const isNonFunctional = disabled || loading;
  return (
    <button
      className={`${className} group ${variant} ${
        isNonFunctional ? 'opacity-75 pointer-events-none' : ''
      } transform transition-all duration-200  hover:scale-102  p-3 px-5 rounded-lg text-white flex justify-center items-center relative active:scale-95`}
      onClick={onClick}
      disabled={isNonFunctional}
      {...props}
    >
      {loading && (
        <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <LoadingEllipsis width={40} />
        </span>
      )}
      <span className={loading ? 'opacity-0' : ''}>{children}</span>
    </button>
  );
};

ButtonV2.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  onClick: PropTypes.func,
};

export default ButtonV2;
