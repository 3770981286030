import PropTypes from 'prop-types';
import Logo from '@/components/Logo';
import AppFooterSimple from '@/components/AppFooterSimple';
import { getToken } from '@/utils/token';
import { Outlet, Navigate } from 'react-router-dom';
import withSession from '@/hoc/withSession';

const AuthLayout = () => {
  if (!!getToken()) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div className="min-h-screen  flex justify-center bg-grey-200">
      <section className="p-6 sm:p-8 w-full">
        <div className="flex justify-center">
          <Logo width="w-52" doNotBreak />
        </div>
        <div className="max-w-sm p-1  mx-auto">
          <div className="mt-4 bg-white p-6 sm:p-8 rounded-lg shadow-sm">
            <Outlet />
          </div>
          <AppFooterSimple />
        </div>
      </section>
    </div>
  );
};

export default withSession(AuthLayout);
