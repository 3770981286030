import FormItemV2 from '@/components/FormItemV2';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const CopyBtn = ({ code, className = '' }) => {
  const [showConfirm, setShowConfirm] = useState(false);

  const clipboardText = `https://communa.com/signup?referrer_id=${code}`;

  const onCopyLink = () => {
    navigator.clipboard.writeText(clipboardText);
    setShowConfirm(true);
  };

  useEffect(() => {
    let t = null;
    if (showConfirm) {
      t = setTimeout(() => {
        setShowConfirm(false);
      }, 1000);
    }
    return () => {
      clearTimeout(t);
    };
  }, [showConfirm]);

  return (
    <div className="relative flex items-center mt-1.5">
      <div
        className={`absolute -top-4  z-50 bg-blue text-white text-xs rounded-lg p-2  ${
          showConfirm ? 'animate-copiedToTop visible' : 'invisible'
        }`}
      >
        Copied!
      </div>
      <FormItemV2
        disabled
        value={clipboardText}
        className="w-36 sm:w-full max-w-lg"
      />
      <button
        onClick={onCopyLink}
        className="text-grey-600 ml-3 flex items-center transition-colors duration-200  group-hover:text-blue"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
          />
        </svg>
        <div className="ml-1">Copy</div>
      </button>
    </div>
  );
};

CopyBtn.propTypes = {
  code: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default CopyBtn;
