import { Outlet } from 'react-router-dom';
import withSession from '@/hoc/withSession';
import AppBlogHeader from '@/components/AppBlogHeader';
import AppFooter from '@/components/AppFooter';

const BlogLayout = () => {
  return (
    <div className="bg-grey-200">
      <AppBlogHeader />
      <main>
        <Outlet />
      </main>
      <AppFooter />
    </div>
  );
};

export default withSession(BlogLayout);
