import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { getToken } from '@/utils/token';
import { post } from '@/config/axios';
import LoadingEllipsis from '@/components/LoadingEllipsis';
import PageHeader from '@/components/PageHeader';
import Button from '@/components/Button';
import Alert from '@/components/Alert';
import useGetPageContent from '@/hooks/useGetPageContent';

const PrivacyCaliforniaPage = () => {
  const { userId } = useSelector((state) => state.user);
  const { contentData, loading, error } = useGetPageContent(
    '/privacy-policy-ccpa-opt-in'
  );

  const [showError, setShowError] = useState(false);
  const navigateTo = useNavigate();

  if (!getToken()) {
    return <Navigate to="/login" />;
  }

  const pageTitle = 'Opt-In Privacy Policy - California Residents';

  const onAccept = async () => {
    setShowError(false);
    setLoading(true);
    try {
      await post(
        'v1/user-action',
        {
          path: 'privacy-policy-ccpa-opt-in',
          action: 'ccpa_opt_in',
        },
        {
          user_id: userId,
        },
        true
      );
      setLoading(false);
      navigateTo('/dashboard');
    } catch (err) {
      setShowError(true);
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle} | Give Your Opinion. Earn Rewards</title>
      </Helmet>
      <section>
        <PageHeader title={pageTitle} className="py-16 md:py-24" />
        <main className="max-w-3xl px-6 mx-auto md:pt-8 pb-16">
          {error && (
            <div className="mt-3">
              <Alert type="error">
                Something went wrong. Please refresh the page. If the issue
                persists, please <Link to="/contact">contact us</Link>.
              </Alert>
            </div>
          )}
          {loading ? (
            <div className="flex justify-center pt-12 pb-6 md:pt-24 md:pb-12 text-blue">
              <LoadingEllipsis width={48} />
            </div>
          ) : (
            <>
              {showError && (
                <Alert type="error">
                  Something went wrong. Please try again. If the issue persists,
                  please <Link to="/contact">contact us</Link>.
                </Alert>
              )}

              <div className="border border-grey-300 my-4 rounded h-72 overflow-y-auto px-3 pb-3">
                <section
                  className="page"
                  dangerouslySetInnerHTML={{ __html: contentData.content }}
                />
              </div>
              <Button
                loading={loading}
                onClick={onAccept}
                variant="success"
                className="w-full"
              >
                Accept
              </Button>
            </>
          )}
        </main>
      </section>
    </>
  );
};

export default PrivacyCaliforniaPage;
