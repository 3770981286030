/* eslint-disable react/display-name */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { post, get } from '@/config/axios';
import useRecursiveTimeout from '@/hooks/useRecursiveTimeout';
import { setUser } from '@/store/slices/userSlice';
import { setFlags } from '@/store/slices/featureFlagsSlice';
import { setIsOnline, setCBDOffer } from '@/store/slices/configSlice';

const withSession = (Component) => ({ ...props }) => {
  const { userId } = useSelector((state) => state.user);
  const [time, setTime] = useState(12000);
  const { list: eventsList } = useSelector((state) => state.events);
  const { list: actionsList } = useSelector((state) => state.actions);
  const { isOnline } = useSelector((state) => state.config);
  const location = useLocation();
  const navigateTo = useNavigate();
  const dispatch = useDispatch();

  const [payloadLoaded, setPayloadLoaded] = useState(false);

  const getPayload = async () => {
    const getPath = () => {
      if (location.pathname === '/signup-v2') {
        return '/signup';
      }
      if (location.pathname === '/redeem') {
        return '/rewards';
      }
      if (
        [
          '/refer-and-earn',
          '/sponsored-surveys/pollfish',
          '/sponsored-surveys/ssi',
          '/sponsored-surveys/peanutlabs',
          '/sponsored-surveys/sayso',
          '/sponsored-surveys/marketcube',
          '/sponsored-surveys/dynata',
          '/sponsored-surveys/cactus',
          '/sponsored-surveys/cint-api',
          '/sponsored-surveys/sayso-livealert',
        ].includes(location.pathname)
      ) {
        return '/dashboard';
      }
      return location.pathname;
    };

    const queryParams = {
      path: getPath(),
    };
    if (location.search && !location.search.includes('referrer_id')) {
      let params = location.search.replace('?', '');
      // params = encodeURIComponent(params);
      queryParams.params = params;
    }

    try {
      const { data } = await post('v2/payload', queryParams, null, true);
      const { payload, feature_flags, ccpa_opt_in_required } = data?.data;

      payload?.feature_sideload_cbd_offer_on_dashboard_landing &&
        dispatch(
          setCBDOffer(payload.feature_sideload_cbd_offer_on_dashboard_landing)
        );

      payload?.user && dispatch(setUser(payload?.user));
      feature_flags && dispatch(setFlags(feature_flags));

      setPayloadLoaded(true);

      if (
        ccpa_opt_in_required &&
        !location.pathname.includes('privacy-policy-ccpa-opt-in')
      ) {
        navigateTo('/privacy-policy-ccpa-opt-in');
      }
    } catch (err) {
      // 🤫
      // Nothing happens on the user side,
      // error is silenced but will be logged in Amplitude
    }
  };

  const onTabBlur = () => {
    setTime(60000);
  };

  const onTabFocus = () => {
    setTime(12000);
    getUserEvents();
  };

  const getUserEvents = async () => {
    if (!userId || actionsList.length || eventsList.length || !isOnline) return;

    try {
      await get('v1/user/load', {
        user_id: userId,
      });
    } catch (err) {
      // console.error(err);
    }
  };

  useRecursiveTimeout(() => getUserEvents(), time);

  useEffect(() => {
    getPayload();
  }, [location]);

  useEffect(() => {
    window.addEventListener('blur', onTabBlur);
    window.addEventListener('focus', onTabFocus);
    window.addEventListener('offline', () => dispatch(setIsOnline(false)));
    window.addEventListener('online', () => dispatch(setIsOnline(true)));
    return () => {
      window.removeEventListener('blur', onTabBlur);
      window.removeEventListener('focus', onTabFocus);
      window.removeEventListener('offline', () => dispatch(setIsOnline(false)));
      window.removeEventListener('online', () => dispatch(setIsOnline(true)));
    };
  }, []);

  return <Component payloadLoaded={payloadLoaded} {...props} />;
};

export default withSession;
