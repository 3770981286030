import { logAnalyticEvent } from '@/config/analytics';

const catchError = (err) => {
  const { error, lineno, colno, url, message } = err;

  logAnalyticEvent('frontend_error', null, {
    trace: error.stack,
    error: error.message,
    line: lineno,
    col: colno,
    url,
    message,
  });
};

const initErrorsTracking = () => {
  if (!window) return;

  window.addEventListener('error', catchError);
};

export default initErrorsTracking;
