import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import FacebookLoginButton from '@/components/FacebookLoginButton';
import GoogleLoginButton from '@/components/GoogleLoginButton';

const SocialSignup = ({ setFormError, logFormEvent, onSubmit }) => {
  const { list: flags } = useSelector((state) => state.flags);

  const onSocialSignupClick = (authenticator) => {
    setFormError();
    logFormEvent(authenticator, 'form-focus');
  };

  if (!flags?.google_authentication && !flags?.facebook_authentication) {
    return <></>;
  }

  return (
    <section>
      {flags && flags.facebook_authentication && (
        <div className="mb-4">
          <FacebookLoginButton
            label="Sign Up with Facebook"
            onClick={() => onSocialSignupClick('facebook')}
            onError={(error) => setFormError(error)}
            onSuccess={(formData) => onSubmit(formData)}
            onSubmit={() => logFormEvent('facebook', 'form-submit')}
          />
        </div>
      )}
      {flags && flags.google_authentication && (
        <div className="mb-4">
          <GoogleLoginButton
            className="mt-3"
            label="signup_with"
            onClick={() => onSocialSignupClick('google')}
            onError={(error) => setFormError(error)}
            onSuccess={(formData) => onSubmit(formData)}
            onSubmit={() => logFormEvent('google', 'form-submit')}
          />
        </div>
      )}

      <div className="my-10">
        <hr className="or-divider text-gray-500 border-t border-gray-300 overflow-visible text-center" />
      </div>
    </section>
  );
};

SocialSignup.propTypes = {
  setFormError: PropTypes.func.isRequired,
  logFormEvent: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SocialSignup;
