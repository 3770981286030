import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import FormItemV2 from '@/components/FormItemV2';
import Alert from '@/components/Alert';
import ButtonV2 from '@/components/ButtonV2';
import FormWrapper from '@/components/FormWrapper';
import { post } from '@/config/axios';
import { makeFieldErrors } from '@/utils/helpers';

const PasswordForgotPage = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    password: null,
    password_1: null,
  });
  const [error, setError] = useState(null);
  const [isReset, setIsReset] = useState(false);
  const [fieldErrors, setFieldErrors] = useState();
  const [formError, setFormError] = useState();

  const getTokenId = () => {
    if (!location.search) return null;

    const token = new URLSearchParams(location.search).get('token_id');

    return token;
  };

  const handleSubmit = async () => {
    const token_id = getTokenId();

    setError(null);
    setLoading(true);

    const postData = {
      ...formData,
      token_id,
    };
    try {
      await post('v1/reset_password/update', postData, {
        speculative: false,
      });
      setIsReset(true);
      setLoading(false);
    } catch (err) {
      const { fieldErrors, formError } = makeFieldErrors(
        err?.response?.data?.errors
      );
      if (fieldErrors) {
        setFieldErrors(fieldErrors);
      }
      if (formError) {
        setFormError(formError);
      }
      if (fieldErrors?.token_id) {
        setFormError(
          'The given URL seems invalid. Please try again or contact us.'
        );
      }
      setLoading(false);
    }
  };

  const handleOnChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  if (isReset) {
    return (
      <div>
        <Alert>Your password has been successfully reset!</Alert>
        <div className="text-center text-sm">
          <Link
            to="/login"
            className="hover:text-blue transition-colors duration-200"
          >
            Return to Login Page
          </Link>
        </div>
      </div>
    );
  }

  const isBtnDisabled = !formData.password || !formData.password_1;

  return (
    <div>
      <p className="mb-5 text-center ">Please enter your new password.</p>
      <FormWrapper onSubmit={handleSubmit} error={formError}>
        <FormItemV2
          placeholder="New Password"
          label="New Password"
          name="password"
          type="password"
          autoComplete="new-password"
          className="mt-4"
          error={fieldErrors?.password}
          onChange={handleOnChange}
          floatingLabel
          value={formData.password}
          required
          disabled={loading}
        />
        <FormItemV2
          value={formData.password_1}
          label="Confirm Password"
          name="password_1"
          type="password"
          autoComplete="new-password"
          className="my-5"
          error={fieldErrors?.password_1}
          onChange={handleOnChange}
          floatingLabel
        />
        <div className="flex flex-col sm:flex-row items-center justify-between">
          <ButtonV2
            loading={loading}
            disabled={isBtnDisabled}
            className="w-32"
            variant="success"
            type="submit"
          >
            Submit
          </ButtonV2>
          <Link
            to="/login"
            className="hover:text-blue mt-6 sm:mt-0 text-grey text-sm transition-colors duration-200 font-bold"
          >
            Cancel
          </Link>
        </div>
      </FormWrapper>
    </div>
  );
};

export default PasswordForgotPage;
