import { useState } from 'react';
import PropTypes from 'prop-types';
import { randId, setValidationRules } from '@/utils/helpers';

const FormItemEdit = ({
  value,
  label = null,
  floatingLabel = false,
  onChange,
  onEditBtnClick,
  placeholder = '',
  tooltip = null,
  required = false,
  disabled = false,
  id = '',
  className = '',
  name,
  error = '',
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const elId = id || randId();
  const elLabel = floatingLabel ? label || placeholder : label;
  const elPlaceholder = floatingLabel ? '' : placeholder;
  const labelClass = `block transition-all duration-200 ${
    floatingLabel
      ? `absolute top-3.5 left-3 text-sm pointer-events-none text-gray-400 origin-left transform ${
          !!value ? 'scale-75 -translate-y-2.5 ' : ''
        }`
      : 'mb-1 font-medium text-xs'
  }`;

  const inputClass = `h-10 rounded px-3 w-full text-sm`;

  const btnClass =
    'h-10 bg-blue text-white hover:bg-blue-400 active:bg-blue-600 transition-all font-bold duration-200 rounded-tr rounded-br px-6 leading-none inline-flex items-center justify-center';

  return (
    <div className={`${className} relative`}>
      {elLabel && (
        <label className={labelClass} htmlFor={elId}>
          {elLabel}
        </label>
      )}
      <div className="flex border rounded border-grey-275">
        <input
          id={elId}
          type="text"
          name={name}
          value={value}
          required={required}
          placeholder={elPlaceholder}
          className={inputClass}
          onChange={onChange}
          disabled={disabled}
          {...props}
        />
        <button className={btnClass} onClick={(e) => onEditBtnClick()}>
          Edit
        </button>
      </div>

      {error && (
        <span
          className="text-xs mt-0.5 text-red-500"
          dangerouslySetInnerHTML={{ __html: error }}
        />
      )}
    </div>
  );
};

FormItemEdit.propTypes = {
  value: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onEditBtnClick: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  floatingLabel: PropTypes.bool,
  tooltip: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

export default FormItemEdit;
