import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setHasCollectedBallot } from '@/store/slices/userSlice';
import Button from '@/components/Button';
import TaskDetailHeader from './TaskDetailHeader';
import TaskDailyLoginUnlocked from './TaskDailyLoginUnlocked';
import { logAnalyticEvent } from '@/config/analytics';
import TaskContainer from './TaskContainer';

const TaskDailyLoginRewards = ({ task, onClose }) => {
  const [isClicked, setIsClicked] = useState(false);
  const dispatch = useDispatch();
  const { nextDraw } = useSelector((state) => state.user);
  const [isCollecting, setIsCollecting] = useState(false);
  const [points, setPoints] = useState(task.points);

  const handleOnClick = () => {
    setIsClicked(true);
    logAnalyticEvent('daily-login-rewards-popup', 'ok-press');
    if (isCollectable) {
      setIsCollecting(true);
      dispatch(setHasCollectedBallot(true));
      return;
    }
    onClose();
  };

  const taskClone = {
    ...task,
    name: 'Daily Login Rewards',
    design_class: 'DailyLoginRewards',
  };
  const isCollectable = points === 5;

  const btnLabel = isCollectable ? 'Collect' : 'Ok';

  useEffect(() => {
    if (!isCollecting) return;

    setTimeout(() => {
      setIsCollecting(false);
      setPoints(0);
      setIsClicked(false);
    }, 1000);
  }, [isCollecting]);

  useEffect(() => {
    logAnalyticEvent('daily-login-rewards-popup', 'impression');
  }, []);

  return (
    <TaskContainer>
      <TaskDetailHeader task={taskClone} onClose={onClose} />
      <div className="flex justify-center mt-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          viewBox="0 0 32 22"
          className={`${isCollecting ? 'animate-spin ' : ''}`}
        >
          <path
            fill="#1494D6"
            d="M20.6,0.2 L20.9,21.1 L2.9,21.3 C2.2,21.3 1.6,21.1 1.1,20.6 C0.65,20.15 0.362,19.619 0.3089,19.007 L0.3,18.8 L0.2,13.9 L1.2,13.5 C2.24210526,13.1210526 3.01495845,12.1138504 3.0934247,10.9885552 L3.1,10.8 L3.07779492,10.6010288 C2.92992112,9.54588477 2.31236283,8.59609053 1.29535322,8.17304527 L1.1,8.1 L0.1,7.8 L-1.42108547e-14,2.9 L0.0088,2.6931 C0.061,2.082 0.34,1.56 0.7,1.2 C1.15,0.75 1.681,0.462 2.293,0.4089 L2.5,0.4 L20.6,0.2 Z M29.1,0 C29.8,0 30.4,0.2 30.9,0.7 C31.35,1.15 31.638,1.681 31.6911,2.293 L31.7,2.5 L31.8,7.4 L30.6,7.7 C29.3590909,7.98636364 28.4826446,9.0927686 28.4055316,10.3234222 L28.4,10.5 L28.4185575,10.6890026 C28.5744403,11.9328799 29.3732272,12.9113723 30.5166406,13.2516329 L30.7,13.3 L31.9,13.6 L31.9,18.3 L31.8939,18.507 C31.857,19.119 31.65,19.65 31.2,20.1 C30.75,20.55 30.219,20.838 29.607,20.8911 L29.4,20.9 L23.6,21 L23.5,0.1 L29.1,0 Z"
          />
        </svg>
      </div>
      <section className="mt-4">
        <p className="px-5 md:px-8 md:text-lg text-center">
          Every time you log in you&apos;ll be rewarded with a stamp. When you
          collect 5, you&apos;ll earn a ballot for a chance to win{' '}
          <strong>5,000 Pts</strong> in our monthly draw.
        </p>
      </section>

      <TaskDailyLoginUnlocked points={points} />

      <section className="flex items-center justify-center mt-8">
        <div className="border rounded-full text-lg font-bold px-3 py-1 bg-white">
          Next Draw: {nextDraw}
        </div>
      </section>

      <footer className="p-5 pt-8 md:p-8">
        <Button
          onClick={handleOnClick}
          variant="success"
          disabled={isClicked}
          className="w-full"
        >
          {btnLabel}
        </Button>
      </footer>
    </TaskContainer>
  );
};

TaskDailyLoginRewards.propTypes = {
  task: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TaskDailyLoginRewards;
