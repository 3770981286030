import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get, post } from '@/config/axios';
import Alert from '@/components/Alert';

import SponsoredHeader from '@/components/SponsoredHeader';
import LoadingEllipsis from '@/components/LoadingEllipsis';
import TaskCard from '@/components/TaskCard';

const SponsoredSurveyPage = () => {
  const { slug } = useParams();
  // const { list } = useSelector((state) => state.sponsors);
  const { userId } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const [sponsor, setSponsor] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [hasError, setHasError] = useState(false);

  const getSponsors = async () => {
    setHasError(false);
    setLoading(true);
    setSponsor(null);
    setTasks([]);

    try {
      const { data } = await post(
        'v2/payload',
        {
          path: '/dashboard',
        },
        null,
        true
      );

      if (!data.data?.survey_sponsors) return;

      const sponsorMatch = data.data.survey_sponsors.find(
        (item) => slug === item.slug
      );
      setSponsor(sponsorMatch);

      if (!sponsorMatch) return;

      await getSurvey(sponsorMatch);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getSurvey = async (sponsorMatch) => {
    const { id } = sponsorMatch;
    try {
      const { data } = await get('v1/task/load', {
        sponsor: id,
        user_id: userId,
      });
      if (data?.data?.tasks) {
        setTasks(data.data.tasks);
      }
    } catch (err) {
      setHasError(true);
    }
  };

  useEffect(() => {
    getSponsors();
  }, [slug]);

  if (loading) {
    return (
      <div className="text-blue flex p-10 md:p-16 justify-center">
        <LoadingEllipsis width={60} />
      </div>
    );
  }

  if (!loading && !tasks.length) {
    return (
      <div className="flex flex-col justify-center items-center">
        <img
          className="w-56 md:w-72"
          src="/img/task/i_c_dkmb_Broken.svg"
          alt="Not found icon"
        />
        <h1 className="text-center text-xl md:text-2xl max-w-lg md:mt-4">
          Sorry, we have no surveys available at this time. Please try again
          later!
        </h1>
      </div>
    );
  }

  return (
    <section className="bg-grey-200">
      {hasError ? (
        <Alert type="error">
          Something went wrong while trying to load the surveys. Please refresh
          the page and try again. <br />
          If the issue persists, please{' '}
          <Link to="/contact">
            <u>contact us</u>
          </Link>
          .
        </Alert>
      ) : (
        <>
          <SponsoredHeader sponsor={sponsor} />

          <section className="my-8 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {tasks.map((task, i) => (
              <TaskCard task={task} key={`tsk-${i}`} />
            ))}
          </section>
        </>
      )}
    </section>
  );
};

export default SponsoredSurveyPage;
