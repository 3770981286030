import PropTypes from 'prop-types';

const FormCheckbox = ({
  checked = false,
  onChange,
  name,
  label,
  className = '',
}) => {
  return (
    <label
      className={`w-full flex form-checkbox items-center relative pl-7 cursor-pointer ${className}`}
    >
      <span className="text-sm font-bold pl-1 mt-px">{label}</span>
      <input
        checked={checked}
        type="checkbox"
        className="absolute opacity-0 cursor-pointer"
        name={name}
        onChange={onChange}
      />
      <span className="check-btn absolute w-5 h-5 bg-grey-300 left-1 transition-all duration-200 rounded" />
    </label>
  );
};

FormCheckbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default FormCheckbox;
