import footerData from './data.json';
import { Link } from 'react-router-dom';
import SocialLink from '@/components/SocialLink';

const SignupV2Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="bg-gray-600">
      <div className="max-w-6xl mx-auto py-16 mt:py-20 px-5">
        <section>
          <nav className="mx-auto py-3 max-w-2xl flex flex-wrap justify-center">
            {footerData.map(({ to, label }, i) => (
              <div key={`ft-${i}`} className="px-3 py-0.5">
                <Link
                  to={to}
                  className="text-sm font-montserrat font-bold text-white hover:text-blue transition-colors duration-200"
                >
                  {label}
                </Link>
              </div>
            ))}
          </nav>
          <nav className="flex justify-center py-1">
            <SocialLink className="text-white" />
            <SocialLink className="text-white mx-4" type="twitter" />
            <SocialLink className="text-white" type="instagram" />
          </nav>
        </section>
        <div className="pt-8 text-center">
          <small className="text-white text-xs">
            Copyright {year} Communa. All Rights Reserved.
          </small>
        </div>
      </div>
    </footer>
  );
};

export default SignupV2Footer;
