import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { setRefreshTasks } from '@/store/slices/eventsSlice';
import { logAnalyticEvent } from '@/config/analytics';
import { taskType } from '@/utils/helpers';

import TaskError from './TaskError';
import TaskCheckPhone from './TaskCheckPhone';
import TaskCheckEmail from './TaskCheckEmail';
import TaskReferAFriend from './TaskReferAFriend';
import TaskCap from './TaskCap';
import TaskDefault from './TaskDefault';

const TaskDispatcher = ({
  task,
  taskId,
  rate,
  onClose,
  onComplete = null,
  onToggleProfiler,
  bypassIntro = false,
  setLoading = null,
  ...props
}) => {
  const dispatch = useDispatch();

  const { goal_id, task_type, slink, caps, task_status } = task;

  const isLocked = [
    'phone_verification_required',
    'email_verification_required',
  ].includes(task_status);

  const handleOnClick = () => {
    logEvent();

    if (task_type === 'profiler') {
      onClose();

      logAnalyticEvent('profiler', 'start', {
        profiler_id: taskId,
        profiler_title: task?.name,
      });

      onToggleProfiler();
      return;
    }

    if (task_type !== 'goal') {
      window.open(slink, '_blank');
    }

    if (
      [
        'survey',
        'limited_time_offers',
        'enter_to_win',
        'up_and_coming_surveys',
        'hot_surveys',
        'popular_surveys',
        'trending_surveys',
      ].includes(task_type)
    ) {
      dispatch(setRefreshTasks(true));
    }
  };

  const logEvent = () => {
    logAnalyticEvent('task-card', 'go-press', {
      task_id: taskId,
      subtask_id: task?.subtask_id,
      task_title: task?.name,
      task_type: taskType(taskId, task?.task_type),
    });
  };

  useEffect(() => {
    if (bypassIntro) {
      handleOnClick();
    }
  }, []);

  if (isLocked) {
    return <TaskError task={task} onClose={onClose} />;
  }

  if (caps) {
    return <TaskCap task={task} onClose={onClose} />;
  }

  if (task_type === 'goal' && goal_id === 'verify_phone') {
    return <TaskCheckPhone task={task} onClose={onClose} {...props} />;
  }

  if (task_type === 'goal' && goal_id === 'verify_email') {
    return <TaskCheckEmail task={task} onClose={onClose} />;
  }

  if (task_type === 'goal' && goal_id === 'referral') {
    return (
      <TaskReferAFriend
        task={task}
        onClose={onClose}
        onComplete={() => onComplete && onComplete()}
        setParentLoading={setLoading}
      />
    );
  }

  if (bypassIntro) {
    return <></>;
  }

  return (
    <TaskDefault
      task={task}
      rate={rate}
      onClose={onClose}
      onClick={handleOnClick}
    />
  );
};

TaskDispatcher.propTypes = {
  task: PropTypes.object.isRequired,
  taskId: PropTypes.string.isRequired,
  rate: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
  setLoading: PropTypes.func,
  onToggleProfiler: PropTypes.func,
  bypassIntro: PropTypes.bool,
};

export default TaskDispatcher;
