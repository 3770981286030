import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setLockAppToDashboard } from '@/store/slices/configSlice';
import TermsModal from '@/components/TermsModal';
import WelcomeModal from '@/components/WelcomeModal';
import ReferralModal from '@/components/ReferralModal';
import { useLocation } from 'react-router-dom';

const ActionsManager = () => {
  const location = useLocation();

  const dispatch = useDispatch();
  const { list: actions } = useSelector((state) => state.actions);
  const { list: goals } = useSelector((state) => state.goals);

  const { lockAppToDashboard } = useSelector((state) => state.config);

  const actionIs = (name) => actions.length && actions[0].id === name;

  // If any action exists here, we lock the app
  // to the dashboard so the user "cannot"
  // reach another page or try to do something else
  useEffect(() => {
    const hasFTUETutorial = actions.some(
      (item) => item.id === 'TUTORIAL_FIRST_TIME_USER'
    );

    // Lock to the dashboard if they still have to do the FTUE
    if (hasFTUETutorial) {
      dispatch(setLockAppToDashboard(true));
      return;
    }

    // Otherwise, unlock it
    dispatch(setLockAppToDashboard(false));
  }, [actions]);

  // These items are only shown on the dashboard
  if (location.pathname !== '/dashboard') {
    return <></>;
  }

  if (actionIs('TERMS_AGREE_POPUP')) {
    return <TermsModal terms={actions[0]} />;
  }

  const hasReferralCard = goals.some((item) => item.goal_id === 'referral');
  if (actionIs('TUTORIAL_REFERRAL') && hasReferralCard) {
    return <ReferralModal terms={actions[0]} />;
  }

  if (actionIs('TUTORIAL_FIRST_TIME_USER')) {
    return <WelcomeModal terms={actions[0]} />;
  }

  return <></>;
};

export default ActionsManager;
