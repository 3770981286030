import { useState } from 'react';
import { Link } from 'react-router-dom';
import FormItemV2 from '@/components/FormItemV2';
import Alert from '@/components/Alert';
import ButtonV2 from '@/components/ButtonV2';
import FormWrapper from '@/components/FormWrapper';
import { post } from '@/config/axios';
import { makeFieldErrors } from '@/utils/helpers';

const PasswordForgotPage = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [isSent, setIsSent] = useState(false);
  const [fieldErrors, setFieldErrors] = useState();
  const [formError, setFormError] = useState();

  const handleSubmit = async () => {
    setError(null);
    setLoading(true);
    try {
      const { data } = await post('v1/reset_password/send', null, {
        speculative: false,
        email,
      });
      setIsSent(true);
      setLoading(false);
    } catch (err) {
      const { fieldErrors, formError } = makeFieldErrors(
        err?.response?.data?.errors
      );
      if (fieldErrors) {
        setFieldErrors(fieldErrors);
      }
      if (formError) {
        setFormError(formError);
      }
      setLoading(false);
    }
  };

  if (isSent) {
    return (
      <div>
        <Alert>
          <p className="break-all max-w-full text-green-600">
            A reset password link has been sent to &quot;{email}&quot;.
          </p>
        </Alert>
        <div className="text-center text-sm">
          <Link
            to="/login"
            className="hover:text-blue transition-colors duration-200"
          >
            Return to Login Page
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div>
      <p className="mb-5 text-center ">
        Please enter your email to reset password.
      </p>
      <FormWrapper onSubmit={handleSubmit} error={formError}>
        <FormItemV2
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          name="email"
          className="mb-5"
          placeholder="Email"
          floatingLabel
          error={fieldErrors?.email}
        />
        <div className="flex flex-col sm:flex-row items-center justify-between">
          <ButtonV2
            loading={loading}
            disabled={!email}
            variant="success"
            className="w-32"
            type="submit"
          >
            Submit
          </ButtonV2>
          <Link
            to="/login"
            className="hover:text-blue mt-6 sm:mt-0 text-grey text-sm transition-colors duration-200 font-bold"
          >
            Cancel
          </Link>
        </div>
      </FormWrapper>
      <div className="mt-3 text-right" />
    </div>
  );
};

export default PasswordForgotPage;
