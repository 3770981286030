import amplitude from 'amplitude-js';
import ReactGA from 'react-ga4';
import store from '@/store';

export const logAnalyticEvent = (
  category,
  name = null,
  props = null,
  callback = null,
  option
) => {
  // Amplitude
  if (!option?.dontSendAmplitude) {
    const { user } = store.getState();

    const eventName = name ? `${category} ${name}` : category;

    amplitude.getInstance().setUserId(user?.userId);

    amplitude.getInstance().logEvent(eventName, props, callback);
  }

  // Analytics
  ReactGA.event({
    category,
    action: name,
    label: props?.description || null,
    value: props?.value || null,
    transport: 'beacon',
  });
};

export const setAmplitudeUser = (user) => {
  // Set the user ID in Amplitude
  if (!user) return;
  if (user._id) {
    amplitude.getInstance().setUserId(user._id);
  }

  const userInfo = {};

  for (const key in user) {
    if (key === '_id') {
      userInfo['user_id'] = user[key];
    }
    if (key !== 'redeemable_amount' && key !== 'points') {
      userInfo[key] = user[key];
    }
  }

  amplitude.getInstance().setUserProperties(userInfo);
};

const initGa = () => {
  ReactGA.initialize(process.env.REACT_APP_GA_API_KEY);
};

const initAmplitude = () => {
  amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY);
};

const init = () => {
  initAmplitude();
  initGa();
};

export default init;
