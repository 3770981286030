import { cloneElement, Children, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const GridLayout = ({ children }) => {
  const [cols, setCols] = useState(3);
  const [childElements, setChildElements] = useState([]);

  const makeGrid = () => {
    const arrayChildren = Children.toArray(children);
    const elements = [];
    const wrapper = {};

    for (let i = 0; i < cols; i++) {
      wrapper[`col-${i}`] = [];
    }

    Children.map(arrayChildren, (child, i) => {
      wrapper[`col-${i % cols}`].push(
        <div key={`wpch-${i}`} className="mb-6">
          {cloneElement(child)}
        </div>
      );
    });

    for (let i = 0; i < cols; i++) {
      elements.push(
        <div key={`wp-${i}`} className={`${i > 0 ? 'ml-6' : ''} flex-1`}>
          {wrapper[`col-${i}`]}
        </div>
      );
    }

    setChildElements(elements);
  };

  const handleResize = () => {
    const colSize =
      window.innerWidth > 860 ? 3 : window.innerWidth > 480 ? 2 : 1;
    setCols(colSize);
  };

  useEffect(() => {
    makeGrid();
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.addEventListener('resize', handleResize);
  }, [children]);

  return (
    <div className="flex">{childElements?.length ? childElements : <></>}</div>
  );
};

GridLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GridLayout;
