import { useEffect, useRef, useState } from 'react';
import useInterval from '@/hooks/useInterval';

const Rewards = () => {
  const spanRef = useRef();
  const list = ['Amazon', 'Apple', 'Visa', 'Amazing', 'Amazon'];
  const [currentWord, setCurrentWord] = useState(0);
  const [wordsLength, setWordsLength] = useState([]);

  useInterval(() => {
    let nextWord = currentWord === list.length - 1 ? 0 : currentWord + 1;
    setCurrentWord(nextWord);
  }, 4000);

  const getWordsLength = () => {
    const lengths = [];
    const list = spanRef?.current?.children;
    if (!list) return;
    for (const item of list) {
      if (item) {
        const { width } = item.getBoundingClientRect();
        lengths.push(width);
      }
    }
    setWordsLength(lengths);
  };

  useEffect(() => {
    window.addEventListener('resize', () => getWordsLength());
    getWordsLength();
    return () => window.removeEventListener('resize', () => getWordsLength());
  }, []);

  return (
    <span className="relative">
      <span ref={spanRef} className="relative ml-4 z-1">
        {list.map((word, i) => (
          <span
            key={`wd-${i}`}
            className={`absolute top-0 transition-all transform duration-400 left-0 
          ${i === currentWord ? 'opacity-100' : 'opacity-0'}
          ${i + 1 === currentWord ? ' -translate-y-10' : ''}
          ${i - 1 === currentWord ? ' translate-y-10' : ''}
          `}
          >
            {word}
          </span>
        ))}
      </span>
      <span
        style={{ minWidth: `${wordsLength[currentWord]}px` }}
        className="h-3 transition-all duration-200 w-full bg-blue-400 absolute bottom-1"
      />
    </span>
  );
};

export default Rewards;
