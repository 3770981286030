import PropTypes from 'prop-types';

const TutorialBackground = ({ onClick = null }) => {
  const onClickHandler = (e) => {
    onClick && onClick();
  };

  return (
    <div
      onClick={onClickHandler}
      role="presentation"
      className="opacity-80 visible z-150 fixed inset-0 bg-grey-900"
    />
  );
};

TutorialBackground.propTypes = {
  onClick: PropTypes.func,
};

export default TutorialBackground;
