import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Alert from '@/components/Alert';
import { get } from '@/config/axios';
import RewardItem from '@/components/RewardItem';
import LoadingEllipsis from '@/components/LoadingEllipsis';
import { useSelector } from 'react-redux';

const RewardsPage = () => {
  const { points } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const [rewards, setRewards] = useState([]);
  const [hasError, setHasError] = useState(false);

  const getRewards = async () => {
    setHasError(false);
    try {
      const { data } = await get('v1/reward/load');
      setRewards(data.data);
      setLoading(false);
    } catch (err) {
      // TODO: Handle errors
      setHasError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    getRewards();
  }, [points]);

  return (
    <>
      <Helmet>
        <title>Redeem / Communa | Give Your Opinion. Earn Rewards</title>
      </Helmet>

      <h1 className="text-2xl">Redeem</h1>
      {hasError && (
        <Alert type="error">
          Something went wrong while trying to load the rewards. Please refresh
          the page and try again. <br />
          If the issue persists, please{' '}
          <Link to="/contact">
            <u>contact us</u>
          </Link>
          .
        </Alert>
      )}

      {loading ? (
        <div className="text-blue flex p-10 md:p-16 justify-center">
          <LoadingEllipsis width={60} />
        </div>
      ) : (
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-10">
          {rewards.map((reward, i) => (
            <RewardItem reward={reward} key={`rw-${i}`} />
          ))}
        </div>
      )}
    </>
  );
};

export default RewardsPage;
