import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FacebookLoginButton from '@/components/FacebookLoginButton';
import GoogleLoginButton from '@/components/GoogleLoginButton';
import FormItemV2 from '@/components/FormItemV2';
import Button from '@/components/Button';
import ButtonV2 from '@/components/ButtonV2';
import FormWrapper from '@/components/FormWrapper';
import useAuth from '@/hooks/useAuth';
import { logAnalyticEvent } from '@/config/analytics';

const LoginPage = () => {
  const { logoutSessionId } = useSelector((state) => state.user);
  const { list: flags } = useSelector((state) => state.flags);
  const navigateTo = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState();
  const [formError, setFormError] = useState();
  const auth = useAuth();

  const handleOnSubmit = async (data = null, isSocial = false) => {
    setLoading(true);
    setFieldErrors();
    setFormError();

    const formData = data || {
      email,
      password,
      tempToken: logoutSessionId,
      session_id: logoutSessionId,
    };

    const { success, isSignup, formError, fieldErrors } = await auth.login(
      formData,
      isSocial
    );

    if (isSignup) {
      // Facebook tracking
      fbq('track', 'Lead');
      // Amplitude and GA tracking
      logAnalyticEvent('signup', 'successful', {
        description: 'Application Signup',
        value: 1,
      });
    }

    setLoading(false);

    if (!success) {
      fieldErrors && setFieldErrors(fieldErrors);
      formError && setFormError(formError);
      return;
    }

    navigateTo('/dashboard');
  };

  useEffect(() => {
    logAnalyticEvent('login_page', 'landing');
  }, []);

  return (
    <div>
      {flags && flags.facebook_authentication && (
        <FacebookLoginButton
          onClick={() => setFormError()}
          onError={(error) => setFormError(error)}
          onSuccess={(formData) => handleOnSubmit(formData, true)}
        />
      )}
      {flags && flags.google_authentication && (
        <GoogleLoginButton
          className="mt-4"
          onClick={() => setFormError()}
          onError={(error) => setFormError(error)}
          onSuccess={(formData) => handleOnSubmit(formData, true)}
        />
      )}
      {flags && (flags.google_authentication || flags.facebook_authentication) && (
        <div className="my-10">
          <hr className="or-divider text-gray-500 border-t border-gray-300 overflow-visible text-center" />
        </div>
      )}
      <FormWrapper onSubmit={handleOnSubmit} error={formError}>
        <FormItemV2
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email address"
          label="Email"
          autoComplete="email"
          error={fieldErrors?.email}
          floatingLabel
          required
          disabled={loading}
        />

        <FormItemV2
          type="password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="my-6"
          placeholder="Password"
          label="Password"
          autoComplete="current-password"
          error={fieldErrors?.password}
          floatingLabel
          required
          disabled={loading}
        />

        <div className="flex flex-col sm:flex-row items-center justify-between">
          <ButtonV2
            className="w-full sm:w-32"
            loading={loading}
            variant="success"
            type="submit"
          >
            Log In
          </ButtonV2>
          <Link
            to="/password/forgot"
            className=" hover:text-blue mt-6 sm:mt-0 text-grey text-sm transition-colors duration-200 font-bold"
          >
            Forgot Password?
          </Link>
        </div>
      </FormWrapper>

      <div className="mt-2 sm:mt-4 py-4 text-center text-grey">
        Don&apos;t have an account?{' '}
        <Link
          to="/signup"
          className="text-blue hover:text-blue-400 transition-colors duration-200 font-bold"
        >
          Sign up
        </Link>
      </div>
    </div>
  );
};

export default LoginPage;
