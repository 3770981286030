import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';

import ReCAPTCHA from 'react-google-recaptcha';

import FormItemV2 from '@/components/FormItemV2';
import ButtonV2 from '@/components/ButtonV2';
import SocialSignup from './SocialSignup';
import Alert from '@/components/Alert';
import { logAnalyticEvent } from '@/config/analytics';
import useAuth from '@/hooks/useAuth';

const SignupFormV2 = () => {
  const recaptchaRef = useRef();
  const [signupFormData, setSignupFormData] = useState({
    email_communa: '',
    password_communa: '',
  });
  const [loading, setLoading] = useState(false);
  const { cmpn, campaignInfo } = useSelector((state) => state.config);
  const [token, setToken] = useState(null);
  const navigateTo = useNavigate();
  const auth = useAuth();

  const [fieldErrors, setFieldErrors] = useState();
  const [formError, setFormError] = useState('');

  const logFormEvent = (authenticator, type) => {
    logAnalyticEvent('signup-page', type, {
      ...campaignInfo,
      authenticator,
    });
  };

  const handleOnSubmit = async (formData = null) => {
    logAnalyticEvent('signup', 'register_attempt');

    if (!formData) {
      setLoading(true);
      logFormEvent('communa', 'form-submit');
    }

    let postData = {
      email_communa: formData ? formData.email : signupFormData.email_communa,
      password_communa: formData
        ? formData.id
        : signupFormData.password_communa,
      password_communa_1: formData
        ? formData.id
        : signupFormData.password_communa,
    };

    if (formData) {
      postData = {
        ...postData,
        ...formData,
      };
    }

    if (!formData) {
      // const token = await recaptchaRef.current.executeAsync();
      postData = {
        ...postData,
        recaptcha: token,
        is_invisible_recaptcha: '1',
        bypass_recaptcha_check: '0',
      };
    }

    if (cmpn) {
      postData.cmpn = cmpn;
    }

    const cmvId = localStorage.getItem('cm_vId');
    const leadId = localStorage.getItem('leadId');

    if (cmvId) {
      postData.test_id = 'lander_test';
      postData.variant_id = cmvId;
    }
    if (leadId) {
      postData.lead_id = leadId;
    }

    delete postData.email;
    delete postData.password;

    const {
      success,
      isSignup,
      formError,
      fieldErrors,
      isMobileOrTabletSignup,
      isFacebookConversion,
    } = await auth.signup(postData, true);

    if (!success) {
      setLoading(false);

      if (fieldErrors?.recaptcha && !formError) {
        setFormError(
          'Something went wrong while we tried to validate your subscription. Please refresh the page and try again.'
        );
      }

      fieldErrors && setFieldErrors(fieldErrors);
      formError && setFormError(formError);

      // Reset reCaptcha
      window?.grecaptcha.reset();
      setToken(false);
      return;
    }

    if (isSignup) {
      // Facebook tracking

      const analyticsParams = {
        description: 'Application Signup',
        value: 1,
      };

      if (isMobileOrTabletSignup !== null) {
        analyticsParams.is_mobile_or_tablet_signup = isMobileOrTabletSignup;
      }

      if (isFacebookConversion !== null) {
        analyticsParams.is_facebook_conversion = isFacebookConversion;
      }

      fbq('track', 'Lead');
      // Amplitude and GA tracking
      logAnalyticEvent('signup', 'successful', analyticsParams);
    }

    setLoading(false);

    navigateTo('/dashboard');
  };

  const handleOnFormSubmit = (e) => {
    e.preventDefault();
    token ? handleOnSubmit() : window.grecaptcha.execute();
  };

  const onSuccessChallenge = (token) => {
    setToken(token);
  };

  const onExpired = () => {
    setToken(null);
  };

  const handleOnChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (fieldErrors?.[name]) {
      const clonedErrors = { ...fieldErrors };
      delete clonedErrors[name];
      setFieldErrors(clonedErrors);
    }

    setSignupFormData({
      ...signupFormData,
      [name]: value,
    });
  };

  const initRecaptcha = () => {
    window.grecaptcha.ready(function () {
      window.grecaptcha.render('recaptcha-container', {
        sitekey: process.env.REACT_APP_RECAPTCHA_INVISIBLE_KEY,
        size: 'invisible',
        callback: onSuccessChallenge,
        'expired-callback': onExpired,
      });
    });
  };

  useEffect(() => {
    token && handleOnSubmit();
  }, [token]);

  useEffect(() => {
    initRecaptcha();
  }, []);

  return (
    <section className="md:w-1/2 px-6 md:px-8 py-8 md:py-12 anim anim1">
      <h2>Signup</h2>
      <p className="mb-8">
        Signup with Google, Facebook or fill out the signup form below and start
        earning!
      </p>

      <SocialSignup
        setFormError={setFormError}
        logFormEvent={logFormEvent}
        onSubmit={handleOnSubmit}
      />
      {formError && <Alert type="error">{formError}</Alert>}
      <form onSubmit={handleOnFormSubmit}>
        <div>
          <FormItemV2
            name="email_communa"
            placeholder="Email address"
            label="Email"
            autoComplete="email"
            value={signupFormData.email_communa}
            onChange={handleOnChange}
            onClick={() => logFormEvent('communa', 'form-focus')}
            error={fieldErrors?.email_communa}
            floatingLabel
            disabled={loading}
          />
        </div>
        <div className="my-3">
          <FormItemV2
            name="password_communa"
            type="password"
            placeholder="Password"
            label="Password"
            autoComplete="password"
            value={signupFormData.password_communa}
            onChange={handleOnChange}
            revealable
            error={fieldErrors?.password_communa}
            floatingLabel
            disabled={loading}
          />
        </div>
        <div className="text-sm my-3">
          By signing up you agree to this site&apos;s{' '}
          <Link className="text-blue-500" to="/terms" target="_blank">
            Terms
          </Link>{' '}
          and{' '}
          <Link className="text-blue-500" to="/privacy" target="_blank">
            Privacy Policy
          </Link>
        </div>
        <div>
          <ButtonV2 loading={loading} variant="success" type="submit">
            Create Account
          </ButtonV2>
          <div id="recaptcha-container" />
          {/* <div
            className="g-recaptcha"
            data-sitekey={process.env.REACT_APP_RECAPTCHA_INVISIBLE_KEY}
            data-callback={successChallenge}
            data-size="invisible"
          /> */}
          {/* <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_INVISIBLE_KEY}
            size="invisible"
          /> */}
        </div>
        {/* https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed */}
        <p className="text-xs mt-4 text-gray-400">
          This site is protected by reCAPTCHA <br /> and the Google
          <a href="https://policies.google.com/privacy">Privacy Policy</a>{' '}
          <br /> and{' '}
          <a href="https://policies.google.com/terms">Terms of Service</a>{' '}
          apply.
        </p>
      </form>
    </section>
  );
};

export default SignupFormV2;
