import { Route, Routes, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import AppLayout from '@/layout/AppLayout';
import AuthLayout from '@/layout/AuthLayout';
import BlogLayout from '@/layout/BlogLayout';
import PageLayout from '@/layout/PageLayout';
import DefaultLayout from '@/layout/DefaultLayout';
import SignupV2Layout from '@/layout/SignupV2Layout';
import FaqPage from '@/pages/faq';
import PrivacyPage from '@/pages/privacy';
import RewardsPage from '@/pages/rewards';
import TermsPage from '@/pages/terms';
import TimelinePage from '@/pages/timeline';
import PrivacyCaliforniaPage from '@/pages/privacy-policy-ccpa';
import PrivacyCaliforniaPageOptIn from '@/pages/privacy-policy-ccpa-opt-in';
import PersonalInfoPage from '@/pages/do-not-sell-my-personal-information';
import DashboardPage from '@/pages/dashboard';
import ProfilePage from '@/pages/profile';
import ReferAndEarnPage from '@/pages/refer-and-earn';
import LogoutPage from '@/pages/logout';
import LoginPage from '@/pages/login';
import SignupPage from '@/pages/signup';
import EmailVerifiedPage from '@/pages/email-verified';
import ContactPage from '@/pages/contact';
import PasswordForgotPage from '@/pages/password/forgot';
import PasswordResetPage from '@/pages/password/reset';
import PasswordOldResetPage from '@/pages/password/old_reset';
import BlogPage from '@/pages/blog';
import BlogArticlePage from '@/pages/blog/article';
import SponsoredSurveyPage from '@/pages/sponsored-surveys/slug';
import ErrorUhOhPage from '@/pages/uh-oh';
import Error404Page from '@/pages/error404';
import UnsubscribedPage from '@/pages/unsubscribed';
import RootPage from '@/pages/index';

const renderRoutes = () => {
  const { list: flags } = useSelector((state) => state.flags);

  return (
    <Routes>
      <Route path="/">
        {/* App pages */}
        <Route element={<AppLayout />}>
          <Route path="" element={<RootPage />} />
          <Route path="dashboard" element={<DashboardPage />} />
          <Route path="cash-out" render={() => <Navigate to="/redeem" />} />
          <Route path="redeem" element={<RewardsPage />} />
          <Route path="timeline" element={<TimelinePage />} />
          <Route path="profile" element={<ProfilePage />} />
          <Route path="logout" element={<LogoutPage />} />
          <Route
            path="refer-and-earn"
            element={
              flags?.referral ? (
                <ReferAndEarnPage />
              ) : (
                <Navigate to="/not-found" />
              )
            }
          />
          <Route
            path="sponsored-surveys/:slug"
            element={<SponsoredSurveyPage />}
          />
        </Route>

        {/* Auth -- except signups */}
        <Route element={<AuthLayout />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="password/forgot" element={<PasswordForgotPage />} />
          <Route path="reset_password" element={<PasswordOldResetPage />} />
          <Route path="password/reset" element={<PasswordResetPage />} />
        </Route>

        {/* Signup */}
        <Route element={<SignupV2Layout />}>
          <Route path="signup" element={<SignupPage />} />
        </Route>

        {/* Signup V2 */}
        {/* <Route element={<SignupV2Layout />}>
          <Route path="signup-v2" element={<SignupV2Page />} />
        </Route> */}

        {/* Pages */}
        <Route element={<PageLayout />}>
          <Route path="faq" element={<FaqPage />} />
          <Route path="privacy" element={<PrivacyPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route
            path="privacy-policy-ccpa"
            element={<PrivacyCaliforniaPage />}
          />
          <Route
            path="privacy-policy-ccpa-opt-in"
            element={<PrivacyCaliforniaPageOptIn />}
          />
          <Route
            path="do-not-sell-my-personal-information"
            element={<PersonalInfoPage />}
          />
          <Route path="terms" element={<TermsPage />} />
          <Route path="unsubscribed" element={<UnsubscribedPage />} />
          <Route path="email-verified" element={<EmailVerifiedPage />} />
        </Route>
      </Route>

      {/* Blog */}
      <Route path="/blog" element={<BlogLayout />}>
        <Route path="" element={<BlogPage />} />
        <Route path=":id/:slug" element={<BlogArticlePage />} />
      </Route>

      <Route element={<DefaultLayout />}>
        <Route path="uh-oh" element={<ErrorUhOhPage />} />
        <Route path="*" element={<Error404Page />} />
      </Route>

      <Route
        path="*"
        element={
          <DefaultLayout>
            <Error404Page />
          </DefaultLayout>
        }
        status={404}
      />
    </Routes>
  );
};

export default renderRoutes;
