import { createSlice } from '@reduxjs/toolkit';

export const goalsSlice = createSlice({
  name: 'events',
  initialState: {
    list: [],
    refreshGoals: false,
  },
  reducers: {
    addGoal: (state, { payload }) => {
      state.list.push(payload);
    },
    setGoals: (state, { payload }) => {
      state.list = payload;
    },
    removeGoal: (state, { payload }) => {
      state.list = state.list.filter((event) => event._id !== payload);
    },
    setRefreshGoals: (state, { payload }) => {
      state.refreshGoals = payload;
    },
  },
});

export const {
  addGoal,
  setGoals,
  removeGoal,
  setRefreshTasks,
} = goalsSlice.actions;

export default goalsSlice.reducer;
