import { useState, useEffect } from 'react';
import { post } from '@/config/axios';
import NavItem from './NavItem';
import SkeletonLoading from './SkeletonLoading';

const SponsoredNav = () => {
  const [sponsors, setSponsors] = useState([]);
  const [loading, setLoading] = useState(true);

  const getSponsors = async () => {
    setLoading(true);
    try {
      const { data } = await post(
        'v2/payload',
        {
          path: '/dashboard',
        },
        null,
        true
      );
      data.data?.survey_sponsors?.length &&
        setSponsors(data.data.survey_sponsors);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSponsors();
  }, []);

  if (loading) {
    return (
      <div className="mt-4">
        <SkeletonLoading isNav={false} hasTitle={true} />
      </div>
    );
  }

  if (!loading && !sponsors.length) {
    return <></>;
  }

  return (
    <section className="md:block hidden">
      <h3 className="px-3 mt-5 mb-2 font-oswald text-xs text-grey-400 uppercase">
        Sponsored Surveys
      </h3>
      <nav>
        {sponsors.map(({ id, name, url }, i) => (
          <NavItem to={url} label={name} key={`nv-${id}-${i}`} />
        ))}
      </nav>
    </section>
  );
};

export default SponsoredNav;
