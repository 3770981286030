import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Button from '@/components/Button';
import Modal from '@/components/Modal';
import { TaskContainer, TaskSomethingWrong } from '@/components/TaskModal';
import RedeemSelect from './components/RedeemSelect';
import { get } from '@/config/axios';

const RewardRedeemModal = ({ item, onClose }) => {
  const { userId } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [error, showError] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);

  const { name, disclaimer, image_urls, _id } = item;

  const onRedeem = async () => {
    setLoading(true);
    try {
      await get('v1/reward/redeem', {
        user_id: userId,
        product_id: _id,
        sub_product_id: selectedValue.id,
        cash_amount: selectedValue.value,
      });
      onClose();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      showError(true);
    }
  };

  const errorMsg = `Your ${name} gift card is not available. Please contact support. <br />
  Head back to dashboard to complete more surveys.`;

  if (error) {
    return (
      <Modal onClose={onClose}>
        {(onClose) => (
          <TaskSomethingWrong content={errorMsg} onClose={onClose} />
        )}
      </Modal>
    );
  }

  return (
    <Modal maxWidth="max-w-3xl" onClose={onClose}>
      {(onClose) => (
        <TaskContainer>
          <header
            className="rounded-t relative h-40 card_base flex items-center justify-center"
            style={{ clipPath: 'polygon(0 0, 101% 0, 101% 80%, 0% 100%)' }}
          >
            <button
              className="hover:opacity-100 opacity-75 duration-200 transition-opacity absolute top-4 right-4 z-10"
              onClick={onClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                viewBox="0 0 32 32"
              >
                <polygon
                  fill="#FFF"
                  points="32 3.8 28.2 0 16 12.2 3.8 0 0 3.8 12.2 16 0 28.2 3.8 32 16 19.8 28.2 32 32 28.2 19.8 16"
                />
              </svg>
            </button>
            <div className="text-white text-center">
              <div className="text-2xl font-bold">{name}</div>
              <div className="uppercase font-oswald text-sm">
                Powered By Tango
              </div>
            </div>
          </header>
          <div className="px-8">
            <section className="flex flex-col md:flex-row items-center md:items-end -mt-9 z-10 mb-6 relative">
              <img
                srcSet={`
          ${image_urls['1200w-326ppi']} 1200w,
          ${image_urls['300w-326ppi']} 300w,
          ${image_urls['278w-326ppi']} 278w,
          ${image_urls['200w-326ppi']} 200w,
          ${image_urls['130w-326ppi']} 130w
          `}
                src={image_urls['80w-326ppi']}
                className="rounded-2xl w-56 md:w-2/5"
                alt="reward bg"
              />

              <RedeemSelect
                item={item}
                onChange={(option) => setSelectedValue(option)}
              />
            </section>

            <p
              className="text-xs page"
              dangerouslySetInnerHTML={{ __html: disclaimer }}
            />
          </div>
          <footer className="p-5 pt-8 md:p-8">
            <Button
              loading={loading}
              onClick={onRedeem}
              variant="success"
              className="w-full"
            >
              Redeem
            </Button>
          </footer>
        </TaskContainer>
      )}
    </Modal>
  );
};

RewardRedeemModal.propTypes = {
  item: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RewardRedeemModal;
