import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormItem from '@/components/FormItem';
import FormSelect from '@/components/FormSelect';

const OptionsAddress = ({ data, onChange }) => {
  const [city, setCity] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('');
  const [regionOptions, setRegionOptions] = useState([]);
  const [region, setRegion] = useState('');

  const {
    type_specific_config: {
      country_list,
      region_map,
      without_country_and_region,
    },
  } = data;

  const makeRegionOptionsList = () => {
    const regions = region_map[country];
    const regionsList = regions.reduce((arr, reg) => {
      arr.push({ label: reg, value: reg });
      return arr;
    }, []);
    setRegionOptions(regionsList);
  };

  const isValid =
    !!city && !!postalCode && without_country_and_region
      ? true
      : !!country && !!region;

  useEffect(() => {
    if (country) {
      makeRegionOptionsList();
    }
  }, [country]);

  useEffect(() => {
    if (isValid) {
      const params = {
        street_address: '',
        city,
        postal_code: postalCode,
      };
      if (!without_country_and_region) {
        params.country = country;
        params.region = region;
      }
      onChange(params);
    }
  }, [city, postalCode, country, region]);

  return (
    <section>
      <FormItem
        label="City"
        placeholder="City"
        className="my-4"
        onChange={(e) => setCity(e.target.value)}
      />
      <FormItem
        label="Postal Code"
        placeholder="Postal Code"
        onChange={(e) => setPostalCode(e.target.value)}
      />
      {!without_country_and_region && (
        <>
          <FormSelect
            labelName="country_name"
            valueName="country_code"
            label="Country"
            options={country_list}
            className="my-4"
            placeholderOption="Select One"
            onChange={(e) => setCountry(e.target.value)}
          />
          {regionOptions.length > 0 && (
            <FormSelect
              placeholderOption="Region"
              label="Region"
              options={regionOptions}
              onChange={(e) => setRegion(e.target.value)}
            />
          )}
        </>
      )}
    </section>
  );
};

OptionsAddress.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default OptionsAddress;
