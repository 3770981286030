import PropTypes from 'prop-types';
import Button from '@/components/Button';
import TaskDetailHeader from './TaskDetailHeader';
import TaskContainer from './TaskContainer';

const TaskError = ({ task, message = '', onClose }) => {
  const errorTask = {
    ...task,
    design_class: 'Broken',
    name: 'Oops!',
  };

  const emailVerificationRequired =
    (task.task_status ?? null) === 'email_verification_required';

  const taskErrorMsg =
    message ||
    `Before taking this survey please confirm your ${
      emailVerificationRequired ? 'email' : 'phone number'
    } on your account.`;

  return (
    <TaskContainer>
      <TaskDetailHeader task={errorTask} onClose={onClose} />
      <p className="px-8 mt-6 text-lg text-center">{taskErrorMsg}</p>
      <footer className="p-8">
        <Button onClick={onClose} variant="success" className="w-full">
          Back to Surveys
        </Button>
      </footer>
    </TaskContainer>
  );
};

TaskError.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  task: PropTypes.object.isRequired,
};

export default TaskError;
