import PropTypes from 'prop-types';
import CopyBtn from './components/CopyBtn';
import ReferralItem from './components/ReferralItem';
import { numberFormat } from '@/utils/helpers';

const FriendsReferralSection = ({ referred, code }) => {
  return (
    <section className="my-10  max-w-5xl">
      <header>
        <h2 className="mb-4 text-xl">Referral Link</h2>
      </header>
      <div>
        <CopyBtn code={code} />
        <p className="mt-0.5 text-sm">
          Copy and share your referral link with friends!
        </p>
      </div>
    </section>
  );
};

FriendsReferralSection.propTypes = {
  referred: PropTypes.number.isRequired,
  code: PropTypes.string.isRequired,
};

export default FriendsReferralSection;
