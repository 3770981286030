import PropTypes from 'prop-types';

const TaskContainer = ({
  children,
  type = 'div',
  className = '',
  ...props
}) => {
  const El = type;
  return (
    <El className={`task-single bg-grey-50 rounded-lg ${className}`} {...props}>
      {children}
    </El>
  );
};

TaskContainer.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default TaskContainer;
