import PropTypes from 'prop-types';
import { useState } from 'react';

const RatingButton = ({ onChange }) => {
  const [rating, setRating] = useState(0);
  const [hoveredRating, setHoveredRating] = useState(0);

  const handleOnClick = (e, i) => {
    const newRating = Number(i + 1);
    e.preventDefault();
    setRating(newRating);
    onChange(newRating);
  };

  const isActive = (i) => i <= (hoveredRating || rating);

  return (
    <div className="flex">
      {[...Array(5)].map((s, i) => (
        <button
          key={`st-${i}`}
          className={`relative w-7 h-7 ${
            isActive(i + 1) ? 'text-yellow-500' : 'text-gray-400'
          }`}
          onClick={(e) => handleOnClick(e, i)}
          onMouseEnter={() => setHoveredRating(Number(i + 1))}
          onMouseLeave={() => setHoveredRating(rating)}
        >
          {/* <div className="absolute inset-0 flex border">
            <div className="w-1/2">
              <svg
                width="16"
                viewBox="0 0 7 13"
                xmlns="http://www.w3.org/2000/svg"
              >
                <polygon points="6.66 10.5033333 2.54466667 12.6666667 3.33066667 8.084 -6.88338275e-14 4.83866667 4.60133333 4.17 6.66 -1.05693232e-13" />
              </svg>
            </div>
            <div className="w-1/2 border" />
          </div> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-2 -2 24 24"
            fill="currentColor"
          >
            <path d="M10 16.379l-6.173 3.245 1.179-6.874L.01 7.882l6.902-1.003L10 .624l3.087 6.255 6.902 1.003-4.995 4.868 1.18 6.874z" />
          </svg>
        </button>
      ))}
    </div>
  );
};

RatingButton.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default RatingButton;
