import { useDispatch } from 'react-redux';
import {
  setLogoutSessionId,
  setUserId,
  setUserData,
} from '@/store/slices/userSlice';

import { useSearchParams } from 'react-router-dom';
import { setShowGlobalCurtain } from '@/store/slices/configSlice';
import { post } from '@/config/axios';
import { setAmplitudeUser } from '@/config/analytics';
import { makeFieldErrors } from '@/utils/helpers';
import { getToken, setToken } from '@/utils/token';

const useAuth = () => {
  const dispatch = useDispatch();

  const processAuth = (data, isSocial) => {
    const token = data?.session_id;
    const user = data?.data?.payload?.user;

    if (token) {
      dispatch(setLogoutSessionId(''));
      setToken(token);
    }

    if (user) {
      dispatch(setUserData(user));
      dispatch(setUserId(user._id));
      setAmplitudeUser(user);
    }

    isSocial && dispatch(setShowGlobalCurtain(false));
  };

  const processError = (err, isSocial) => {
    const { fieldErrors, formError } = makeFieldErrors(
      err?.response?.data?.errors
    );
    isSocial && dispatch(setShowGlobalCurtain(false));
    return { fieldErrors, formError };
  };

  const [searchParams] = useSearchParams();

  const doAuth = async (url, formData, isSocial, isFormSignup = false) => {
    let success = true;
    let formErrorResp = null;
    let fieldErrorsResp = null;
    let isSignup = ''; // TEMP
    let isMobileOrTabletSignup = null;
    let isFacebookConversion = null;

    const postData = {
      ...formData,
    };

    if (isFormSignup) {
      const referrerId = searchParams.get('referrer_id');
      const isInvite = searchParams.get('invite');
      if (referrerId) {
        postData.referrer_id = referrerId;
      }
      if (isInvite) {
        postData.invite = 1;
      }
    }

    try {
      const { data } = await post(url, postData);
      processAuth(data, isSocial);
      isSignup = data?.data?.is_signup;
      isMobileOrTabletSignup = data?.data?.is_mobile_or_tablet_signup;
      isFacebookConversion = data?.data?.is_facebook_conversion;
    } catch (err) {
      const { fieldErrors, formError } = processError(err, isSocial);
      success = false;
      formErrorResp = formError;
      fieldErrorsResp = fieldErrors;
    }

    return {
      success,
      isSignup,
      isMobileOrTabletSignup,
      isFacebookConversion,
      formError: formErrorResp,
      fieldErrors: fieldErrorsResp,
    };
  };

  const login = (formData, isSocial) => {
    return doAuth('v1/user/login', formData, isSocial);
  };

  const signup = (formData, isSocial) => {
    return doAuth('v1/user/signup', formData, isSocial, true);
  };

  return {
    login,
    signup,
  };
};

export default useAuth;
