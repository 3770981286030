export const getErrorMsg = (err) => {
  const errMsgLookup = {
    default: 'Something went wrong, please try again',
    '0204': 'Please enter a valid email address.',
    '0213': 'Please enter a valid email address.',
    429: "You've reached your invite limit. Try again tomorrow!",
    40011: 'You cannot invite yourself!',
  };

  if (!err?.response?.data) {
    return errMsgLookup['default'];
  }

  const hexCode =
    err.response?.data?.errors?.validation?.[0]?.code_hex ||
    err.response?.data?.errors?.[0]?.code_hex;
  const httpCode = err?.response?.data?.code;

  let errorCode = httpCode;

  if (hexCode) {
    errorCode = hexCode;
  }

  return errMsgLookup[errorCode] || errMsgLookup['default'];
};
