import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { currencyFormat } from '@/utils/helpers';
import FormSelect from '@/components/FormSelect/FormSelect';

const RedeemSelect = ({ item, onChange }) => {
  const {
    cash_adjusted,
    min_value_dollars,
    max_value_dollars,
    currency_code,
    points_adjusted,
    items,
  } = item;

  const [currentOption, setCurrentOption] = useState();
  const [options, setOptions] = useState([]);

  const makeFixedOptions = () => {
    let option_list = [];
    let highest_index = 0;
    let highest_points = 0;
    for (let i = 0; i < items.length; i++) {
      let item = items[i];
      let option = {};
      option.id = item._id;
      option.value = item.face_value;
      option.label = currencyFormat(item.face_value);
      option.disabled = item.face_value > cash_adjusted;
      if (
        item.face_value_points <= points_adjusted &&
        item.face_value_points > highest_points
      ) {
        highest_points = item.face_value_points;
        highest_index = i;
      }
      option_list.push(option);
    }
    option_list.sort((a, b) => (a.value < b.value ? -1 : 1));
    const currentOption = option_list[highest_index];
    setOptions(option_list);
    setCurrentOption(currentOption.value);
    onChange(currentOption);
  };

  const makeVariableOptions = () => {
    const option_list = [];
    let index = 0;
    let highest_index = 0;
    let highest_points = 0;
    let increment = min_value_dollars;
    for (let i = min_value_dollars; i <= max_value_dollars; i = i + increment) {
      let item = items[0];
      let option = {};
      option.id = item._id;
      option.value = i;
      option.label = currencyFormat(i);
      option.disabled = i > cash_adjusted;

      if (i >= 50) {
        increment = 50;
      }
      if (i <= cash_adjusted && i > highest_points) {
        highest_points = i;
        highest_index = index;
      }
      option_list.push(option);
      index++;
    }
    const currentOption = option_list[highest_index];
    setOptions(option_list);
    setCurrentOption(currentOption.value);
    onChange(currentOption);
  };

  const handleOnChange = (e) => {
    const option = options.find(
      (option) => option.value.toString() === e.target.value.toString()
    );
    setCurrentOption(option.value);
    onChange(option);
  };

  useEffect(() => {
    const value_type = item.items[0].value_type;
    value_type === 'FIXED_VALUE' ? makeFixedOptions() : makeVariableOptions();
  }, []);

  return (
    <div className="md:pl-4 text-center md:text-left mt-4 md:mt-0">
      <p className="text-lg">Select the amount you would like to redeem.</p>
      <div className="my-2">
        <FormSelect
          value={currentOption}
          name="redeem-select"
          options={options}
          onChange={handleOnChange}
        />
      </div>
      <p className="text-base">
        (min {currencyFormat(min_value_dollars)}, max
        {currencyFormat(max_value_dollars)} {currency_code})
      </p>
    </div>
  );
};

RedeemSelect.propTypes = {
  item: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RedeemSelect;
