import { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@/components/Modal';
import FormItemV2 from '@/components/FormItemV2';
import ButtonV2 from '@/components/ButtonV2';
import RatingButton from './RatingButton';

const TestimonialAddModal = ({ onClose }) => {
  const [review, setReview] = useState('');
  const [rating, setRating] = useState(null);

  return (
    <Modal onClose={onClose} maxWidth="max-w-xl">
      {(onClose) => (
        <div className="md:flex p-8">
          <section className="">
            <h2>Your opinion really matters to us.</h2>
            <p className="leading-relaxed">
              You are the reason we exist and strive and as Communa user, we
              value your opinion more than anything.
              <br /> Please take a short moment to let us know what you think of
              our services and how we can improve.
              <br />
              Thank you!
            </p>
            <div className="mt-6 mb-3 flex">
              <div className="mr-3 w-11 h-11 text-white flex items-center justify-center rounded-xl bg-blue-400">
                JF
              </div>
              <div>
                <div>Jordan, F</div>
                <div className="text-sm text-gray-400">Toronto, Canada</div>
              </div>
            </div>
            <form>
              <RatingButton onChange={(rating) => setRating(rating)} />
              <FormItemV2
                name="review"
                value={review}
                onChange={(e) => setReview(e.target.value)}
                type="textarea"
                label="Your Review"
                floatingLabel
                className="mt-4"
              />
              <div className="mt-4">
                <ButtonV2>Post Review</ButtonV2>
              </div>
            </form>
          </section>
        </div>
      )}
    </Modal>
  );
};

TestimonialAddModal.propTypes = {
  onClose: PropTypes.func,
};

export default TestimonialAddModal;
