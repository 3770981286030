import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { setShowGlobalCurtain } from '@/store/slices/configSlice';

const FacebookLoginButton = ({
  label = 'Login with Facebook',
  onError = null,
  onSubmit = null,
  onClick,
  onSuccess,
}) => {
  const APP_ID = process.env.REACT_APP_FB_APP_ID;
  const dispatch = useDispatch();

  const loadSdk = () => {
    ((d, s, id) => {
      const element = d.getElementsByTagName(s)[0];
      const fjs = element;
      let js = element;
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = `https://connect.facebook.net/en_US/sdk.js`;
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  };

  const fbAsyncInit = () => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: APP_ID,
        cookie: false,
        xfbml: false,
        version: 'v11.0',
      });
    };
  };

  const initFb = () => {
    if (document.getElementById('facebook-jssdk')) return;

    fbAsyncInit();
    loadSdk();
  };

  const checkLoginState = (response) => {
    onSubmit && onSubmit();
    if (response.authResponse) {
      dispatch(setShowGlobalCurtain(true));
      getUserData(response.authResponse.accessToken);
    }
  };

  const getUserData = (token) => {
    window.FB.api(
      '/me',
      { locale: 'en_US', fields: 'id,email' },
      ({ email, id }) => {
        const leadId = localStorage.getItem('leadId');

        const params = {
          id,
          email: email,
          password: id,
          source: 'facebook',
          access_token: token,
          speculative: false,
          lead_id: leadId,
        };
        onSuccess(params);
      }
    );
  };

  const fbSignIn = () => {
    onClick();
    window.FB.login(checkLoginState, { scope: 'email' });
  };

  useEffect(() => {
    initFb();
  }, []);

  return (
    <button
      className="rounded-full flex items-center justify-center relative px-4 h-11 w-full text-white hover:opacity-75 active:opacity-95 transition-all duration-200"
      style={{ background: '#3A559F' }}
      onClick={fbSignIn}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-7 -2 24 24"
        width="24"
        height="24"
        className="absolute left-5"
      >
        <path
          fill="currentColor"
          d="M2.046 3.865v2.748H.032v3.36h2.014v9.986H6.18V9.974h2.775s.26-1.611.386-3.373H6.197V4.303c0-.343.45-.805.896-.805h2.254V0H6.283c-4.34 0-4.237 3.363-4.237 3.865z"
        />
      </svg>
      {label}
    </button>
  );
};

FacebookLoginButton.propTypes = {
  label: PropTypes.string,
  onError: PropTypes.func,
  onSubmit: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default FacebookLoginButton;
