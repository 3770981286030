import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import TaskModal from '@/components/TaskModal';
import Fieldset from '@/components/Fieldset';
import Button from '@/components/Button';
import FormItemEdit from '@/components/FormItemEdit';
import Alert from '../Alert';

const ProfilePhone = () => {
  const { data } = useSelector((state) => state.user);
  const [currentPhone, setCurrentPhone] = useState(data?.phone || '');
  const [showConfirm, setShowConfirm] = useState(false);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const btnLabel = data.phone ? 'Update' : 'Verify Now';

  const taskOverride = data.phone
    ? {
        goal_id: 'verify_phone',
        task_type: 'goal',
        design_class: 'GoalVerifyPhone',
      }
    : null;

  useEffect(() => {
    if (data.phone && currentPhone && data.phone !== currentPhone) {
      setShowConfirm(true);
      setCurrentPhone(data.phone);
    }
    data.phone && setCurrentPhone(data.phone);
  }, [data.phone]);

  return (
    <>
      <div className="mt-6">
        <Fieldset title="Change Phone" className="mt-6">
          {showConfirm && (
            <div className="mt-3">
              <Alert onClose={() => setShowConfirm(false)}>
                Phone successfully updated!
              </Alert>
            </div>
          )}

          {data.phone ? (
            <FormItemEdit
              name="phone"
              className="my-4"
              value={data.phone}
              onEditBtnClick={() => setShowTaskModal(true)}
              disabled
            />
          ) : (
            <>
              <p className="my-4">
                Enter your phone number now and earn points!
              </p>
              <Button onClick={() => setShowTaskModal(true)}>{btnLabel}</Button>
            </>
          )}
        </Fieldset>
      </div>
      {showTaskModal && (
        <TaskModal
          taskId="verify_phone"
          source="Goals"
          bypassIntro={true}
          taskOverride={taskOverride}
          onClose={() => setShowTaskModal(false)}
          startStep2={!!data.phone}
          isPhoneChange={!!data.phone}
        />
      )}
    </>
  );
};

ProfilePhone.propTypes = {};

export default ProfilePhone;
