import PropTypes from 'prop-types';
import Modal from '@/components/Modal';
import { TaskSomethingWrong } from '@/components/TaskModal';

const TaskSomethingWrongModal = ({ content, onClose }) => {
  return (
    <Modal onClose={onClose}>
      {(onClose) => <TaskSomethingWrong content={content} onClose={onClose} />}
    </Modal>
  );
};

TaskSomethingWrongModal.propTypes = {
  content: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TaskSomethingWrongModal;
