import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Button from '@/components/Button';
import Logo from '@/components/Logo';
import AppFooterSimple from '@/components/AppFooterSimple';
import { setIsFailed } from '@/store/slices/configSlice';

const ErrorInner = ({ title, content }) => {
  const dispatch = useDispatch();
  return (
    <div className="bg-grey-200 flex flex-col min-h-screen justify-between py-12">
      <div className="flex justify-center">
        <Logo width="w-56" doNotBreak />
      </div>
      <section className="max-w-2xl py-8 px-4 mx-auto text-center">
        <h1 className="text-2xl md:text-3xl text-center">{title}</h1>
        <p
          className="text-lg md:text-xl mb-8 leading-relaxed"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <a
          onClick={() => dispatch(setIsFailed(false))}
          href="/"
          className="h-10 transition-all font-bold duration-200 rounded px-6 leading-none inline-flex items-center justify-center border-2 bg-transparent hover:bg-grey-700 active:bg-grey-800 hover:text-white active:text-white border-grey-700 active:border-grey-800"
        >
          Return to the dashboard
        </a>
      </section>
      <AppFooterSimple />
    </div>
  );
};

ErrorInner.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default ErrorInner;
