import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from '@/config/axios';
import { logAnalyticEvent } from '@/config/analytics';
import { taskType } from '@/utils/helpers';
import Modal from '@/components/Modal';
import TaskDispatcher from './components/TaskDispatcher';
import TaskSkeleton from './components/TaskSkeleton';
import TaskError from './components/TaskError';
import store from '@/store';

const TaskModal = ({
  onClose,
  taskId,
  pId = null,
  subtaskId = null,
  rfgId = null,
  source,
  onToggleProfiler = null,
  bypassIntro = false,
  taskOverride = null,
  onComplete = null,
  ...props
}) => {
  const [taskData, setTaskData] = useState();
  const [rateLimitData, setRateLimitData] = useState();
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const taskDataLookup = {
    referral: {
      task_type: 'goal',
      source: 'GoalReferral',
      design_class: 'GoalReferral',
      name: 'Invite a Friend',
      points: 1000,
      description: 'Invite a friend and get a reward.',
      task_class: 'Goals',
      goal_id: 'referral',
    },
  };

  const getTaskId = (task) => {
    return task.project_id || task.profiler_id || task.goal_id;
  };

  const getRateLimiting = (task) => {
    return {
      popup_common: task?.popup_common,
      popup_credit_delay: task?.popup_credit_delay,
      popup_rate_section_once: task?.popup_rate_section_once,
      popup_rate_section_once_day: task?.popup_rate_section_once_day,
      survey_interval: task?.survey_interval,
      survey_interval_check: task?.survey_interval_check,
      countdown_seconds: task?.countdown_seconds,
    };
  };

  const handleTaskData = (data) => {
    data?.rate_limiting && setRateLimitData(data.rate_limiting);
    data?.task && setTaskData(data.task);

    logAnalyticEvent('task-card', 'impression', {
      task_id: taskId,
      subtask_id: data.task?.subtask_id,
      task_title: data.task?.name,
      task_type: taskType(taskId, data.task?.task_type),
    });
  };

  const loadTaskDetails = async () => {
    setLoading(true);
    setHasError(false);

    if (taskOverride) {
      setTaskData(taskOverride);
      setLoading(false);
      return;
    }

    const taskParams = {
      source,
      task_id: taskId,
    };

    if (pId) {
      taskParams.p_id = pId;
    }

    if (subtaskId) {
      taskParams.subtask_id = subtaskId;
    }

    if (rfgId) {
      taskParams.rfg_id = rfgId;
    }

    // For those cases where the Task modal loads its own data
    if (taskDataLookup[taskId]) {
      setTaskData(taskDataLookup[taskId]);
      return;
    }

    // try get task data from cache
    const { tasks } = store.getState();
    const task = tasks.list.find((task) => {
      return Object.keys(taskParams).every((key) => {
        if (key == 'task_id') {
          return getTaskId(task) === taskParams[key];
        }
        return task[key] === taskParams[key];
      });
    });

    if (task) {
      handleTaskData({ task: task, rate_limiting: getRateLimiting(task) });
      setLoading(false);
      return;
    }

    // Otherwise, get the task data from the api
    try {
      const {
        data: { data },
      } = await get('v1/task/load-by-id', taskParams);

      handleTaskData(data);
      setLoading(false);
    } catch (err) {
      setHasError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadTaskDetails();
  }, []);

  return (
    <Modal onClose={onClose}>
      {(onCloseCallback) => {
        // Special cases when modals get there own data, instead of using `/task/load-by-id`
        if (['referral'].includes(taskId)) {
          return (
            <>
              {loading && <TaskSkeleton onClose={onCloseCallback} />}
              {taskData && (
                <div className={loading ? 'max-h-0 overflow-hidden' : ''}>
                  <TaskDispatcher
                    task={taskData}
                    taskId={taskId}
                    rate={rateLimitData}
                    onClose={onCloseCallback}
                    onToggleProfiler={onToggleProfiler}
                    bypassIntro={bypassIntro}
                    setLoading={setLoading}
                    {...props}
                  />
                </div>
              )}
            </>
          );
        }

        return loading ? (
          <TaskSkeleton onClose={onCloseCallback} />
        ) : hasError ? (
          <TaskError
            task={taskData}
            message="Something went wrong. Please try again or contact us."
            onClose={onCloseCallback}
          />
        ) : (
          <TaskDispatcher
            task={taskData}
            taskId={taskId}
            rate={rateLimitData}
            onClose={onCloseCallback}
            onToggleProfiler={onToggleProfiler}
            bypassIntro={bypassIntro}
            onComplete={() => onComplete && onComplete()}
            {...props}
          />
        );
      }}
    </Modal>
  );
};

TaskModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onToggleProfiler: PropTypes.func,
  source: PropTypes.string.isRequired,
  taskId: PropTypes.string.isRequired,
  pId: PropTypes.string,
  subtaskId: PropTypes.string,
  rfgId: PropTypes.string,
  bypassIntro: PropTypes.bool,
  taskOverride: PropTypes.object,
  onComplete: PropTypes.func,
};

export default TaskModal;
