import { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { get } from '@/config/axios';
import TaskModal from '@/components/TaskModal';
import LoadingEllipsis from '@/components/LoadingEllipsis';
import FriendsReferralSection from '@/components/FriendsReferralSection';
import FriendsInviteSection from '@/components/FriendsInviteSection';

const ReferAndEarnPage = () => {
  const { list: events } = useSelector((state) => state.events);

  const [showTaskModal, setShowTaskModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [invites, setInvites] = useState([]);
  const [referralData, setReferralData] = useState(null);

  // TODO: retrieve points? Or hardcoded
  const points = 500;

  // Get the refer a friend task data
  const getFriendsData = async () => {
    try {
      const {
        data: { data },
      } = await get('v1/friends/load');

      const { invites, referral } = data;

      invites && setInvites(invites);
      referral && setReferralData(referral);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFriendsData();
  }, []);

  useEffect(() => {
    // If one of their friends joins + confirms, reload friends list
    if (events.some((evt) => evt.type === 'REFERRED_EARNED_POINTS')) {
      getFriendsData();
    }
  }, [events]);

  return (
    <>
      <Helmet>
        <title>
          Refer and Earn / Communa | Give Your Opinion. Earn Rewards
        </title>
      </Helmet>
      <h1 className="text-2xl">Refer and Earn</h1>

      {loading ? (
        <div className="text-blue flex p-10 md:p-16 justify-center">
          <LoadingEllipsis width={60} />
        </div>
      ) : (
        <div className="pb-6 md:pb-12">
          <p className="mb-12">
            Invite friends to Communa and earn {points} points for each friend
            that signs up and completes their first survey!
            <br />
            {referralData.total_referred > 0 ? (
              <span>
                You have referred{' '}
                <strong>{referralData.total_referred} friends</strong> so far!
                Keep up the good work!
              </span>
            ) : (
              <span>
                You have not referred a friend yet. Get started and earn!
              </span>
            )}
          </p>

          <FriendsReferralSection
            referred={referralData.total_referred}
            code={referralData.id}
          />

          <FriendsInviteSection
            friends={invites}
            onShowModal={() => setShowTaskModal(true)}
          />
        </div>
      )}

      {showTaskModal && (
        <TaskModal
          taskId="referral"
          source="Goals"
          onClose={() => setShowTaskModal(false)}
          onComplete={() => getFriendsData()}
        />
      )}
    </>
  );
};

export default ReferAndEarnPage;
