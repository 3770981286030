import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { logAnalyticEvent } from '@/config/analytics';
import { post } from '@/config/axios';
import { getToken } from '@/utils/token';

// Signup v2
import SignupV2Hero from '@/components/SignupV2Hero';
import SignupV2Brands from '@/components/SignupV2Brands';
import SignupV2HowItWorks from '@/components/SignupV2HowItWorks';
import SignupV2WhyJoin from '@/components/SignupV2WhyJoin';
import SignupV2Testimonials from '@/components/SignupV2Testimonials';
import SignupV2Faq from '@/components/SignupV2Faq';
import SignupV2Ready from '@/components/SignupV2Ready';
import SignupV2Footer from '@/components/SignupV2Footer';
import LoadingEllipsis from '@/components/LoadingEllipsis';

const SignupPage = () => {
  if (!!getToken()) {
    return <Navigate to="/dashboard" />;
  }

  const { campaignInfo, cmpn } = useSelector((state) => state.config);
  const location = useLocation();

  const [analyticFired, setAnalyticFired] = useState(false);

  const logSignupEvent = async () => {
    if (analyticFired) return;

    logAnalyticEvent('signup-page', 'impression', {
      ...campaignInfo,
      is_campaign_lead: !!cmpn,
    });
  };

  useEffect(() => {
    logSignupEvent();
    // Avoid double recording. Fire only once (either campaign lead or organic)
    setAnalyticFired(true);
  }, [location]);

  return (
    <div style={{ background: '#F6F7F8' }} className="min-h-full">
      <SignupV2Hero />
      <SignupV2Brands />
      <SignupV2WhyJoin />
      <SignupV2HowItWorks />
      <SignupV2Testimonials />
      <SignupV2Faq />
      <SignupV2Ready />
      <SignupV2Footer />
    </div>
  );
};

export default SignupPage;
