import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { InView } from 'react-intersection-observer';
import TaskCard from '@/components/TaskCard';
import { removeAction, setTutorialToShow } from '@/store/slices/actionsSlice';
import { setLockAppToDashboard } from '@/store/slices/configSlice';
import { get } from '@/config/axios';
import icons from './icons';
import TutorialBackground from '@/components/TutorialBackground';

const TaskCategory = ({ title, tasks, type }) => {
  const { tutorialToShow } = useSelector((state) => state.actions);
  const dispatch = useDispatch();

  const isTutorialInCategory = !!tasks.find((task) =>
    ['GoalReferral', 'GeneralProfiler2105'].includes(task.source)
  );

  const showTutorial =
    ['GoalReferral', 'GeneralProfiler2105'].includes(tutorialToShow) &&
    isTutorialInCategory;

  const handleOnClose = (task) => {
    if (task.source === 'GoalReferral' && tutorialToShow === 'GoalReferral') {
      dispatch(setTutorialToShow('refer_a_friend'));
      dispatch(setLockAppToDashboard(false));
      dispatch(removeAction('TUTORIAL_REFERRAL'));
      completeReferralTutorial();
    }
  };

  const completeReferralTutorial = async () => {
    try {
      await get('v1/action/tutorial/referral-tutorial/complete');
    } catch (err) {}
  };

  // TEMP: Remove this later
  /*
  useEffect(() => {
    dispatch(setTutorialToShow('refer_a_friend'));
  }, []);
  */

  return (
    <InView triggerOnce={true} threshold={0.1}>
      {({ inView, ref }) => (
        <>
          <section
            ref={ref}
            className={`${inView && !showTutorial ? 'animate-1' : ''} ${
              !showTutorial ? 'animate' : ''
            } mb-8`}
          >
            <h2 className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                viewBox={icons[type].viewBox}
                className="mr-2"
              >
                <path fill="#767676" d={icons[type].path} />
              </svg>
              {title}
            </h2>
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
              {tasks.map((task, i) => (
                <TaskCard
                  showTutorial={tutorialToShow === task.source}
                  task={task}
                  category={type}
                  key={`tsk-${i}`}
                  onClose={() => handleOnClose(task)}
                />
              ))}
            </div>
          </section>

          {showTutorial && <TutorialBackground />}
        </>
      )}
    </InView>
  );
};

TaskCategory.propTypes = {
  title: PropTypes.string.isRequired,
  tasks: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
};

export default TaskCategory;
