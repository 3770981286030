import TaskCategory from '@/components/TaskCategory/TaskCategory';
import PropTypes from 'prop-types';

const TaskHeader = ({ task, children, category }) => {
  const {
    task_type,
    is_narrow_card,
    design_class,
    design_color,
    offer_image,
    name,
  } = task;

  const isGoal = task_type === 'goal';
  const isNarrowCard = is_narrow_card;

  console.log(task);

  return (
    <section className="overflow-hidden relative flex-1">
      <div className="p-3 relative">{children}</div>
    </section>
  );
};

TaskHeader.propTypes = {
  task: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  category: PropTypes.string,
};

export default TaskHeader;
