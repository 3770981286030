import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Confetti from 'react-confetti';
import { useSelector, useDispatch } from 'react-redux';
import TaskDetailHeader from './TaskDetailHeader';
import Button from '@/components/Button';
import { setAddPoints, setReducePoints } from '@/store/slices/userSlice';
import { setRefreshTasks } from '@/store/slices/eventsSlice';
import { useNavigate } from 'react-router-dom';
import { logAnalyticEvent } from '@/config/analytics';
import { removeAction } from '@/store/slices/actionsSlice';
import TaskContainer from './TaskContainer';

const TaskEventCongratulation = ({ task, onClose }) => {
  const { hasCollectedPoints } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const { title, message, points, submitText, type, additional } = task;
  const [isCollected, setIsCollected] = useState(false);
  const [showConfettis, setShowConfettis] = useState(false);

  const isRedeem = type === 'REDEEM_COMPLETE';
  const isProfiler = type === 'GENERALPROFILER2105_PROFILER_COMPLETE';

  const taskClone = {
    ...task,
    name: title,
    design_class: getDesignClass(type),
  };

  const btnLabel = submitText || isRedeem ? 'OK' : 'Collect';
  const friendName = task?.additional?.name || 'a friend';

  const msgLookup = {
    REFERRED_EARNED_POINTS: `You have earned <strong>${points} Pts</strong> because <strong>you referred ${friendName}</strong> and they earned points! Keep up the good&nbsp;work!`,
    REENGAGEMENT_INCENTIVE: `Welcome back! You've earned ${points} Pts for checking out Communa&nbsp;again!`,
    REFERRAL_POINTS_GOAL_REACHED: `You have earned your <strong>referral bonus</strong>! Amazing! <br /> Here are your <strong>${points} bonus Pts</strong>! `,
  };

  function getDesignClass(type) {
    if (type === 'REDEEM_COMPLETE') {
      return 'Trophy';
    }

    return 'Reward';
  }

  const handleOnClick = async () => {
    logAnalyticEvent('reward-popup', 'ok-press');

    if (isRedeem) {
      navigateTo('/dashboard');
      dispatch(setReducePoints(points));
      logAnalyticEvent('reward-popup', 'close');
      onClose();
      return;
    }

    setIsCollected(true);
    dispatch(setAddPoints(points));
  };

  const handleOnClose = () => {
    logAnalyticEvent('reward-popup', 'close');

    if (isRedeem) {
      dispatch(setReducePoints(points));
      onClose();
      return;
    }

    if (isProfiler) {
      dispatch(removeAction('TUTORIAL_FIRST_TIME_USER'));
    }
  };

  useEffect(() => {
    // If they've completed the first time user profile, dispatch facebook tracking event
    if (type === 'GENERALPROFILER2105_PROFILER_COMPLETE') {
      if (typeof fbq === 'function') {
        fbq('track', 'SubmitApplication');
      }
    }
  }, [type]);

  useEffect(() => {
    if (hasCollectedPoints) {
      dispatch(setRefreshTasks(true));
      logAnalyticEvent('reward-popup', 'close');
      if (isProfiler) {
        dispatch(removeAction('TUTORIAL_FIRST_TIME_USER'));
      }
      onClose();
    }
  }, [hasCollectedPoints]);

  useEffect(() => {
    setShowConfettis(true);

    logAnalyticEvent('reward-popup', 'impression');
  }, []);

  const msg = msgLookup?.[type] || message;

  return (
    <TaskContainer>
      <TaskDetailHeader task={taskClone} onClose={handleOnClose} />
      {showConfettis && (
        <div className="absolute top-0">
          <Confetti
            numberOfPieces={350}
            tweenDuration={300}
            recycle={false}
            width={800}
            gravity={0.2}
            height={800}
            onConfettiComplete={() => setShowConfettis(false)}
          />
        </div>
      )}
      <section id="coinsStart" className="mt-4">
        <p
          className="px-5 md:px-8 text-lg text-center"
          dangerouslySetInnerHTML={{ __html: msg }}
        />
        {additional?.referral?.earned_points &&
          additional?.referral?.required_points && (
            <p className="px-5 mt-3 md:px-8 text-lg text-center">
              You&apos;ve earned {additional.referral.earned_points} Pts towards
              your referral bonus! <br /> Just
              {additional.referral.required_points} Pts to go!
            </p>
          )}
      </section>

      <footer className="p-5 pt-8 md:p-8">
        <Button
          onClick={handleOnClick}
          variant="success"
          disabled={isCollected}
          className="w-full"
        >
          {btnLabel}
        </Button>
      </footer>
    </TaskContainer>
  );
};

TaskEventCongratulation.propTypes = {
  task: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TaskEventCongratulation;
