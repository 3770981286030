import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
import Alert from '@/components/Alert';
import { get } from '@/config/axios';
import { setRefreshTasks } from '@/store/slices/eventsSlice';
import { setGoals } from '@/store/slices/goalsSlice';
import TaskCategory from '@/components/TaskCategory/TaskCategory';
import GlobalLoading from '@/components/GlobalLoading';
import { addTasks } from '@/store/slices/tasksSlice';

const DashboardPage = () => {
  const { refreshTasks } = useSelector((state) => state.events);
  const { list: goals } = useSelector((state) => state.goals);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [hasError, setHasError] = useState(false);

  const getGoalsAndTasks = async () => {
    setHasError(false);
    setLoading(true);
    try {
      const { data: goalsData } = await get('v1/goal/load');
      const { data: tasksData } = await get('v1/task/load');

      goalsData?.data?.length && dispatch(setGoals(goalsData.data));
      tasksData?.data?.tasks && setTasks(categorizeTasks(tasksData.data.tasks));
      tasksData?.data?.tasks && dispatch(addTasks(tasksData.data.tasks));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setHasError(true);
    }
  };

  const categorizeTasks = (tasks) => {
    const featured = [];
    const hotSurveys = [];
    const answerAndEarn = [];
    const sponsored = [];
    const enterToWin = [];
    const limitedTimeOffers = [];
    const popularSurveys = [];
    const trendingSurveys = [];

    for (const task of tasks) {
      if (task.featured) {
        featured.push(task);
      }

      if (task.task_type === 'hot_surveys') {
        hotSurveys.push(task);
      }

      if (task.task_type === 'profiler') {
        answerAndEarn.push(task);
      }

      if (task.task_type === 'survey') {
        sponsored.push(task);
      }

      if (task.task_type === 'enter_to_win') {
        enterToWin.push(task);
      }

      if (task.task_type === 'limited_time_offers') {
        limitedTimeOffers.push(task);
      }

      if (task.task_type === 'popular_surveys') {
        popularSurveys.push(task);
      }

      if (task.task_type === 'trending_surveys') {
        trendingSurveys.push(task);
      }
    }

    const sortingFunction = function (a, b) {
      let output;
      const aScore = a.position_weight ?? -999999;
      const bScore = b.position_weight ?? -999999;

      if (aScore > bScore) {
        output = -1;
      } else if (bScore > aScore) {
        output = 1;
      } else {
        output = 0;
      }

      return output;
    };

    featured.sort(sortingFunction);
    hotSurveys.sort(sortingFunction);
    answerAndEarn.sort(sortingFunction);
    sponsored.sort(sortingFunction);
    enterToWin.sort(sortingFunction);
    limitedTimeOffers.sort(sortingFunction);
    popularSurveys.sort(sortingFunction);
    trendingSurveys.sort(sortingFunction);

    const taskList = [
      {
        title: 'Featured Offers',
        type: 'featured',
        tasks: featured,
      },
      {
        title: 'Hot Surveys',
        type: 'hot_surveys',
        tasks: hotSurveys,
      },
      {
        title: 'Trending Surveys',
        type: 'trending_surveys',
        tasks: trendingSurveys,
      },
      {
        title: 'Popular Surveys',
        type: 'popular_surveys',
        tasks: popularSurveys,
      },
      {
        title: 'Answer Surveys and Earn',
        type: 'answer_and_earn',
        tasks: answerAndEarn,
      },
      {
        title: 'Sponsored Surveys',
        type: 'sponsored',
        tasks: sponsored,
      },
      {
        title: 'Enter to Win',
        type: 'enter_to_win',
        tasks: enterToWin,
      },
      {
        title: 'Limited Time Offers',
        type: 'limited_time_offers',
        tasks: limitedTimeOffers,
      },
    ];

    return taskList;
  };

  useEffect(() => {
    if (refreshTasks) {
      getGoalsAndTasks();
      dispatch(setRefreshTasks(false));
    }
  }, [refreshTasks]); // When a congrats 🎉 event is completed, refresh tasks

  useEffect(() => {
    getGoalsAndTasks();
  }, []);

  return (
    <>
      <Helmet>
        <title>Dashboard / Communa | Give Your Opinion. Earn Rewards</title>
      </Helmet>

      {hasError ? (
        <Alert type="error">
          Something went wrong while trying to load the dashboard. Please
          refresh the page and try again. <br />
          If the issue persists, please{' '}
          <Link to="/contact">
            <u>contact us</u>
          </Link>
          .
        </Alert>
      ) : loading ? (
        <div>
          <GlobalLoading />
        </div>
      ) : (
        <>
          {goals && goals.length > 0 && (
            <TaskCategory
              title="Get Started and Earn"
              type="start"
              tasks={goals}
            />
          )}

          {tasks.map(
            ({ title, type, tasks }, i) =>
              tasks.length > 0 && (
                <TaskCategory
                  title={title}
                  type={type}
                  tasks={tasks}
                  key={`tskc-${i}`}
                />
              )
          )}
        </>
      )}
    </>
  );
};

export default DashboardPage;
