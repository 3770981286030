// import '@/utils/wdyr';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';

import Head from '@/components/Head';
import App from './App';

import reportWebVitals from '@/config/reportWebVitals';
import initAnalytics from '@/config/analytics';
import initErrorsTracking from '@/config/errorTracking';
import store from '@/store';

import '@/assets/css/vendors/tailwind.css';
import '@/assets/css/main.css';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <HelmetProvider>
        <Head />
        <App />
      </HelmetProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

initAnalytics();
initErrorsTracking();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
