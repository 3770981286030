import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  updateUserPoints,
  setAddPoints,
  setHasCollectedPoints,
  setAsyncPoints,
} from '@/store/slices/userSlice';
import CoinsAnimationV2 from './CoinsAnimationV2';

import PointsDisplay from './PointsDisplay';

const PointsCount = ({ refEl }) => {
  const { points, asyncPoints, addPoints } = useSelector((state) => state.user);
  const [animate, setAnimate] = useState('idle');
  const [posRight, setPosRight] = useState(180);
  const [isPositioned, setIsPositioned] = useState(false);
  const currentRef = useRef();
  const ballot = document.querySelector('#ballot');
  const dispatch = useDispatch();

  const printablePoints = asyncPoints || points;

  const isAnimating = animate === 'running';

  const positionCounter = () => {
    if (!ballot) return;
    const { left } = ballot.getBoundingClientRect();
    setPosRight(window.innerWidth - left);
    setIsPositioned(true);
  };

  useEffect(() => {
    if (addPoints) {
      setAnimate('running');
      dispatch(updateUserPoints(addPoints));
      if (asyncPoints) {
        dispatch(setAsyncPoints(asyncPoints + addPoints));
      }
    }
  }, [addPoints]);

  useEffect(() => {
    if (animate === 'complete') {
      dispatch(setHasCollectedPoints(true));
      setAnimate('idle');
      setTimeout(() => {
        dispatch(setAddPoints(null));
        dispatch(setHasCollectedPoints(false));
      }, 200);
    }
  }, [animate]);

  useEffect(() => {
    if (currentRef.current && refEl.current && ballot && !isPositioned) {
      positionCounter();
    }
  }, [currentRef.current, refEl.current, ballot]);

  useEffect(() => {
    window.addEventListener('resize', positionCounter);
    return () => {
      window.removeEventListener('resize', positionCounter);
    };
  }, []);

  return !printablePoints ? (
    <></>
  ) : (
    <>
      <div
        className="h-12 pt-1   transition-colors duration-200"
        style={{ right: posRight }}
        id="points"
        ref={currentRef}
      >
        <Link
          to="/redeem"
          className="font-bold flex items-center px-3 h-10 hover:bg-grey-50 rounded-full transition-all duration-200"
        >
          <div id="pointsDot" className="mr-1 h-2 rounded-full bg-green w-2 " />
          <PointsDisplay isSimpleDisplay />
        </Link>
      </div>

      <CoinsAnimationV2
        isAnimating={isAnimating}
        onDone={() => setAnimate('complete')}
      />
    </>
  );
};

PointsCount.propTypes = {
  refEl: PropTypes.any.isRequired,
};

export default PointsCount;
