import { Navigate } from 'react-router-dom';
import { getToken } from '@/utils/token';

const RootPage = () => {
  //
  // commenting the below out for now.
  // I'm not sure
  // on what's actually happening
  /*
  if (!!getToken()) {
    return <Navigate to="/dashboard" />;
  }
  */

  const params = window.location.search;

  return <Navigate to={`/signup${params}`} />;
};

export default RootPage;
