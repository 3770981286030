import PropTypes from 'prop-types';

const FormRadio = ({ onChange, className = '', name, value, label }) => {
  return (
    <label
      className={`${className} w-full form-radio flex items-center relative cursor-pointer `}
    >
      <input
        type="radio"
        className="absolute opacity-0 cursor-pointer"
        name={name}
        value={value}
        onChange={onChange}
      />
      <span className="radio-btn relative w-5 h-5 bg-grey-300 left-1 transition-all duration-200 rounded-full" />
      {label && <span className="text-sm font-bold pl-3 mt-px">{label}</span>}
    </label>
  );
};

FormRadio.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.any.isRequired,
};

export default FormRadio;
