import PropTypes from 'prop-types';
import CountAnimation from './CountAnimation';
import { numberFormat } from '@/utils/helpers';
import { useSelector } from 'react-redux';

const PointsDisplay = ({ isSimpleDisplay = false }) => {
  const { points, asyncPoints, addPoints } = useSelector((state) => state.user);

  const printablePoints = asyncPoints || points;

  const pointsPlural = printablePoints && printablePoints > 1 ? 's' : '';

  return (
    <div id="points" className="text-sm sm:text-base">
      {!isSimpleDisplay && addPoints ? (
        <CountAnimation
          start={printablePoints}
          points={addPoints}
          delay={800}
        />
      ) : (
        numberFormat(printablePoints)
      )}
      Pt
      {pointsPlural}
    </div>
  );
};

PointsDisplay.propTypes = {
  isSimpleDisplay: PropTypes.bool,
};

export default PointsDisplay;
