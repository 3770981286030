import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useIntersectionObserver from '@/hooks/useIntersectionObserver';

const AnimatedSection = ({ children, className = '', delay = 500 }) => {
  const [observerRef, isInViewport] = useIntersectionObserver();
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      !animate && isInViewport && setAnimate(true);
    }, delay);
  }, [isInViewport]);
  return (
    <section className={className} ref={observerRef}>
      {children(animate)}
    </section>
  );
};

AnimatedSection.propTypes = {
  children: PropTypes.func.isRequired,
  className: PropTypes.string,
  delay: PropTypes.number,
};

export default AnimatedSection;
