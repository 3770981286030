import { Outlet } from 'react-router-dom';
import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';
import withSession from '@/hoc/withSession';
import ErrorBoundary from '@/components/ErrorBoundary';

const PageLayout = () => {
  return (
    <div className="bg-grey-200 min-h-screen flex flex-col pt-12">
      <AppHeader />
      <div className="flex-auto">
        <main className="bg-white">
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </main>
      </div>
      <div className="flex-shrink-0">
        <AppFooter />
      </div>
    </div>
  );
};

export default withSession(PageLayout);
