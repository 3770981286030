import PropTypes from 'prop-types';
import {
  TaskEventCongratulation,
  TaskDailyLoginRewards,
  TaskBallotDrawReward,
  TaskBallotLoginReward,
  TaskCBDOffer,
} from '@/components/TaskModal';

const EventDispatcher = ({ event, onClose }) => {
  const handleOnClose = () => {
    onClose(event._id);
  };

  if (event.type === 'DAY_FIRST_LANDING_POPUP') {
    return <TaskDailyLoginRewards task={event} onClose={handleOnClose} />;
  }

  if (event.type === 'WON_BALLOT_DRAW') {
    return <TaskBallotDrawReward task={event} onClose={handleOnClose} />;
  }

  if (event.type === 'BALLOT_EARNED_LOGIN_REWARD') {
    return <TaskBallotLoginReward task={event} onClose={handleOnClose} />;
  }

  if (event.type === 'CBD_OFFER') {
    return <TaskCBDOffer task={event} onClose={handleOnClose} />;
  }

  return <TaskEventCongratulation task={event} onClose={handleOnClose} />;
};

EventDispatcher.propTypes = {
  event: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EventDispatcher;
