import { createSlice } from '@reduxjs/toolkit';

export const actionsSlice = createSlice({
  name: 'actions',
  initialState: {
    list: [],
    tutorialToShow: '',
  },
  reducers: {
    setTutorialToShow: (state, { payload }) => {
      state.tutorialToShow = payload;
    },
    setActions: (state, { payload }) => {
      state.list = payload;
    },
    removeAction: (state, { payload }) => {
      const clonedList = [...state.list];
      state.list = [];
      state.list = clonedList.filter((action) => action.id !== payload);
    },
  },
});

export const {
  setActions,
  setTutorialToShow,
  removeAction,
} = actionsSlice.actions;

export default actionsSlice.reducer;
