import axios from 'axios';
import qs from 'qs';
import { getToken, removeToken } from '@/utils/token';
import store from '@/store';
import {
  setLogoutSessionId,
  resetUser,
  setAsyncPoints,
} from '@/store/slices/userSlice';
import { addEvents } from '@/store/slices/eventsSlice';
import { setActions } from '@/store/slices/actionsSlice';
import { setIsFailed } from '@/store/slices/configSlice';

const axiosInstance = () => {
  const { user } = store.getState();
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL, // Add baseUrl from .env file
    headers: {
      'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
      Authorization: user.logoutSessionId,
    },
  });

  // Request interceptors can be used to append elements to the request,
  // like a JWT token
  instance.interceptors.request.use(
    (config) => {
      const token = getToken();
      if (token) {
        config.headers.Authorization = token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Response interceptors can be use to handle global errors, redirects etc.
  instance.interceptors.response.use(
    (response) => {
      const events =
        response.data?.events ||
        response.data?.data?.events ||
        response.data?.data?.payload?.user_events;
      const actions = response.data?.data?.payload?.user_actions;

      if (actions) {
        store.dispatch(setActions(actions));
      }

      if (events && events.length) {
        // 1. Sync user points if events come from the payload
        const payloadUserEvents = response.data?.data?.payload?.user_events;

        if (payloadUserEvents?.length) {
          const payloadUserPoints = response.data?.data?.payload?.user?.points;

          // Filter ballot events. TOASK: can we dissociate points and ballot count?!
          let payloadEventPoints = payloadUserEvents.filter(
            (event) =>
              ![
                'WON_BALLOT_DRAW',
                'DAY_FIRST_LANDING_POPUP',
                'BALLOT_EARNED_LOGIN_REWARD',
              ].includes(event.type)
          );

          payloadEventPoints = payloadEventPoints.reduce(
            (total, event) => total + event.points,
            0
          );

          if (payloadUserPoints && payloadEventPoints) {
            store.dispatch(
              setAsyncPoints(payloadUserPoints - payloadEventPoints)
            );
          }
        }

        // 2. Dispatch events
        store.dispatch(addEvents(events));
      }

      return response;
    },
    (error) => {
      const isLoginPage = window.location.pathname.includes('login');
      const errorStatus = error?.response?.status;
      const errorMsg = error?.response?.data?.errors?.[0]?.message;
      const token = error?.response?.data?.session_id;

      if (errorStatus === 500) {
        store.dispatch(setIsFailed(true));
        return;
      }

      // On 403, record logoutSessionId
      if ([403].includes(errorStatus)) {
        token && store.dispatch(setLogoutSessionId(token));

        if (errorMsg === 'Unauthorized') {
          removeToken();
          store.dispatch(resetUser());
        }

        return;
      }

      if ([401].includes(errorStatus)) {
        removeToken();
        store.dispatch(resetUser());
        token && store.dispatch(setLogoutSessionId(token));

        return;
      }

      if (errorStatus === 404) {
        window.location.href = '/not-found';
      }
      // Handle other status here or reject to allow local handling with
      // try / catch blocks for instance
      return Promise.reject(error);
    }
  );
  return instance;
};

export const post = async (url, formData = {}, params = {}, isJSON = false) => {
  const { user } = store.getState();

  const postParams = {
    ...params,
  };

  const resp = isJSON
    ? await axiosInstance().post(url, formData, {
        params: postParams,
        headers: {
          'content-type': 'application/json',
        },
      })
    : await axiosInstance().post(url, qs.stringify(formData), {
        params: postParams,
      });

  return resp;
};

export const get = async (url, urlParams) => {
  const { user } = store.getState();

  const params = {
    ...urlParams,
  };

  if (user.userId) {
    params.user_id = user.userId;
  }

  const resp = await axiosInstance().get(url, { params });

  return resp;
};

export default axiosInstance;
