import React, { useState, useCallback, useEffect, useRef } from 'react';

const useIntersectionObserver = (
  rootMargin = '20px',
  threshold = 0,
  useWindow = true
) => {
  const ref = useRef();
  const rootRef = useRef();
  const observerRef = useRef();

  const [isIntersecting, setIsIntersecting] = useState(false);

  const observe = useCallback(() => {
    const observableEl = ref.current;

    if (observableEl) {
      const root = useWindow ? null : rootRef.current;
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsIntersecting(entry.isIntersecting);
        },
        { root, rootMargin, threshold }
      );
      observer.observe(observableEl);
      observerRef.current = observer;
    }
  }, [rootMargin, threshold]);

  const unobserve = useCallback(() => {
    observerRef?.current?.disconnect();
    observerRef.current = null;
  }, []);

  const initializeObserver = useCallback(() => {
    unobserve();
    observe();
  }, [observe, unobserve]);

  const refCallback = useCallback(
    (el) => {
      ref.current = el;
      initializeObserver();
    },
    [initializeObserver]
  );

  const rootRefCallback = useCallback(
    (rootEl) => {
      rootRef.current = rootEl;
      initializeObserver();
    },
    [initializeObserver]
  );

  useEffect(() => {
    return () => unobserve();
  }, [unobserve]);

  return [refCallback, isIntersecting, rootRefCallback];
};

export default useIntersectionObserver;
