import PropTypes from 'prop-types';

const WhyItem = ({
  children,
  title,
  content,
  iconStyle = '',
  className = '',
}) => {
  return (
    <div className={`px-3 flex md:flex-col flex-row ${className}`}>
      <div
        className={`px-3  rounded-2xl md:rounded-4xl w-16 h-16 md:w-24 md:h-24 flex items-center justify-center ${iconStyle}`}
      >
        {children}
      </div>
      <div className="flex-1 md:ml-0 ml-4">
        <h3
          className="text-xl mb-2 md:mt-4"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <p>{content}</p>
      </div>
    </div>
  );
};

WhyItem.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  iconStyle: PropTypes.string,
  className: PropTypes.string,
};

export default WhyItem;
