import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addEvent } from '@/store/slices/eventsSlice';

const useCbdOffer = () => {
  const [hasBeenAdded, setHasBeenAdded] = useState(false);

  const dispatch = useDispatch();
  const { userId } = useSelector((state) => state.user);
  const { cbdOffer } = useSelector((state) => state.config);
  const dashboardRef = useRef();

  const type = cbdOffer?.type;
  const url = cbdOffer?.url;

  const popOverHandler = (e) => {
    if (!url) return;
    window.open(url, '_blank');
    setTimeLastShown();
    // We only want this click event to happen once, so remove it now that they've clicked
    dashboardRef.current.removeEventListener('click', popOverHandler);
  };

  const popUnderHandler = () => {
    if (!url) return;
    const popUnder = window.open(
      url,
      '_blank',
      'width= 800, height= 510, left=0, top=0, resizable=yes, toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=no, copyhistory=no'
    );
    popUnder.blur();
    window.focus();
    setTimeLastShown();
    dashboardRef.current.removeEventListener('click', popUnderHandler);
  };

  const setTimeLastShown = () => {
    const millisCurrent = Date.now();
    localStorage.setItem('cbd_offer_last_shown_time', millisCurrent);
  };

  const shouldOfferShow = (lastShownTimeMs) => {
    const millisCurrent = Date.now();
    const millisPerDay = 1 * 60 * 60 * 24 * 1000;

    // If an offer was not previously shown (i.e., the is no stored timestamp), OR, the stored timestamp is more than 24 hours ago
    if (
      !lastShownTimeMs ||
      millisCurrent - Number(lastShownTimeMs) > millisPerDay
    ) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (!userId || !type || !url) {
      return;
    }

    /* 
    Note: This goes in it's own separate if() statement because it also sets the `cbd_offer_last_shown_time` value.
    If it were to run and the `cbd_offer_last_shown_time` while !userID (above), shouldOfferShow() would be `true` the first time, then would always return false after that, 
    and the cbd offer code below would never run.
    */

    const cbdOfferLastShownTime = localStorage.getItem(
      'cbd_offer_last_shown_time'
    );

    if (!shouldOfferShow(cbdOfferLastShownTime)) {
      return;
    }

    if (!hasBeenAdded) {
      if (type === 'pop-up') {
        dispatch(
          addEvent({
            type: 'CBD_OFFER',
            url: url,
          })
        );
        // Since the offer has been shown, set the time that it was shown
        setTimeLastShown();
      }

      if (!dashboardRef.current) {
        return;
      }

      if (type === 'pop-over') {
        dashboardRef.current.addEventListener('click', popOverHandler);
      }

      if (type === 'pop-under') {
        dashboardRef.current.addEventListener('click', popUnderHandler);
      }

      setHasBeenAdded(true);
    }
  }, [type, url, hasBeenAdded, userId, dashboardRef]);

  return dashboardRef;
};

export default useCbdOffer;
