import PropTypes from 'prop-types';
import Rating from './Rating';

const Testimonial = ({ data }) => {
  const { testimonial, date, name, city, country, rating } = data;

  const niceDate = () => {
    const formattableDate = new Date(date);

    return formattableDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  const niceTime = () => {
    const formattableDate = new Date(date);

    return formattableDate.toLocaleTimeString('en-US', {
      hour12: true,
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  return (
    <article className="rounded-lg bg-white p-6">
      <header className="mb-4">
        <div className="font-bold">{name}</div>
        <div className="text-gray-400 text-sm mb-2">
          {city}, {country}
        </div>
        <Rating rating={rating} />
      </header>
      <main>
        <p>{testimonial}</p>
        <time dateTime={date} className="mt-3 text-gray-400 text-xs block">
          {niceDate()} • {niceTime()}
        </time>
      </main>
    </article>
  );
};

Testimonial.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Testimonial;
