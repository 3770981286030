import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { logAnalyticEvent } from '@/config/analytics';
import { setTutorialToShow } from '@/store/slices/actionsSlice';
import Modal from '@/components/Modal';
import Button from '@/components/Button';
import { TaskContainer, TaskDetailHeader } from '@/components/TaskModal';

const ReferralModal = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(true);

  const handleOnClose = () => {
    dispatch(setTutorialToShow('GoalReferral'));
    setShowModal(false);
    logAnalyticEvent('referral-popup', 'accept');
  };

  useEffect(() => {
    logAnalyticEvent('referral-popup', 'impression');
  }, []);

  const task = {
    name: 'Invite a friend!',
    design_class: 'Welcome',
  };

  if (!showModal) return <></>;

  return (
    <Modal onClose={handleOnClose}>
      {(onClose) => (
        <TaskContainer>
          <TaskDetailHeader task={task} />
          <section className="mt-4">
            <p className="px-5 md:px-8 md:text-lg text-center">
              You are now able to invite your friends to redeem points!
            </p>
          </section>

          <footer className="p-5 pt-8 md:p-8">
            <Button onClick={onClose} variant="success" className="w-full">
              Show me!
            </Button>
          </footer>
        </TaskContainer>
      )}
    </Modal>
  );
};

export default ReferralModal;
