import PropTypes from 'prop-types';

const ListItem = ({ label, className = '' }) => (
  <li className={`flex items-start mb-4 ${className}`}>
    <span className="rounded-full p-0.5 bg-green-500 text-white mr-4">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-5 -7 24 24"
        width="18"
        fill="currentColor"
      >
        <path d="M5.486 9.73a.997.997 0 0 1-.707-.292L.537 5.195A1 1 0 1 1 1.95 3.78l3.535 3.535L11.85.952a1 1 0 0 1 1.415 1.414L6.193 9.438a.997.997 0 0 1-.707.292z" />
      </svg>
    </span>
    <span
      className="text-grey-900"
      dangerouslySetInnerHTML={{ __html: label }}
    />
  </li>
);

ListItem.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default ListItem;
