import { useState } from 'react';
import Button from '@/components/Button';
import FormItem from '@/components/FormItem';
import FormWrapper from '@/components/FormWrapper';
import GoogleRecaptcha from '@/components/GoogleRecaptcha';
import Alert from '@/components/Alert';
import { makeFieldErrors } from '@/utils/helpers';
import axios from 'axios';
import qs from 'qs';

const ContactForm = () => {
  const defaultValues = {
    first_name: '',
    last_name: '',
    phone_number: '',
    email: '',
    subject: '',
    message: '',
    'g-recaptcha-response': '',
  };

  const formAPIEndpoint = process.env.REACT_APP_CONTACT_FORM_API_ENDPOINT;

  const [formData, setFormData] = useState(defaultValues);
  const [formError, setFormDataError] = useState();
  const [formSuccess, setFormDataSuccess] = useState(false);
  const [fieldErrors, setFieldErrors] = useState(defaultValues);
  const [loading, setLoading] = useState(false);

  const handleOnChange = ({ target }) => {
    const { name, value } = target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleOnSubmit = async () => {
    setFormDataError();
    setFormDataSuccess(false);

    if (!formData['g-recaptcha-response']) {
      setFormDataError('Missing Recaptcha Response');
      return;
    }

    const requiredInputs = {
      first_name: '',
      last_name: '',
      email: '',
      subject: '',
      message: '',
    };

    let errors = {};

    for (const required in requiredInputs) {
      if (!formData[required]) {
        errors[required] = 'Please enter this field.';
      }
    }
    setFieldErrors(errors);
    if (Object.keys(errors).length !== 0) return;

    setLoading(true);

    try {
      await axios.post(formAPIEndpoint, qs.stringify(formData), {
        headers: {
          'content-type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
      });
      setFormDataSuccess(true);
    } catch (err) {
      const { fieldErrors, formError } = makeFieldErrors(
        err?.response?.data?.errors
      );

      if (fieldErrors) {
        setFieldErrors(fieldErrors);
      }

      if (formError) {
        setFormDataError(formError);
      }

      if (
        !formError &&
        (!fieldErrors || Object.keys(fieldErrors).length === 0)
      ) {
        setFormDataError(
          'Oops! Something went wrong. Please try again later or email us at panelmanager@communa.com'
        );
      }

      setLoading(false);
    }
  };

  if (formSuccess) {
    return <Alert>Your message has been successfully sent!</Alert>;
  }

  return (
    <div>
      <h2 className="mb-6">Say Hello! Don&apos;t be Shy</h2>
      <FormWrapper onSubmit={handleOnSubmit} error={formError}>
        <div className="grid sm:grid-cols-2 gap-4">
          <FormItem
            placeholder="First Name*"
            name="first_name"
            required
            onChange={(e) => handleOnChange(e)}
            error={fieldErrors?.first_name}
          />
          <FormItem
            placeholder="Last Name*"
            name="last_name"
            required
            onChange={(e) => handleOnChange(e)}
            error={fieldErrors?.last_name}
          />
          <FormItem
            placeholder="Phone Number"
            name="phone_number"
            onChange={(e) => handleOnChange(e)}
            error={fieldErrors?.phone_number}
          />
          <FormItem
            placeholder="Email Address*"
            type="email"
            name="email"
            required
            onChange={(e) => handleOnChange(e)}
            error={fieldErrors?.email}
          />
        </div>
        <div className="my-4">
          <FormItem
            placeholder="Subject*"
            name="subject"
            required
            onChange={(e) => handleOnChange(e)}
            error={fieldErrors?.subject}
          />
        </div>
        <div className="my-3">
          <FormItem
            type="textarea"
            name="message"
            placeholder="Enter your message here*"
            required
            onChange={(e) => handleOnChange(e)}
            error={fieldErrors?.message}
          />
        </div>
        <GoogleRecaptcha
          onChange={(token) =>
            setFormData({ ...formData, 'g-recaptcha-response': token })
          }
        />
        <div className="flex justify-end mt-3">
          <Button loading={loading} type="submit" variant="success">
            Submit
          </Button>
        </div>
      </FormWrapper>
    </div>
  );
};

export default ContactForm;
