import { useState } from 'react';
import PropTypes from 'prop-types';
import { setIsTaskOpen } from '@/store/slices/configSlice';
import { logAnalyticEvent } from '@/config/analytics';
import { taskType } from '@/utils/helpers';
import TaskModal from '@/components/TaskModal';
import Profiler from '@/components/Profiler';
import TaskTutorialInfo from '@/components/TaskTutorialInfo';
import TaskFooter from './components/TaskFooter';
import TaskPoints from './components/TaskPoints';
import TaskFeatured from './components/TaskFeatured';
import TaskHot from './components/TaskHot';
import TaskHeader from './components/TaskHeader';
import TaskLock from './components/TaskLock';
import { useDispatch } from 'react-redux';

const TaskCard = ({ task, category, showTutorial = false, onClose = null }) => {
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [showProfilerModal, setShowProfilerModal] = useState(false);

  const {
    is_title_visible,
    points,
    upto,
    length,
    name,
    featured,
    task_type,
    project_id,
    profiler_id,
    goal_id,
    task_class,
    offer_image,
    source,
    task_status,
    bonus_points,
    design_class,
    design_color,
    p_id,
    subtask_id,
    rfg_id,
    show_title_on_dashboard_card,
  } = task;
  const showTitle = show_title_on_dashboard_card === false ? false : true;
  const dispatch = useDispatch();
  const isLocked = [
    'phone_verification_required',
    'email_verification_required',
  ].includes(task_status);

  const taskId = project_id || profiler_id || goal_id;

  const isReferrerTask = source === 'GoalReferral';

  const onCardClick = () => {
    dispatch(setIsTaskOpen(true));
    setShowTaskModal(true);
    logAnalyticEvent('task-card', 'click', {
      task_id: taskId,
      subtask_id,
      task_title: name,
      task_type: taskType(taskId, task_type),
    });
    if (showTutorial) {
      const tutorial_id = isReferrerTask ? 'referrer' : 'ftue';
      const tutorial_title = isReferrerTask
        ? 'Invite a friend'
        : 'First Time User';

      logAnalyticEvent('tutorial ', 'end', {
        tutorial_id,
        tutorial_title,
      });
    }
  };

  const handleModalClose = () => {
    dispatch(setIsTaskOpen(false));
    setShowTaskModal(false);
    onClose && onClose();
  };

  // TODO card color should not be hardcoded
  const isPeanutLabs =
    !['featured', 'sponsored', 'trending_surveys'].includes(category) &&
    (task_type === 'hot_surveys' || design_class === 'PeanutLabs');

  return (
    <div className="relative">
      {showTutorial && <TaskTutorialInfo source={source} />}
      <article
        onClick={onCardClick}
        role="presentation"
        className={`task-single ${design_color || 'card_base'} ${
          isPeanutLabs ? 'dark_blue' : ''
        } ${design_class} ${
          showTutorial ? 'relative z-200' : ''
        } overflow-hidden h-32 flex flex-col rounded-md w-full shadow-md hover:scale-105 transform transition-all bg-white duration-200 cursor-pointer`}
      >
        {offer_image && !isPeanutLabs && (
          <img
            className="w-24 absolute right-0 top-4"
            src={offer_image}
            alt={`${name}`}
          />
        )}
        {isLocked && <TaskLock />}
        {featured && <TaskFeatured />}
        {bonus_points && !featured && <TaskHot />}
        <TaskHeader task={task} category={category}>
          <TaskPoints points={points} upto={upto} bonusPoints={bonus_points} />
          {showTitle === true && (
            <div
              className={`font-bold text-white leading-tight ${
                offer_image && showTitle
                  ? 'max-w-13 md:max-w-9 lg:max-w-9.5 xl:max-w-8 2xl:max-w-11.5'
                  : ''
              } ${name.length >= 50 ? 'text-sm' : 'text-sm'}`}
            >
              {name}
            </div>
          )}
        </TaskHeader>
        {task.length && (
          <TaskFooter
            bonusPoints={bonus_points}
            length={length}
            featured={featured}
          />
        )}
      </article>
      {showTaskModal && (
        <TaskModal
          taskId={taskId}
          pId={p_id}
          subtaskId={subtask_id}
          rfgId={rfg_id}
          source={task_class || source}
          onClose={handleModalClose}
          onToggleProfiler={() => setShowProfilerModal(true)}
        />
      )}
      {showProfilerModal && (
        <Profiler
          surveyId={profiler_id}
          title={name}
          onClose={() => setShowProfilerModal(false)}
        />
      )}
    </div>
  );
};

TaskCard.propTypes = {
  task: PropTypes.object.isRequired,
  showTutorial: PropTypes.bool,
  category: PropTypes.string,
  onClose: PropTypes.func,
};

export default TaskCard;
