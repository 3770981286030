import actionsSlice from './actionsSlice';
import configSlice from './configSlice';
import eventsSlice from './eventsSlice';
import featureFlagsSlice from './featureFlagsSlice';
import userSlice from './userSlice';
import goalsSlice from './goalsSlice';
import tasksSlice from './tasksSlice';

export default {
  actions: actionsSlice,
  config: configSlice,
  events: eventsSlice,
  flags: featureFlagsSlice,
  user: userSlice,
  goals: goalsSlice,
  tasks: tasksSlice,
};
