import PropTypes from 'prop-types';

const SkeletonLoading = ({ hasTitle = false, isNav = true }) => (
  <div className="animate-pulse md:block hidden py-2 px-3.5">
    {hasTitle && <div className="h-4 w-24 mb-6 rounded bg-grey-260" />}
    {Array.from(Array(5).keys()).map((i) => (
      <div key={`sk-${i}`} className="flex items-center mb-4">
        {isNav && <div className="w-5 mr-2 h-5 rounded-lg bg-grey-260" />}
        <div
          className={`${
            isNav
              ? `${i % 2 === 0 ? 'w-24' : 'w-20'}`
              : `${i % 2 === 0 ? 'w-28' : 'w-32'}`
          } h-4 rounded bg-grey-260`}
        />
      </div>
    ))}
  </div>
);

SkeletonLoading.propTypes = {
  hasTitle: PropTypes.bool,
  isNav: PropTypes.bool,
};

export default SkeletonLoading;
