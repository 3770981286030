import PropTypes from 'prop-types';
import Modal from '@/components/Modal';
import { TaskNotEnoughSurveys } from '@/components/TaskModal';

const NotEnoughSurveysModal = ({
  minimumSurveys,
  surveysRequired,
  onClose,
}) => {
  return (
    <Modal onClose={onClose}>
      {(onClose) => (
        <TaskNotEnoughSurveys
          minimumSurveys={minimumSurveys}
          surveysRequired={surveysRequired}
          onClose={onClose}
        />
      )}
    </Modal>
  );
};

NotEnoughSurveysModal.propTypes = {
  minimumSurveys: PropTypes.number.isRequired,
  surveysRequired: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NotEnoughSurveysModal;
