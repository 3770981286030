import AnimatedSection from '@/components/AnimatedSection';
import ListItem from '@/components/ListItem';
import Illustration from './components/Illustration';

const SignupV2HowItWorks = () => {
  return (
    <AnimatedSection>
      {(animate) => (
        <div className="max-w-6xl flex flex-col md:flex-row items-center mx-auto py-20 md:py-24 px-5">
          <section
            className={`md:w-1/2 flex justify-end md:pr-12 anim ${
              animate ? 'anim1-5' : ''
            }`}
          >
            <Illustration />
          </section>
          <section className="md:w-1/2 mt-8 md:mt-0">
            <h2
              className={`text-2xl md:text-3xl text-center md:text-left  anim ${
                animate ? 'anim0' : ''
              }`}
            >
              So.. how does it work?
            </h2>
            <p
              className={`text-center md:text-left text-lg leading-relaxed mt-4 md:mt-8  anim ${
                animate ? 'anim1' : ''
              }`}
            >
              Joining Communa is easy:
            </p>
            <ol className="mt-6">
              <ListItem
                label="Fill out our sign up form and confirm your membership"
                className={`anim ${animate ? 'anim1-5' : ''}`}
              />
              <ListItem
                label="Choose any available surveys"
                className={`anim ${animate ? 'anim1-5' : ''}`}
              />
              <ListItem
                label="Complete surveys and receive points"
                className={`anim ${animate ? 'anim2' : ''}`}
              />
              <ListItem
                label="Redeem points through our rewards page"
                className={`anim ${animate ? 'anim2' : ''}`}
              />
              <ListItem
                label="Enjoy your gift card and keep earning!"
                className={`anim ${animate ? 'anim2-5' : ''}`}
              />
            </ol>
          </section>
        </div>
      )}
    </AnimatedSection>
  );
};

export default SignupV2HowItWorks;
