import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const AnalyticsPageViews = () => {
  const location = useLocation();

  useEffect(() => {
    // GA
    ReactGA.send({
      hitType: window.location.pathname + window.location.search,
      page: location.pathname,
    });
  }, [location.pathname]);

  return <></>;
};

export default AnalyticsPageViews;
