import { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

const TaskDetailHeader = ({ task, onClose }) => {
  const {
    design_class,
    design_color,
    offer_image,
    task_type,
    name,
    clients,
    show_title_in_popup,
  } = task;

  const isPeanutLabApi =
    task_type === 'peanut_labs_api' || clients === 'PeanutLabs';
  const [imgPath, setImgPath] = useState('');
  const showTitle = show_title_in_popup === false ? false : true;

  const imgEl = [
    'Cactus',
    'PeanutLabs',
    'Cint',
    'SaySoLiveAlertAPI',
    'CintAPI',
    'PeanutLabsAPI',
    'PeanutLabsRouter',
  ].includes(design_class)
    ? 'SurveyGeneric'
    : design_class;

  const taskImgPath = `/img/task/i_c_dkmb_${imgEl}.svg`;
  const genericImgPath = '/img/task/i_c_dkmb_SurveyGeneric.svg';

  const designColor = () => {
    if (design_class === 'GoalVerifyPhone') {
      return 'winter_sky_blue';
    }
    if (design_class === 'GoalVerifyEmail') {
      return 'dark_blue';
    }
    return design_color || 'card_base';
  };

  const setImg = async () => {
    // If the offer image is defined, use it
    if (offer_image) {
      setImgPath(offer_image);
      return;
    }

    try {
      let result = await axios.get(taskImgPath);
      if ((result.headers['content-type'] ?? null) === 'text/html') {
        throw 'image not found';
      } else {
        setImgPath(taskImgPath);
      }
      // Otherwise, use the generic survey image.
    } catch (err) {
      setImgPath(genericImgPath);
    }
  };

  useEffect(() => {
    setImg();
  }, []);

  return (
    <header>
      <section className="relative h-40">
        {onClose && (
          <button
            className="hover:opacity-100 opacity-75 duration-200 transition-opacity absolute top-4 right-4 z-20"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              viewBox="0 0 32 32"
            >
              <polygon
                fill="#FFF"
                points="32 3.8 28.2 0 16 12.2 3.8 0 0 3.8 12.2 16 0 28.2 3.8 32 16 19.8 28.2 32 32 28.2 19.8 16"
              />
            </svg>
          </button>
        )}

        {imgPath && (
          <img
            className="z-10 absolute left-1/2 transform -translate-x-1/2 top-4 max-h-44"
            src={imgPath}
            alt={`${name}`}
          />
        )}
        <div
          className={`task-single ${designColor()} ${
            isPeanutLabApi ? 'dark_blue' : ''
          } rounded-t relative h-40`}
          style={{ clipPath: 'polygon(0 0, 101% 0, 101% 80%, 0% 100%)' }}
        />
      </section>
      {name && showTitle === true && (
        <h3 className="mt-12 px-4 md:px-8 text-center text-xl md:text-2xl">
          {name}
        </h3>
      )}
    </header>
  );
};

TaskDetailHeader.propTypes = {
  task: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

export default TaskDetailHeader;
