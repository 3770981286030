import TaskModal from './TaskModal';

export { default as TaskDetailHeader } from './components/TaskDetailHeader';
export { default as TaskEventCongratulation } from './components/TaskEventCongratulation';
export { default as TaskDailyLoginRewards } from './components/TaskDailyLoginRewards';
export { default as TaskWelcome } from './components/TaskWelcome';
export { default as TaskVerifyPhone } from './components/TaskVerifyPhone';
export { default as Task30DayHold } from './components/Task30DayHold';
export { default as TaskRedeemReward } from './components/TaskRedeemReward';
export { default as TaskSomethingWrong } from './components/TaskSomethingWrong';
export { default as TaskNotEnoughPoints } from './components/TaskNotEnoughPoints';
export { default as TaskNotEnoughSurveys } from './components/TaskNotEnoughSurveys';
export { default as TaskBallotDrawReward } from './components/TaskBallotDrawReward';
export { default as TaskBallotLoginReward } from './components/TaskBallotLoginReward';
export { default as TaskVerifyEmail } from './components/TaskVerifyEmail';
export { default as TaskContainer } from './components/TaskContainer';
export { default as TaskCBDOffer } from './components/TaskCBDOffer';

export default TaskModal;
