import AnimatedSection from '@/components/AnimatedSection';
import ListItem from '@/components/ListItem';

const LeftSection = () => {
  return (
    <section className="md:block hidden w-1/2 px-8 py-12 bg-gray-50">
      <div className="px-4 anim anim1">
        <svg viewBox="0 0 724 724" xmlns="http://www.w3.org/2000/svg">
          <title>ModalVectorArt</title>
          <g
            id="ModalVectorArt"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="table_coffee"
              transform="translate(120.000000, 112.000000)"
              fillRule="nonzero"
            >
              <g id="chair" transform="translate(0.000000, 150.827187)">
                <path
                  d="M44.3507849,225.784217 C44.3507849,225.784217 21.4015803,342.82516 23.0129075,348.489219 C24.6730627,354.10445 57.3878863,228.27445 57.3878863,228.27445 L44.3507849,225.784217 Z"
                  id="Path"
                  fill="#000000"
                />
                <path
                  d="M79.360529,236.282257 C79.360529,236.282257 79.7511538,344.973597 82.5343552,348.586876 C85.3663847,352.200155 92.202318,237.307647 92.202318,237.307647 L79.360529,236.282257 Z"
                  id="Path"
                  fill="#000000"
                />
                <path
                  d="M139.516742,235.891633 C139.516742,235.891633 121.010894,345.559534 124.624173,348.293907 C128.237452,351.02828 151.186657,235.403352 151.186657,235.403352 L139.516742,235.891633 Z"
                  id="Path"
                  fill="#000000"
                />
                <path
                  d="M183.071403,236.184601 C183.071403,236.184601 220.473723,347.854454 227.309657,348.635704 C234.14559,349.416953 196.010848,235.012727 196.010848,235.012727 L183.071403,236.184601 Z"
                  id="Path"
                  fill="#000000"
                />
                <path
                  d="M58.9015572,2.20037027 C58.9015572,2.20037027 5.58127756,-4.58673493 3.13987282,18.2159854 C0.698468071,41.0187057 -5.06324713,210.501023 31.020715,228.616246 C67.1046772,246.73147 196.792097,236.47757 212.465916,232.864291 C228.139734,229.251012 224.526455,174.368233 224.526455,174.368233 L90.0050537,156.25301 L73.3058452,7.22966404 L58.9015572,2.20037027 L58.9015572,2.20037027 Z"
                  id="Path"
                  fill="#EAEFFA"
                />
                <path
                  d="M30.4347779,229.739293 C14.7121313,221.877969 4.94651233,186.721741 1.3820614,125.247169 C-1.35231192,78.1280579 0.600811881,30.1788687 1.87034235,18.0695011 C2.3097952,13.9679411 4.262919,10.5011464 7.72971374,7.76677309 C22.0363456,-3.56134494 57.4855425,0.784355512 58.9503853,0.979667892 L74.3800633,6.30193024 L91.0304437,155.129964 L224.624111,173.098702 L225.600673,173.245187 L225.649501,174.270577 C225.795986,176.565497 229.165124,230.276402 212.6124,234.084993 C204.506936,235.940461 158.755011,240.286161 113.979648,240.286161 C79.9952942,240.237333 46.4015649,237.698272 30.4347779,229.739293 Z M88.8820075,157.327228 L72.182799,8.15739785 L58.6085887,3.42107264 C56.2648401,3.12810407 22.4269703,-0.63165924 9.34104087,9.71989688 C6.36252708,12.0636454 4.75119995,14.8956749 4.36057519,18.3624697 C3.09104472,30.374181 1.13792092,78.176886 3.87229424,125.100685 C7.33908898,184.768617 16.9093956,220.168986 31.5578241,227.4932 C67.9347548,245.754908 198.0128,234.915071 212.172947,231.643588 C223.940518,228.909215 224.282315,191.067441 223.354581,175.393623 L88.8820075,157.327228 Z"
                  id="Shape"
                  fill="#000000"
                />
                <path
                  d="M29.3117317,54.4952599 C29.1652474,53.7628385 29.6535284,53.0792452 30.3859498,52.9327609 C31.1183712,52.7862766 31.8019645,53.2745576 31.9484488,54.006979 C32.1437612,54.9347128 49.038282,148.04989 48.3058606,184.426821 L102.895671,184.426821 C103.628092,184.426821 104.214029,185.012758 104.214029,185.745179 C104.214029,186.477601 103.628092,187.063538 102.895671,187.063538 L35.122275,187.063538 C34.3898536,187.063538 33.8039164,186.477601 33.8039164,185.745179 C33.8039164,185.012758 34.3898536,184.426821 35.122275,184.426821 L45.6203154,184.426821 C46.3527368,148.440515 29.458216,55.4718218 29.3117317,54.4952599 Z"
                  id="Path"
                  fill="#000000"
                />
              </g>
              <g id="table" transform="translate(171.547972, 208.203305)">
                <path
                  d="M144.82413,263.23226 L153.027249,263.23226 L153.027249,74.4628448 C145.800691,72.4608929 140.380773,64.7460539 140.380773,55.5175439 L172.949112,55.5175439 C172.949112,64.7460539 167.529194,72.509721 160.302636,74.4628448 L160.302636,263.281088 L166.8456,263.281088 C180.370983,263.281088 191.308476,274.218581 191.308476,287.743963 L191.308476,291.796695 L120.361254,291.796695 L120.361254,287.743963 C120.361254,274.218581 131.298747,263.23226 144.82413,263.23226 Z"
                  id="Path"
                  fill="#000000"
                />
                <ellipse
                  id="Oval"
                  fill="#FFFFFF"
                  cx="156.689357"
                  cy="28.3691232"
                  rx="155.419826"
                  ry="27.0995927"
                />
                <path
                  d="M-2.77555585e-14,28.3691232 C-2.77555585e-14,10.5956966 79.6386228,0 156.640529,0 C233.642434,0 313.281057,10.5956966 313.281057,28.3691232 C313.281057,46.1425497 233.642434,56.7382463 156.640529,56.7382463 C79.6874509,56.7382463 -2.77555585e-14,46.0937216 -2.77555585e-14,28.3691232 Z M2.49023284,28.3691232 C2.49023284,40.8691155 64.4530853,54.2480135 156.689357,54.2480135 C248.925628,54.2480135 310.88848,40.8691155 310.88848,28.3691232 C310.88848,15.8691309 248.925628,2.49023284 156.689357,2.49023284 C64.4530853,2.49023284 2.49023284,15.8691309 2.49023284,28.3691232 Z"
                  id="Shape"
                  fill="#000000"
                />
              </g>
              <g id="person" transform="translate(54.660181, 0.000000)">
                <path
                  d="M99.2120205,1.41632273 C99.2120205,1.41632273 119.231539,3.613587 130.803798,22.5588878 C142.376056,41.5041887 140.960042,44.5803586 135.979576,56.6408981 C130.99911,68.7014375 128.313565,75.1955742 136.516685,82.4709603 C141.790119,87.1096293 134.85653,105.224853 131.633876,108.935788 L34.4659666,84.2775998 C35.8331533,79.1506498 30.4132348,64.6975338 34.6124509,58.8869905 C42.2296337,48.340122 47.9425208,49.0725434 47.4542399,36.4260668 C47.1612713,29.6389616 56.780406,-7.66570293 99.2120205,1.41632273 Z"
                  id="Path"
                  fill="#000000"
                />
                <path
                  d="M72.2589121,57.5686319 C72.2589121,57.5686319 67.4737588,93.8967345 66.546025,96.0939988 C64.2511045,101.758058 61.7608717,113.135004 58.3917331,113.525629 C55.0225946,113.916253 91.8878063,136.230693 91.8878063,136.230693 L100.23741,129.736556 C100.23741,129.736556 103.753033,110.400631 100.481551,105.859618 C97.2588967,101.318605 100.969832,63.818628 100.969832,63.818628 L72.2589121,57.5686319 Z"
                  id="Path"
                  fill="#FFD599"
                />
                <path
                  d="M91.3018691,137.304911 C91.2042129,137.256083 82.6592963,132.080305 74.260864,126.709214 C56.5850936,115.429924 56.780406,114.551019 57.0245465,113.427972 C57.1222027,112.939692 57.5128274,112.402582 58.294077,112.353754 C59.9542322,112.158442 62.2491526,105.127196 63.469855,101.318605 C64.1534483,99.2189969 64.7882136,97.2658731 65.4229788,95.6545459 C66.057744,93.9943907 69.1827421,71.2893266 71.0382097,57.4221476 L71.2335221,56.103789 L102.385847,62.9397223 L102.28819,64.0139404 C100.872176,78.3205722 99.4073329,102.197511 101.555769,105.224853 C105.022564,110.058834 101.848738,127.978745 101.506941,130.029525 L101.409285,130.517806 L91.9854625,137.793192 L91.3018691,137.304911 Z M99.5538172,106.640867 C96.379991,102.197511 98.9190519,73.0959661 99.7003015,64.844018 L73.2843021,59.0823028 C72.210084,67.383079 68.5479769,94.4826717 67.6690712,96.5822797 C67.083134,98.0959507 66.4483688,100.049074 65.7647755,102.099854 C63.8116517,108.154538 62.2491526,112.549067 60.1007165,114.160394 C63.7139955,117.285392 78.2647678,126.611558 91.7901501,134.814678 L99.1143643,129.150619 C100.432723,121.777577 101.653425,109.570553 99.5538172,106.640867 Z"
                  id="Shape"
                  fill="#000000"
                />
                <polygon
                  id="Path"
                  fill="#FFD599"
                  points="122.893647 160.302944 153.069409 203.46698 189.885793 225.24431 179.778377 230.664228 143.010822 219.09197 99.6514734 172.216999"
                />
                <path
                  d="M142.669025,220.263844 L142.376056,220.166188 L97.6495215,171.875202 L123.284271,158.740445 L153.948315,202.539246 L192.376026,225.293138 L179.924861,231.982587 L142.669025,220.263844 Z M152.239332,204.394713 L122.503022,161.914271 L101.702253,172.607624 L143.743243,218.017752 L179.729549,229.34587 L187.39556,225.195482 L152.239332,204.394713 Z"
                  id="Shape"
                  fill="#000000"
                />
                <path
                  d="M81.8780468,265.283348 C81.8780468,265.283348 165.520573,269.384908 167.961978,291.308722 C170.354555,313.232537 106.047954,481.005871 106.047954,481.005871 L83.6358582,481.005871 C83.6358582,481.005871 110.833107,340.380958 109.417092,332.226666 C103.753033,299.951295 47.2100994,263.867333 47.2100994,263.867333 L81.8780468,265.283348 Z"
                  id="Path"
                  fill="#FFD599"
                />
                <path
                  d="M82.4151558,480.810559 C89.9835106,441.748083 109.319436,338.964943 108.147562,332.470806 C102.629987,301.123169 47.0636151,265.283348 46.5265061,264.941551 L42.6690866,262.500146 L81.8780468,264.111473 L81.8780468,264.111473 C82.0733592,264.111473 103.118268,265.185692 124.163177,268.945455 C152.922925,274.072405 168.059634,281.543103 169.133852,291.259894 C171.575257,313.232537 109.758889,474.658219 107.171,481.54298 L106.878031,482.32423 L82.1221873,482.32423 L82.4151558,480.810559 Z M166.741276,291.455207 C164.83698,274.316545 104.827251,267.627096 81.8292187,266.50405 L51.5557999,265.283348 C64.3975888,274.023577 105.706157,304.101683 110.637795,332.031353 C112.004981,339.941505 88.2256991,463.964866 85.1495292,479.785169 L105.217876,479.785169 C109.954201,467.285176 168.987368,312.109491 166.741276,291.455207 Z"
                  id="Shape"
                  fill="#000000"
                />
                <path
                  d="M5.75504682,264.404442 C5.75504682,264.404442 -7.67267929,335.20518 56.5850936,339.55088 C121.477632,343.945409 142.864337,322.9005 157.024485,334.033305 C161.174873,337.304788 208.586953,483.886729 208.586953,483.886729 L229.143581,479.638684 C229.143581,479.638684 199.846724,299.170046 184.954155,289.746223 C170.061586,280.273573 148.040115,266.308738 89.2999172,265.039207 C30.6085471,263.672021 5.75504682,264.404442 5.75504682,264.404442 Z"
                  id="Path"
                  fill="#FFD599"
                />
                <path
                  d="M207.415079,484.228525 C188.66509,426.269577 159.563546,338.671974 156.292063,334.961039 C150.188552,330.127058 142.2784,331.884869 129.192471,334.765727 C114.153418,338.086037 91.3995253,343.115331 56.5362655,340.722754 C38.4210423,339.502052 24.7003476,332.959087 15.618322,321.240344 C-1.7644798,298.730593 4.29020397,265.527488 4.58317254,264.111473 L4.77848492,263.134912 L5.75504682,263.086083 C5.99918729,263.086083 31.2921405,262.40249 89.3487453,263.672021 C148.479568,264.990379 170.842836,279.199355 185.637749,288.574349 C188.030325,290.08802 195.403368,294.775517 213.860387,387.890694 C222.942413,433.691447 230.315455,478.906263 230.364283,479.345716 L230.559596,480.51759 L207.756876,485.253915 L207.415079,484.228525 Z M184.270562,290.722785 C169.71979,281.445447 147.698319,267.480612 89.2510891,266.211082 C37.6886209,265.088035 12.005043,265.47866 6.78043681,265.625144 C5.9015311,271.289203 2.43473636,300.244264 17.5226177,319.775502 C26.1163624,330.908307 39.2511199,337.158303 56.6339217,338.330178 C91.1065567,340.673926 113.665137,335.693461 128.557706,332.421978 C142.376056,329.394636 150.676833,327.539169 157.708078,333.056743 C158.586984,333.740337 160.588936,335.302836 185.442436,409.765681 C196.526414,442.919957 207.317423,476.17189 209.319375,482.421886 L227.62991,478.613294 C219.42679,427.73442 195.989305,298.193484 184.270562,290.722785 Z"
                  id="Shape"
                  fill="#000000"
                />
                <path
                  d="M104.046002,111.279536 C104.046002,111.279536 117.131931,144.140844 132.219813,162.793176 L104.631939,188.867379 C90.715932,182.129102 58.0011084,155.224822 72.1124278,141.455299 C84.026483,129.785384 104.046002,111.279536 104.046002,111.279536 Z"
                  id="Path"
                  fill="#C5CFE8"
                />
                <path
                  d="M104.827251,190.332222 L133.879968,162.842005 L133.098718,161.963099 C116.204198,143.115454 105.315532,111.18188 105.169048,110.888912 C104.973736,110.254146 104.290142,109.91235 103.655377,110.15649 C103.020612,110.351803 102.678815,111.035396 102.922956,111.670161 C103.020612,112.011958 113.762793,143.408423 130.559657,162.744348 L104.436627,187.451364 C93.3526491,181.494337 78.7530487,165.820518 72.8448492,140.429909 C72.698365,139.795144 72.0635997,139.404519 71.4288345,139.551003 C70.7940693,139.697488 70.4034445,140.332253 70.5499288,140.967018 C76.7999249,167.773642 92.5713996,184.131054 104.143658,189.990425 L104.827251,190.332222 Z"
                  id="Path"
                  fill="#000000"
                />
                <path
                  d="M15.4718377,200.146669 C7.70817061,235.351726 5.31559396,306.640744 5.31559396,306.640744 L95.0616324,308.398556 C95.0616324,308.398556 119.915133,110.058834 102.190534,106.299071 C94.7198358,104.687743 104.143658,132.129133 66.4971969,103.369385 C48.9190827,89.8928307 23.1866767,164.990441 15.4718377,200.146669 Z"
                  id="Path"
                  fill="#C5CFE8"
                />
                <path
                  d="M4.09489159,307.812618 L4.14371968,306.591916 C4.14371968,305.859495 6.63395253,234.765788 14.2999634,199.902529 C20.9405843,169.62911 41.5460404,107.568601 59.0264984,101.123293 C61.9561841,100.049074 64.7393855,100.488527 67.2296183,102.392823 C79.9737511,112.109614 88.6163239,116.406486 93.0596805,115.136956 C95.5010853,114.453362 96.4288191,112.011958 97.3077248,109.717037 C98.1866305,107.373289 99.3096767,104.443603 102.483503,105.127196 C105.754985,105.81079 109.563577,110.351803 109.807717,139.453347 C109.954201,156.396696 108.831155,179.736525 106.536235,208.83807 C102.581159,258.349758 96.379991,308.056759 96.3311629,308.54504 L96.1846786,309.619258 L4.09489159,307.812618 Z M99.5049891,110.547115 C98.5284272,113.135004 97.3077248,116.406486 93.6944458,117.431876 C88.3721834,118.945547 79.4854702,114.795159 65.7647755,104.297119 C63.9093079,102.881104 61.9561841,102.588135 59.8077479,103.369385 C43.938617,109.179928 23.5284734,168.701376 16.5460558,200.39081 C9.46598203,232.568524 6.82926491,295.898563 6.48746824,305.46887 L93.9385862,307.177853 C94.8663201,299.707155 100.383895,254.101714 103.997174,208.642758 C111.126076,119.238516 104.87608,108.056882 101.848738,107.422117 C101.751081,107.373289 101.604597,107.373289 101.506941,107.373289 C100.872176,107.422117 100.432723,108.10571 99.5049891,110.547115 Z"
                  id="Shape"
                  fill="#000000"
                />
                <path
                  d="M67.1807902,204.687682 C66.3018845,208.691586 16.0089468,189.697457 14.592932,194.043157 C5.99918729,220.556813 2.72770493,285.98646 2.72770493,285.98646 L97.6006934,310.742304 C97.6006934,310.742304 121.477632,155.859587 110.833107,151.220918 C104.192486,148.34006 97.5518653,148.34006 89.5928858,144.629125 C69.5245388,135.205303 74.8956292,169.482625 67.1807902,204.687682 Z"
                  id="Path"
                  fill="#423CB3"
                />
                <path
                  d="M200.72563,236.425944 C190.764698,235.547038 133.489343,223.779467 133.489343,223.779467 L77.8253149,191.064643 L93.9874143,162.988489 L146.331132,205.078307 C146.331132,205.078307 198.235397,226.172044 201.75102,225.195482 C208.391641,223.291186 217.13187,222.119312 224.993193,221.826343 C235.344749,221.435718 244.182634,228.369308 244.03615,231.201337 C243.938494,232.715008 224.895537,228.515792 221.331086,229.980635 C211.22367,234.033367 207.805704,237.060709 200.72563,236.425944 Z"
                  id="Path"
                  fill="#FFD599"
                />
                <path
                  d="M200.627974,237.646646 L200.627974,237.646646 C190.71587,236.816568 135.588951,225.48845 133.245203,225.000169 L133.04989,224.951341 L76.1651597,191.504096 L93.6456177,161.133021 L146.917069,204.004089 C166.448307,211.91424 198.333053,224.316576 201.409223,223.974779 C207.708047,222.16814 216.497105,220.898609 224.944365,220.556813 C232.659204,220.263844 239.788106,223.877123 243.206072,227.343918 C244.622087,228.759933 245.305681,230.078291 245.256852,231.250166 C245.256852,231.64079 245.06154,232.031415 244.719743,232.275556 C243.79201,233.056805 241.887714,232.861493 234.661156,231.933759 C229.876003,231.298994 223.381866,230.468916 221.770539,231.103681 C218.987337,232.226727 216.741245,233.252117 214.788121,234.131023 C210.19828,236.230631 206.926798,237.695474 202.483441,237.695474 C201.897504,237.744302 201.262739,237.744302 200.627974,237.646646 Z M234.954124,229.492354 C237.63967,229.834151 240.764668,230.273604 242.424823,230.322432 C242.229511,230.029463 241.887714,229.59001 241.448261,229.150557 C238.372091,226.025559 231.975611,222.851733 225.042021,223.095874 C216.790073,223.43767 208.245156,224.658373 202.141645,226.416184 C198.479537,227.441574 158.147531,211.279475 145.891679,206.250181 L145.745195,206.201353 L94.4268672,164.892785 L79.5831263,190.674019 L134.026452,222.656421 C137.737387,223.43767 191.399464,234.423992 200.920942,235.254069 C205.754924,235.644694 208.782266,234.277507 213.860387,231.982587 C215.862339,231.054853 218.15726,230.029463 220.989289,228.906417 C221.672883,228.613448 222.698273,228.515792 224.016631,228.515792 C226.458036,228.418136 230.217799,228.906417 234.954124,229.492354 Z"
                  id="Shape"
                  fill="#000000"
                />
                <path
                  d="M95.9405381,148.34006 C95.9405381,148.34006 73.772583,102.295167 46.1358813,112.793207 L41.2042437,120.996327 C41.2042437,120.996327 54.2901732,116.650627 66.5948531,123.095935 C68.9874297,124.365466 86.3214034,144.433813 86.3214034,144.433813 L95.9405381,148.34006 Z"
                  id="Path"
                  fill="#423CB3"
                />
                <path
                  d="M81.3897659,140.576393 C81.3897659,140.576393 105.462017,166.504112 126.262785,178.418167 L109.954201,212.695489 C94.5245234,211.425959 71.9659435,197.509952 62.4932931,180.224806 C45.3546318,149.023654 81.3897659,140.576393 81.3897659,140.576393 Z"
                  id="Path"
                  fill="#C5CFE8"
                />
                <path
                  d="M110.637795,213.96502 L127.776456,177.881058 L126.751066,177.343949 C104.143658,165.869346 82.4639839,140.039284 82.2686716,139.795144 C81.8292187,139.306863 81.0967973,139.209207 80.6085163,139.648659 C80.1202354,140.088112 80.0225792,140.820534 80.4620321,141.308815 C80.6573444,141.552955 102.04405,166.992393 124.651458,178.906448 L109.22178,211.425959 C96.7706158,209.86346 77.4346902,200.537294 62.7374336,179.004104 C62.3468088,178.466995 61.6143874,178.320511 61.0772784,178.711135 C60.5401693,179.10176 60.393685,179.834182 60.7843098,180.371291 C76.2628159,203.076355 96.8682719,212.646661 109.807717,213.916192 L110.637795,213.96502 Z"
                  id="Path"
                  fill="#000000"
                />
                <path
                  d="M231.389673,475.830093 C231.389673,475.830093 268.987307,487.744148 270.305665,497.607423 L202.874066,497.607423 L202.874066,475.830093 L231.389673,475.830093 Z"
                  id="Path"
                  fill="#9F7508"
                />
                <polygon
                  id="Path"
                  fill="#000000"
                  points="272.74707 496.191409 272.74707 499.511719 201.116255 499.511719 201.116255 491.503911"
                />
                <path
                  d="M255.120128,491.503911 C254.778331,491.162115 254.583019,490.673834 254.680675,490.185553 L255.55958,485.693368 L247.795913,488.623054 C247.258804,488.818366 246.624039,488.72071 246.184586,488.330085 C245.793961,487.988289 245.598649,487.45118 245.696305,486.914071 L246.721695,482.373058 C236.272483,486.083993 236.077171,485.888681 235.491233,485.156259 C235.100609,484.716806 234.856468,484.37501 236.370139,476.708999 C236.467795,476.074233 237.102561,475.683609 237.688498,475.830093 C238.323263,475.927749 238.713888,476.562514 238.567403,477.148452 C238.176779,479.24806 237.737326,481.640636 237.542013,483.056651 C239.153341,482.56837 242.327167,481.54298 247.209976,479.785169 C247.747085,479.589856 248.38185,479.687513 248.772475,480.078137 C249.1631,480.419934 249.358412,480.957043 249.211928,481.445324 L248.186538,485.986337 L255.413096,483.251963 C256.047861,483.007823 256.780283,483.105479 257.317392,483.544932 C257.805673,483.935557 258.000985,484.521494 257.903329,485.107431 L257.073251,489.306647 L265.471684,487.207039 C266.057621,487.060555 266.692386,487.402352 266.83887,488.037117 C266.985355,488.623054 266.643558,489.257819 266.008793,489.404303 L256.487314,491.79688 C256.34083,491.845708 256.243174,491.845708 256.096689,491.845708 C255.803721,491.943364 255.413096,491.748052 255.120128,491.503911 Z"
                  id="Shape"
                  fill="#000000"
                />
                <path
                  d="M109.612405,476.318374 C109.612405,476.318374 147.210038,488.232429 148.528396,498.095704 L81.0967973,498.095704 L81.0967973,476.318374 L109.612405,476.318374 Z"
                  id="Path"
                  fill="#9F7508"
                />
                <polygon
                  id="Path"
                  fill="#000000"
                  points="150.969801 496.67969 150.969801 500 79.3389859 500 79.3389859 491.992192"
                />
                <path
                  d="M133.342859,491.992192 C133.001062,491.650396 132.80575,491.162115 132.903406,490.673834 L133.782312,486.181649 L126.018645,489.111335 C125.481536,489.306647 124.84677,489.208991 124.407317,488.818366 C124.016693,488.47657 123.82138,487.939461 123.919037,487.402352 L124.944427,482.861339 C114.495214,486.572274 114.299902,486.376962 113.713965,485.64454 C113.32334,485.205087 113.079199,484.863291 114.59287,477.19728 C114.690527,476.562514 115.325292,476.17189 115.911229,476.318374 C116.545994,476.41603 116.936619,477.050795 116.790135,477.636733 C116.39951,479.736341 115.960057,482.128917 115.764745,483.544932 C117.376072,483.056651 120.549898,482.031261 125.432707,480.27345 C125.969817,480.078137 126.604582,480.175793 126.995207,480.566418 C127.385831,480.908215 127.581144,481.445324 127.434659,481.982433 L126.409269,486.523446 L133.635827,483.789072 C134.270593,483.544932 135.003014,483.642588 135.540123,484.082041 C136.028404,484.472666 136.223716,485.058603 136.12606,485.64454 L135.295983,489.843756 L143.694415,487.744148 C144.280352,487.597664 144.915117,487.939461 145.061602,488.574226 C145.208086,489.160163 144.866289,489.794928 144.231524,489.941412 L134.710046,492.333989 C134.563561,492.382817 134.465905,492.382817 134.319421,492.382817 C134.026452,492.431645 133.635827,492.285161 133.342859,491.992192 Z"
                  id="Shape"
                  fill="#000000"
                />
                <path
                  d="M92.0831186,258.349758 C92.0831186,258.349758 181.682673,259.179836 198.528366,279.736464 C215.374058,300.293092 242.131854,347.216891 239.739278,349.170015 C211.956092,371.875079 201.165083,348.388765 175.920958,354.101652 C164.006902,356.738369 118.547946,371.093829 103.801861,372.558672 C87.3956215,374.169999 97.6983496,347.900484 96.2335067,346.48447 C92.3272591,342.773534 17.669102,352.343841 3.60661064,326.416123 C-4.88947788,310.742304 4.29020397,263.232568 4.29020397,263.232568 L92.0831186,258.349758 Z"
                  id="Path"
                  fill="#423CB3"
                />
                <path
                  d="M71.4288345,70.6545613 C68.1573521,66.6506575 62.5909493,58.9846466 64.0069641,53.7600405 C65.4229788,48.5354343 71.3800064,51.2209796 72.9913335,52.6858224 C74.0655516,53.6623843 80.8526568,27.5393535 80.8526568,27.5393535 L125.139739,41.2600482 C125.139739,41.2600482 122.454194,63.6721438 116.545994,77.3440103 C110.588967,91.064705 102.581159,92.4318917 91.5460096,89.2580655 C86.077263,87.6467384 82.2198435,85.1076774 79.387814,82.3733041 L71.4288345,70.6545613 Z"
                  id="Path"
                  fill="#FFD599"
                />
                <path
                  d="M100.188582,91.8947826 C106.731547,91.8947826 112.932715,88.7209565 117.66904,77.7834632 C123.626068,64.1115966 126.262785,42.2854382 126.360441,41.3577044 L126.458097,40.3323144 L79.9737511,25.8791983 L79.6319544,27.1487288 C77.0928935,37.0120039 74.0167235,47.7541848 72.6495369,50.8791829 C71.0382097,49.902621 68.4503207,48.9260591 66.1554002,49.6096524 C64.983526,49.9514491 63.469855,50.8791829 62.7862617,53.3694157 C61.3214188,58.7893343 66.1554002,66.1623766 70.4522726,71.3869828 C70.8917254,71.9240918 71.672975,71.9729199 72.210084,71.533467 C72.7471931,71.0940142 72.7960211,70.3127647 72.3565683,69.7756556 C66.8389936,63.1350347 64.2511045,57.3733195 65.1300102,54.004181 C65.4229788,52.8811348 66.008916,52.1975415 66.8389936,51.953401 C68.596805,51.4162919 71.184694,52.6858224 72.1124278,53.5159 C72.4542245,53.8088686 72.9425054,53.9553529 73.3819583,53.8088686 C74.1143797,53.5647281 75.3350821,53.2229314 81.6827344,29.0041964 L123.723724,42.0412977 C123.137787,46.5334824 120.403414,65.0393304 115.325292,76.7580732 C109.514749,90.0881431 102.04405,90.9182207 91.8389782,87.9397069 C87.2003092,86.5725203 83.2940616,84.375256 80.1690635,81.3479141 C79.6807825,80.8596332 78.899533,80.8596332 78.4112521,81.3479141 C77.9229711,81.8361951 77.9717992,82.6174446 78.4600802,83.1057255 C81.8780468,86.426036 86.1260911,88.8186126 91.1553848,90.2834555 C94.1827267,91.2600174 97.2100686,91.8947826 100.188582,91.8947826 Z"
                  id="Path"
                  fill="#000000"
                />
                <path
                  d="M100.139754,49.1701996 C100.139754,49.1701996 98.2354586,45.6057486 88.176871,47.4612162 L100.139754,49.1701996 Z"
                  id="Path"
                  fill="#FFFFFF"
                />
                <path
                  d="M100.139754,50.3909019 C100.335067,50.3909019 100.530379,50.3420738 100.725691,50.2444177 C101.311629,49.9514491 101.506941,49.1701996 101.213972,48.5842624 C100.969832,48.1448096 98.5772553,44.3362182 87.9815587,46.289342 C87.3467934,46.3869982 86.9073406,47.0217634 87.0049968,47.7053567 C87.102653,48.340122 87.7374182,48.7795748 88.3721834,48.6819186 C97.2100686,47.0705915 99.1143643,49.8049648 99.1143643,49.8049648 C99.3585048,50.1467615 99.7491295,50.3909019 100.139754,50.3909019 Z"
                  id="Path"
                  fill="#000000"
                />
                <path
                  d="M135.686607,37.3538006 C135.686607,37.3538006 87.1514811,35.010052 73.8702392,52.1487134 C73.8702392,52.1487134 73.0889897,50.2932457 71.4776626,49.3655119 C69.4268826,48.1936377 67.9132117,48.1936377 67.9132117,48.1936377 L78.8018768,19.1897493 L135.686607,37.3538006 Z"
                  id="Path"
                  fill="#000000"
                />
                <path
                  d="M381.096612,235.302897 L381.096612,235.302897 C392.278246,235.302897 401.360272,226.220872 401.360272,215.039238 L401.360272,205.273619 L360.832953,205.273619 L360.832953,215.039238 C360.832953,226.220872 369.914979,235.302897 381.096612,235.302897 Z"
                  id="Path"
                  fill="#E6730B"
                />
                <path
                  d="M391.887621,200.976747 C392.229418,200.976747 392.571215,200.830262 392.815355,200.586122 C393.254808,200.097841 393.254808,199.316591 392.717699,198.828311 C389.006764,195.459172 388.323171,194.48261 388.371999,191.1623 C388.420827,188.08613 389.88567,186.865427 391.692309,185.302928 C393.059496,184.131054 394.670823,182.812695 395.452072,180.517775 C397.356368,175.0002 390.227466,170.068563 389.934498,169.87325 C389.348561,169.482625 388.616139,169.62911 388.225514,170.215047 C387.83489,170.752156 387.981374,171.533405 388.567311,171.92403 C390.227466,173.047076 394.23137,176.660355 393.157152,179.736525 C392.571215,181.396681 391.448169,182.373243 390.12981,183.447461 C388.176686,185.107616 386.02825,186.963083 385.930594,191.1623 C385.832938,195.703312 387.346609,197.265811 391.106372,200.683778 C391.252856,200.87909 391.594653,200.976747 391.887621,200.976747 Z"
                  id="Path"
                  fill="#000000"
                />
                <path
                  d="M373.870054,200.976747 C374.211851,200.976747 374.553648,200.830262 374.797788,200.586122 C375.237241,200.097841 375.237241,199.316591 374.700132,198.828311 C370.989197,195.459172 370.305604,194.48261 370.354432,191.1623 C370.40326,188.08613 371.868103,186.865427 373.674742,185.302928 C375.041929,184.131054 376.653256,182.812695 377.434505,180.517775 C379.338801,175.0002 372.209899,170.068563 371.916931,169.87325 C371.330994,169.482625 370.598572,169.62911 370.207947,170.215047 C369.817323,170.752156 369.963807,171.533405 370.549744,171.92403 C372.209899,173.047076 376.213803,176.660355 375.139585,179.736525 C374.553648,181.396681 373.430602,182.373243 372.112243,183.447461 C370.159119,185.107616 368.010683,186.963083 367.913027,191.1623 C367.815371,195.703312 369.329042,197.265811 373.088805,200.683778 C373.284117,200.87909 373.577086,200.976747 373.870054,200.976747 Z"
                  id="Path"
                  fill="#000000"
                />
                <path
                  d="M383.489189,197.656436 C383.830986,197.656436 384.172782,197.509952 384.416923,197.265811 C384.856376,196.777531 384.856376,195.996281 384.319267,195.508 C380.608332,192.138862 379.924738,191.1623 379.973566,187.841989 C380.022394,184.765819 381.487237,183.545117 383.293877,181.982618 C384.661063,180.810743 386.272391,179.492385 387.05364,177.197464 C388.957936,171.67989 381.829034,166.748252 381.536065,166.55294 C380.998956,166.162315 380.217707,166.308799 379.827082,166.894736 C379.436457,167.431845 379.582942,168.213095 380.168879,168.60372 C381.829034,169.726766 385.832938,173.340045 384.75872,176.416215 C384.172782,178.07637 383.049736,179.052932 381.731378,180.12715 C379.778254,181.787305 377.629818,183.593945 377.532162,187.841989 C377.434505,192.383002 378.948176,193.945501 382.70794,197.363468 C382.903252,197.509952 383.196221,197.656436 383.489189,197.656436 Z"
                  id="Path"
                  fill="#000000"
                />
                <path
                  d="M366.008731,228.173996 C366.3017,228.173996 366.594668,228.173996 366.887637,228.125167 L366.741153,225.683763 C362.248968,225.976731 358.147408,223.730639 355.754831,219.677907 C353.948192,216.650565 353.508739,213.18377 354.680613,211.230647 C355.071238,210.547053 355.608347,210.205257 356.340768,210.058772 C358.977485,209.619319 363.127874,212.402521 364.49506,213.525567 L366.057559,211.621271 C365.471622,211.13299 360.100532,206.933774 355.950144,207.617368 C354.534129,207.861508 353.362255,208.642758 352.581005,209.912288 C350.92085,212.646661 351.311475,217.04119 353.655223,220.898609 C356.389596,225.48845 360.979437,228.173996 366.008731,228.173996 Z"
                  id="Path"
                  fill="#E6730B"
                />
                <path
                  d="M387.83489,230.566572 C388.030202,230.566572 388.225514,230.517744 388.371999,230.420088 C397.893477,225.586107 396.770431,210.88885 396.721603,210.254085 C396.672775,209.570491 396.086838,209.08221 395.403244,209.131039 C394.719651,209.179867 394.23137,209.765804 394.280198,210.449397 C394.280198,210.595881 395.305588,224.121264 387.248952,228.222824 C386.663015,228.515792 386.418875,229.297042 386.711843,229.882979 C386.955984,230.322432 387.395437,230.566572 387.83489,230.566572 Z"
                  id="Path"
                  fill="#FFFFFF"
                />
                <polygon
                  id="Path"
                  fill="#EBF4FE"
                  points="351.555615 169.677938 335.979453 232.66618 245.110368 232.66618 263.51856 169.677938"
                />
                <path
                  d="M262.541998,168.408407 L353.166942,168.408407 L336.956015,233.984539 L243.401385,233.984539 L262.541998,168.408407 Z M349.89546,170.996296 L264.495122,170.996296 L246.86818,231.39665 L335.002891,231.39665 L349.89546,170.996296 Z"
                  id="Shape"
                  fill="#000000"
                />
                <rect
                  id="Rectangle"
                  fill="#444B64"
                  x="217.913119"
                  y="232.66618"
                  width="118.017505"
                  height="5.95702758"
                />
                <path
                  d="M216.643589,231.445478 L337.102499,231.445478 L337.102499,239.84391 L216.643589,239.84391 L216.643589,231.445478 Z M334.709922,233.935711 L219.133822,233.935711 L219.133822,237.402505 L334.709922,237.402505 L334.709922,233.935711 L334.709922,233.935711 Z"
                  id="Shape"
                  fill="#000000"
                />
                <path
                  d="M290.862293,203.027527 C290.42284,207.910336 293.303698,212.15838 297.307602,212.500177 C301.262677,212.841974 304.827128,209.179867 305.266581,204.297057 C305.706034,199.414248 302.825176,195.166203 298.821273,194.824407 C294.817369,194.433782 291.252918,198.144717 290.862293,203.027527 Z"
                  id="Path"
                  fill="#EBF4FE"
                />
                <path
                  d="M297.209945,213.672051 C294.915025,213.476739 292.864245,212.207209 291.44823,210.1076 C290.081044,208.105649 289.446278,205.51776 289.690419,202.881042 C290.1787,197.31464 294.329088,193.115423 298.967757,193.554876 C303.606426,193.945501 307.024392,198.779482 306.536112,204.345885 C306.096659,209.668148 302.239239,213.720879 297.844711,213.720879 C297.60057,213.720879 297.405258,213.720879 297.209945,213.672051 Z M292.082995,203.125183 L292.082995,203.125183 C291.887683,205.224791 292.375964,207.226743 293.450182,208.740414 C294.475572,210.205257 295.842759,211.084162 297.405258,211.230647 C300.67674,211.523615 303.655254,208.349789 304.045879,204.150573 C304.387675,199.951357 302.043927,196.28925 298.723616,196.045109 C298.577132,196.045109 298.430648,196.045109 298.332992,196.045109 C295.159165,195.996281 292.424792,199.072451 292.082995,203.125183 Z"
                  id="Shape"
                  fill="#000000"
                />
                <path
                  d="M109.075296,75.3420584 C109.612405,76.2697922 105.315532,78.6135408 102.385847,77.9299475 C94.9639762,76.3186203 93.8409301,70.5569051 95.354601,71.7287794 C95.89171,72.1682323 99.2608486,74.6584651 103.020612,75.1955742 C107.708109,75.9279956 108.489359,74.3166685 109.075296,75.3420584 Z"
                  id="Path"
                  fill="#000000"
                />
                <path
                  d="M115.422948,61.1330828 C115.422948,61.1330828 113.420996,59.1311309 110.442482,58.9846466 L115.422948,61.1330828 Z"
                  id="Path"
                  fill="#FFFFFF"
                />
                <path
                  d="M115.422948,62.4026133 C115.715917,62.4026133 116.057713,62.3049571 116.301854,62.0608166 C116.790135,61.5725357 116.790135,60.7912862 116.301854,60.3030052 C116.204198,60.205349 113.958105,57.9592567 110.540139,57.7639443 C109.856545,57.7639443 109.270608,58.2522252 109.22178,58.9358186 C109.172952,59.6194119 109.710061,60.205349 110.393654,60.2541771 C112.835059,60.4006614 114.544042,62.0608166 114.544042,62.0608166 C114.788183,62.256129 115.129979,62.4026133 115.422948,62.4026133 Z"
                  id="Path"
                  fill="#000000"
                />
                <path
                  d="M97.8448338,55.8108205 C95.1592886,54.1994933 91.3995253,55.0783991 91.3995253,55.0783991 L97.8448338,55.8108205 Z"
                  id="Path"
                  fill="#FFFFFF"
                />
                <path
                  d="M97.8448338,57.0315228 C98.2842867,57.0315228 98.6749115,56.8362105 98.9190519,56.4455857 C99.2608486,55.8596486 99.0655362,55.1272271 98.4795991,54.7366024 C95.4034291,52.8811348 91.3018691,53.8088686 91.1553848,53.8576967 C90.4717915,54.004181 90.0811668,54.6877743 90.227651,55.3225395 C90.3741353,56.0061329 91.0577286,56.3967576 91.6924939,56.2502733 C91.741322,56.2502733 95.0128043,55.5178519 97.2100686,56.8362105 C97.405381,56.9826948 97.6006934,57.0315228 97.8448338,57.0315228 Z"
                  id="Path"
                  fill="#000000"
                />
                <path
                  d="M106.585063,71.0940142 C109.758889,71.0940142 110.24717,70.2151085 110.442482,69.8244837 C111.028419,68.7990937 110.588967,67.8713599 110.149514,66.894798 C109.563577,65.5276114 108.538187,63.1838628 108.342874,58.2033971 C108.147562,53.1252753 108.831155,51.953401 109.124124,51.6604324 C109.368264,51.46512 109.856545,51.5139481 110.637795,51.6116043 C115.276464,52.2951976 117.962009,54.9319148 117.962009,54.9319148 C118.45029,55.4201957 119.231539,55.4201957 119.71982,54.9319148 C120.208101,54.4436338 120.208101,53.6623843 119.71982,53.1741033 C119.573336,53.0276191 116.497166,49.902621 110.979591,49.1213715 C109.905373,48.9748872 108.587015,48.7795748 107.463969,49.7561367 C106.14561,50.928011 105.657329,53.4182438 105.852641,58.2033971 C106.047954,63.6721438 107.219828,66.3088609 107.854593,67.7737037 C107.952249,67.9690161 108.049906,68.2131566 108.098734,68.408469 C107.122172,68.6037813 104.583111,68.5061252 102.190534,68.1155004 C101.506941,68.0178442 100.872176,68.4572971 100.77452,69.1408904 C100.676863,69.8244837 101.116316,70.459249 101.79991,70.5569051 C103.899518,70.9475299 105.413189,71.0940142 106.585063,71.0940142 Z"
                  id="Path"
                  fill="#000000"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div className="mt-4">
        <ol>
          <ListItem
            label="Give your opinion and feedback on things that matter to you."
            className="anim anim1-5"
          />

          <ListItem label="Earn points as you go" className="anim anim1-5" />
          <ListItem
            label="Redeem gift cards from great brands such as Amazon, Apple or Visa"
            className="anim anim1-5"
          />
          <ListItem
            label="Enjoy Communa. <br /> It's free, and always will be!"
            className="anim anim2"
          />
        </ol>
      </div>
    </section>
  );
};

export default LeftSection;
