import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { setShowGlobalCurtain } from '@/store/slices/configSlice';
import { jwtDecode } from '@/utils/jwt';

const GoogleLoginButton = ({
  label = 'signin_with',
  onError = null,
  onClick,
  onSuccess,
  onSubmit = null,
  className = '',
}) => {
  const dispatch = useDispatch();
  const btnRef = useRef();

  const handleCredentialResponse = (response) => {
    if (response.credential) {
      const leadId = localStorage.getItem('leadId');
      const responsePayload = jwtDecode(response.credential);
      const params = {
        lead_id: leadId,
        email: responsePayload.email,
        password: response.credential,
        id: responsePayload.sub,
        source: 'google',
        access_token: response.credential,
      };
      dispatch(setShowGlobalCurtain(true));
      onSubmit && onSubmit();
      onSuccess(params);
    }
  };

  const handleGoogleNtification = (notification) => {
    if (notification.isNotDisplayed()) {
      const reason = notification.getNotDisplayedReason();
      const reasonToNotify = [
        'browser_not_supported',
        'invalid_client',
        'secure_http_required',
        'unknown_reason',
      ];
      if (reasonToNotify.includes(reason)) {
        onError(
          'Something went wrong. Please try again or select another method.'
        );
      }
    }
  };

  const googleBtnTheme = {
    size: 'large',
    width: label === 'signin_with' ? 310 : 360,
    theme: 'outline',
    text: label,
    shape: 'pill',
  };

  const googleInitSettings = {
    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    callback: handleCredentialResponse,
  };

  const initClient = () => {
    onClick();
    console.log('Initializing Google Account...');
    google.accounts.id.initialize(googleInitSettings);
    google.accounts.id.renderButton(btnRef.current, googleBtnTheme);
    google.accounts.id.prompt(handleGoogleNtification);
  };

  useEffect(() => {
    initClient();
  }, []);

  return (
    <button
      className={`flex items-center justify-center relative px-4 h-11 w-full  bg-white hover:opacity-75 active:opacity-95 transition-all duration-200 ${className}`}
      ref={btnRef}
    ></button>
  );
};

GoogleLoginButton.propTypes = {
  label: PropTypes.string,
  onError: PropTypes.func,
  onSubmit: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default GoogleLoginButton;
