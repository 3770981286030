import { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import TaskContainer from './TaskContainer';
import cbdHero from '@/assets/img/cbd-gummies-modal-hero.png';

const TaskCBDOffer = ({ task, onClose }) => {
  const [disableBtn, setDisableBtn] = useState(false);

  const handleOnClick = () => {
    window.open(task.url, '_blank');
    onClose();
  };

  // const to = { pathname: task.url };

  return (
    <TaskContainer className="overflow-visible">
      <header>
        <section className="relative h-80 md:h-100">
          <button
            className="hover:opacity-100 opacity-75 duration-200 transition-opacity absolute top-4 right-4 z-20"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              viewBox="0 0 32 32"
            >
              <polygon
                fill="#FFF"
                points="32 3.8 28.2 0 16 12.2 3.8 0 0 3.8 12.2 16 0 28.2 3.8 32 16 19.8 28.2 32 32 28.2 19.8 16"
              />
            </svg>
          </button>

          <img
            className="z-10 absolute left-1/2 transform -translate-x-1/2 scale-110 top-24"
            src={cbdHero}
            alt="CBD Offer"
          />

          <div
            className="dark_blue rounded-t relative h-40 pt-10"
            style={{ clipPath: 'polygon(0 0, 101% 0, 101% 80%, 0% 100%)' }}
          >
            <h1 className="text-center text-white text-4xl">Special Offer!</h1>
          </div>
        </section>
      </header>

      <section className="md:mt-3 lg:mt-2.5 px-5 md:px-8 text-center text-lg">
        <h2 className="px-4 md:px-8 text-center text-2xl md:text-3xl mb-0 uppercase">
          CBD Gummies
        </h2>
        <p className="mt-3.5">Powerful Natural Relief!</p>
        <p className="mt-3">
          Reduce pain &amp; chronic aches, relieve anxiety &amp; stress, enhance
          focus &amp; clarity, and promote healthy&nbsp;sleep!
        </p>
      </section>
      <footer className="p-5 pt-8 md:p-8">
        <Button
          onClick={handleOnClick}
          variant="success"
          className="w-full"
          disabled={disableBtn}
        >
          Claim Your Bottle Now!
        </Button>
      </footer>
    </TaskContainer>
  );
};

TaskCBDOffer.propTypes = {
  // onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  task: PropTypes.object,
};

export default TaskCBDOffer;
